import flattenObject from '../../utils/functions/flattenObject';
import {ESupportedLanguages} from './intl.config';
import en from './languages/en.json';
import hi from './languages/hi.json';

const languages = {
  [ESupportedLanguages.English]: en,
  [ESupportedLanguages.Hindi]: hi,
};

const AppLocale: {[key: string]: {[key: string]: string}} = {
  ...Object.entries(ESupportedLanguages).reduce((all, [, value]) => {
    return {
      ...all,
      [value]: flattenObject(languages[value]),
    };
  }, {}),
};

export default AppLocale;
