import {AddressObject, Banner, Occupation, Panth} from '../../api/response/app';

export enum AppActionType {
  GetState = 'GetState',
  GetContactDistrics = 'GetContactDistrics',
  GetContactCities = 'GetContactCities',
  GetContactAreas = 'GetContactAreas',
  GetOfficeDistrics = 'GetOfficeDistrics',
  GetOfficeCities = 'GetOfficeCities',
  GetOfficeAreas = 'GetOfficeAreas',
  GetPanths = 'GetPanths',
  GetBanner = 'GetBanner',
  GetOccupations = 'GetOccupations',
  GetCategories = 'GetCategories',
}

export interface AppState {
  states: AddressObject[];
  contactDistrics: AddressObject[];
  contactCities: AddressObject[];
  contactAreas: AddressObject[];
  officeDistrics: AddressObject[];
  officeCities: AddressObject[];
  officeAreas: AddressObject[];
  panths: Panth[];
  banners: Banner[];
  occupations: Occupation[];
  categories: AddressObject[];
}
