import React from 'react';
import {Email, Location, Phone} from '../../assets/svg';
import {SupportURLs} from '../../services/api/path';

export const contactInfoData = [
  {
    id: 1,
    title: 'Email',
    icon: <Email width={50} height={50} />,
    description: 'ssrjsvadodara@gmail.com',
    link: SupportURLs.Email,
  },
  {
    id: 2,
    title: 'Contact Number',
    icon: <Phone width={50} height={50} fill="green" />,
    description: '+91-7600136222',
    link: SupportURLs.WhatsAppWeb,
  },
  {
    id: 3,
    title: 'Contact Address',
    icon: <Location width={50} height={50} />,
    description:
      'Shree Samast Rajasthan Jain Samaj, 1st Floor Loha Bhavan, Lakkad Pitha Road, Baroda-1, Gujarat, India.',
  },
];
