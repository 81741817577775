import {Navigate, Outlet} from 'react-router-dom';
import React from 'react';
import {useAppSelector} from '../../../services/redux/hook';
import {NavigationNames} from '../../../utils/routeNames';

export const PublicRoute = () => {
  const {user} = useAppSelector(state => state.userReducer);

  if (user) {
    return <Navigate to={NavigationNames.Home} replace />;
  }

  return <Outlet />;
};
