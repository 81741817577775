import {BoardMember} from '../../api/response/community';
import {CommunityActionType} from './community.types';

export const boardMembersAction = (payload: BoardMember[]) => ({
  type: CommunityActionType.BoardMembers,
  payload,
});

export const allMembersAction = (payload: BoardMember[]) => ({
  type: CommunityActionType.AllMembers,
  payload,
});
