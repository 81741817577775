import {Dimensions, Platform, StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';
const {width: screenWidth} = Dimensions.get('window');

const getFontSize = () => {
  if (screenWidth > 300 && screenWidth < 580) return 18;
  if (screenWidth > 600 && screenWidth < 720) return 14;
  return 20;
};

export const style = StyleSheet.create({
  container: {
    height: 67,
    maxWidth: '100%',
    flexDirection: 'row',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    alignItems: 'center',
    backgroundColor: Colors.white,
    gap: 15,
    paddingHorizontal: 20,
  },
  webContainer: {
    minHeight: 80,
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    gap: 15,
    paddingHorizontal: 16,
  },
  rowView: {
    flexDirection: 'row',
    flex: 1,
    gap: 20,
    maxWidth: '100%',
    alignItems: 'center',
    height: '100%',
  },
  name: {
    fontFamily: FontFamily.PoppinsSemiBold,
    fontSize: FontSize.h5,
    color: Colors.primary,
  },
  leftComponentButton: {
    paddingHorizontal: 10,
    maxWidth: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 0,
  },
  rightComponentButton: {
    paddingHorizontal: 10,
    maxWidth: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 0,
  },
  headerTitleButton: {
    padding: 10,
    justifyContent: 'center',
    borderRadius: 10,
  },
  headerTitleActiveButton: {
    padding: 10,
    justifyContent: 'center',
    backgroundColor: Colors.primaryBackground,
    borderRadius: 10,
  },
  headerTitleButtonActiveText: {
    fontFamily: FontFamily.PoppinsMedium,
    fontSize: FontSize.bodyBig,
    color: Colors.primary,
  },
  headerTitleButtonText: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
  },
  logoStyle: {
    alignSelf: 'center',
    width: Platform.OS === 'web' ? 120 : 180,
    height: 120,
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    maxWidth: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  logoTextContainer: {
    maxWidth: '80%',
    height: 80,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: getFontSize(),
    color: '#17179b',
    fontWeight: '600',
  },
  logoContainer_M_View: {
    width: '100%',
    marginRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logoTextContainer_M_View: {
    width: '90%',
    fontFamily: FontFamily.PoppinsBold,
    flexWrap: 'wrap',
  },
  logoText_M_View: {
    fontFamily: FontFamily.PoppinsItalic,
    width: '100%',
    fontSize: 12,
    textAlign: 'center',
    color: '#17179b',
    fontWeight: '600',
  },
  rowViewMobileWeb: {
    width: '100%',
    flexDirection: 'row',
    gap: 5,
    paddingHorizontal: 20,
    overflow: 'scroll',
  },
  landingRowView: {
    flexDirection: 'row',
    gap: 15,
    alignItems: 'center',
  },
  loginButton: {
    backgroundColor: 'transparent',
    marginTop: 10,
    borderWidth: 1,
    borderColor: Colors.primary,
    width: 130,
    borderRadius: 100,
    paddingHorizontal: 10,
  },
  loginText: {
    color: Colors.primary,
    fontSize: FontSize.h6,
  },
  signUpButton: {
    marginTop: 10,
    width: 130,
    borderRadius: 100,
    paddingHorizontal: 10,
  },
  signUpButtonText: {
    fontSize: FontSize.h6,
  },
  playStoreIcon: {width: 150, height: 50, resizeMode: 'contain'},

  landingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.white,
    width: '100%',
    position: 'absolute',
    top: 10,
  },

  hamburgerIcon: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: 10,
    width: 30,
  },
  hamburgerLine: {
    height: 3,
    backgroundColor: Colors.black,
    marginVertical: 2,
  },

  storeIcon: {
    width: 100,
    height: 40,
    resizeMode: 'contain',
  },

  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
  },

  mobileMenu: {
    position: 'absolute',
    top: 80,
    right: 20,
    backgroundColor: Colors.white,
    padding: 10,
    borderRadius: 8,
    shadowColor: Colors.black,
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },

  langSelectionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  languageIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  languageName: {
    fontSize: 16,
    color: Colors.maroon,
  },
});
