import {Text, TouchableOpacity} from 'react-native';
import React from 'react';
import {ContactInfoProps} from './ContactInfo.types';
import {styles} from './ContactInfo.styles';

const ContactInfo: React.FC<ContactInfoProps> = ({
  title,
  icon,
  description,
  onPress,
}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={styles.container}
      onPress={onPress}>
      {icon}
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.message}>{description}</Text>
    </TouchableOpacity>
  );
};

export default ContactInfo;
