import {AddressObject, Banner, Occupation} from '../../api/response/app';
import {UserActionType} from '../user/user.types';
import {AppActionType, AppState} from './app.types';

const initialState: AppState = {
  states: [],
  contactDistrics: [],
  contactAreas: [],
  contactCities: [],
  officeDistrics: [],
  officeAreas: [],
  officeCities: [],
  panths: [],
  banners: [],
  occupations: [],
  categories: [],
};

const appReducer = (state: AppState = initialState, action: any): AppState => {
  switch (action.type) {
    case AppActionType.GetState:
      return {...state, states: action.payload};
    case AppActionType.GetContactDistrics:
      return {...state, contactDistrics: action.payload};
    case AppActionType.GetContactAreas:
      return {...state, contactAreas: action.payload};
    case AppActionType.GetContactCities:
      return {...state, contactCities: action.payload};
    case AppActionType.GetOfficeDistrics:
      return {...state, officeDistrics: action.payload};
    case AppActionType.GetOfficeAreas:
      return {...state, officeAreas: action.payload};
    case AppActionType.GetOfficeCities:
      return {...state, officeCities: action.payload};
    case AppActionType.GetPanths:
      return {...state, panths: action.payload};
    case AppActionType.GetBanner:
      return {...state, banners: action.payload as Banner[]};
    case AppActionType.GetOccupations:
      return {...state, occupations: action.payload as Occupation[]};
    case AppActionType.GetCategories:
      return {...state, categories: action.payload as AddressObject[]};
    case UserActionType.Logout:
      return initialState;
    default:
      return state;
  }
};

export default appReducer;
