import {BoardMember} from '../../api/response/community';

export enum CommunityActionType {
  BoardMembers = 'BoardMembers',
  AllMembers = 'AllMembers',
}

export interface CommutnityState {
  boardMembers: BoardMember[];
  allMembers: BoardMember[];
}
