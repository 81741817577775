import {View, Text, TouchableOpacity} from 'react-native';
import React from 'react';
import {style} from './ProfileInfoTile.styles';
import {ProfileInfoTileProps} from './ProfileInfoTile.types';

const ProfileInfoTile: React.FC<ProfileInfoTileProps> = ({
  title,
  value,
  icon,
  containerStyle,
  onPress,
}) => {
  return (
    <View style={[style.container, containerStyle]}>
      <Text style={style.title}>{title}</Text>
      <View style={style.valueContainer}>
        {icon && (
          <TouchableOpacity onPress={onPress} style={style.iconContainer}>
            {icon}
          </TouchableOpacity>
        )}
        <Text style={style.value}>
          {value && value !== '' && value !== 'Na' ? value : 'N/A'}
        </Text>
      </View>
    </View>
  );
};

export default ProfileInfoTile;
