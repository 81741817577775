import {SignUpController, SignUpFormFields} from './SignUp.types';
import {useForm} from 'react-hook-form';
import {signUpFormSchema} from '../../validationSchemas/signUp';
import {yupResolver} from '@hookform/resolvers/yup';
import axios from '../../services/api/api';
import {SupportURLs} from '../../services/api/path';
import {SignUp} from '../../services/api/response/user';
import {Linking, Platform} from 'react-native';
import {ApiRoutes} from '../../services/api/path';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useEffect} from 'react';

export const useSignUpController = (): SignUpController => {
  const {t} = useTranslation();
  const {openModal} = useModal();
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm<SignUpFormFields>({
    resolver: yupResolver(signUpFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      isLoading: false,
      whatsAppNumber: '',
      profileImage: '',
      acceptTandC: false,
      bloodGroup: '',
    },
  });
  const {isLoading, bloodGroup} = watch();

  useEffect(() => {
    if (bloodGroup) {
      setValue('bloodGroup', bloodGroup?.toUpperCase());
    }
  }, [bloodGroup, setValue]);

  const onSubmit = async (data: SignUpFormFields, onSuccess: () => void) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post<SignUp>(ApiRoutes.SignUp, {
        ProfilePicture: data.profileImage,
        FirstName: data.firstName,
        MiddleName: data.middleName,
        LastName: data.lastName,
        Email: data.email ?? null,
        MobileNo: data.mobileNumber,
        WhatsAppNo: data.whatsAppNumber,
        BloodGroup: data.bloodGroup,
        DOB: moment(data.dob).format('DD MM YYYY'),
        RequestById: Platform.OS === 'web' ? 5 : 7,
        IsAdmin: 0,
      });
      setValue('isLoading', false);
      if (response.isSaved) {
        onSuccess();
        openModal({
          title: t('signUp.success'),
          message: t('signUp.newSignUpMessage'),
        });
        return;
      }
      openModal({
        title: t('signUp.signUpFailed'),
        message: response?.message,
      });
    } catch (e) {
      setValue('isLoading', false);
      console.log(e);
    }
  };

  const openPrivacyPolicy = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.PrivacyPolicy);

    if (supported) {
      await Linking.openURL(SupportURLs.PrivacyPolicy);
    } else {
      openModal({message: t('login.urlDoesNotSupport')});
    }
  };

  const openTeamsAndService = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.TermsAndService);

    if (supported) {
      await Linking.openURL(SupportURLs.TermsAndService);
    } else {
      openModal({message: t('login.urlDoesNotSupport')});
    }
  };

  return {
    control,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    setValue,
    openPrivacyPolicy,
    openTeamsAndService,
  };
};
