import {Dimensions, Platform, StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';
const {width: screenWidth} = Dimensions.get('window');

const getFontSize = () => {
  if (screenWidth > 300 && screenWidth < 580) return 18;
  if (screenWidth > 600 && screenWidth < 720) return 14;
  return 20;
};

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    paddingVertical: 50,
    paddingHorizontal: 20,
    gap: 30,
  },
  title: {
    fontSize: FontSize.h1,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  message: {
    fontSize: FontSize.h4,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
  },
  boxView: {
    flex: 1,
    display: 'flex',
    gap: 20,
  },

  rowView: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    gap: 50,
  },
  inquiryForm: {
    fontSize: FontSize.h1,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  inquiryFormMessage: {
    fontSize: FontSize.h6,
    color: Colors.lightBlack,
    fontFamily: FontFamily.PoppinsRegular,
    marginBottom: 10,
    textAlign: 'center',
  },
  rowInputView: {
    flexDirection: 'row',
    gap: 15,
  },
  headerTitle: {
    fontSize: FontSize.h2,
    fontFamily: FontFamily.PoppinsSemiBold,
    marginBottom: 5,
    marginTop: 15,
    color: Colors.blackA100,
  },
  headerRowView: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    justifyContent: 'center',
    paddingVertical: 20,
  },
  backButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    position: 'absolute',
    left: 0,
  },
  backText: {
    fontSize: FontSize.h5,
    fontFamily: FontFamily.PoppinsSemiBold,
    color: Colors.primary,
  },
  copyRights: {
    fontSize: FontSize.body,
    color: Colors.lightBlack,
    fontFamily: FontFamily.PoppinsRegular,
    textAlign: 'center',
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: getFontSize(),
    color: '#17179b',
    fontWeight: '600',
  },
  logoTextContainer: {
    maxWidth: '60%',
    height: 80,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginLeft: 80,
    maxWidth: '20%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  logoStyle: {
    alignSelf: 'center',
    width: Platform.OS === 'web' ? 120 : 180,
    height: 120,
  },
});
