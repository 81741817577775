import {Platform, Text, TouchableOpacity, View} from 'react-native';
import React from 'react';
import {style} from './Header.styles';
import {Logo} from '../../assets/svg';
import {HeaderProps} from './Header.types';
import {NavigationNames} from '../../utils/routeNames';
import {useLocation, useNavigate} from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import {FormattedMessage} from 'react-intl';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';
import {useTranslation} from 'react-i18next';

const Header: React.FC<HeaderProps> = ({
  title,
  leftComponent = (
    <View style={style.logoContainer}>
      <Logo width={120} height={120} style={style.logoStyle} />
      <View style={style.logoTextContainer}>
        <Text style={style.logoText}>
          श्री समस्त राजस्थान जैन समाज , वडोदरा
        </Text>
      </View>
    </View>
  ),
  rightComponent = <Avatar image={0} firstName="a" lastName="p" />,
  leftComponentPress,
  rightComponentPress,
}) => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const {pathname} = useLocation();
  const showMenuButtons = [
    NavigationNames.Home,
    NavigationNames.Event,
    NavigationNames.Community,
    NavigationNames.Matrimonial,
    NavigationNames.BoardMembers,
  ].includes(pathname as NavigationNames);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const headerMenu = [
    {
      id: 1,
      name: <FormattedMessage id={t('misc.home')} />,
      pathName: NavigationNames.Home,
    },

    {
      id: 3,
      name: <FormattedMessage id={t('home.matrimonial')} />,
      pathName: NavigationNames.Matrimonial,
    },
    {
      id: 4,
      name: <FormattedMessage id={t('misc.community')} />,
      pathName: NavigationNames.Community,
    },
    {
      id: 5,
      name: <FormattedMessage id={t('misc.boardMembers')} />,
      pathName: NavigationNames.BoardMembers,
    },
  ];

  const userProfileClick = (): void => {
    navigate(NavigationNames.AccountAndSettings);
  };
  return (
    <View style={{width: '100%'}}>
      <View style={style.webContainer}>
        <View style={style.rowView}>
          <TouchableOpacity
            activeOpacity={0.8}
            disabled={!leftComponentPress}
            onPress={leftComponentPress}
            style={style.leftComponentButton}>
            {leftComponent}
          </TouchableOpacity>
          {title && (
            <Text style={style.name} numberOfLines={1} ellipsizeMode="tail">
              {title}
            </Text>
          )}
        </View>
        {showMenuButtons && isWeb && (
          <View style={{flexDirection: 'row', gap: 5}}>
            {headerMenu.map(menu => (
              <TouchableOpacity
                style={
                  pathname === menu.pathName
                    ? style.headerTitleActiveButton
                    : style.headerTitleButton
                }
                onPress={() => navigate(menu.pathName)}>
                <Text
                  style={
                    pathname === menu.pathName
                      ? style.headerTitleButtonActiveText
                      : style.headerTitleButtonText
                  }>
                  {menu.name}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
        {rightComponent && (
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={rightComponentPress || userProfileClick}
            style={style.rightComponentButton}>
            {rightComponent}
          </TouchableOpacity>
        )}
      </View>
      {showMenuButtons && !isWeb && (
        <View style={style.rowViewMobileWeb}>
          {headerMenu.map(menu => (
            <TouchableOpacity
              style={
                pathname === menu.pathName
                  ? style.headerTitleActiveButton
                  : style.headerTitleButton
              }
              onPress={() => navigate(menu.pathName)}>
              <Text
                style={
                  pathname === menu.pathName
                    ? style.headerTitleButtonActiveText
                    : style.headerTitleButtonText
                }>
                {menu.name}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

export default Header;
