import {Text, TouchableOpacity} from 'react-native';
import React from 'react';
import {style} from './Button.styles';
import {ButtonProps} from './Button.types';

const Button: React.FC<ButtonProps> = ({
  style: buttonStyle,
  name,
  textStyle,
  ...restProps
}) => {
  return (
    <TouchableOpacity
      {...restProps}
      style={[
        style.container,
        {opacity: restProps.disabled ? 0.5 : 1},
        buttonStyle,
      ]}>
      <Text style={[style.name, textStyle]}>{name}</Text>
    </TouchableOpacity>
  );
};

export default Button;
