import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAppSelector} from '../../../services/redux/hook';
import {NavigationNames} from '../../../utils/routeNames';

export const ProtectedRoute = () => {
  const {user} = useAppSelector(state => state.userReducer);
  const {pathname} = useLocation();

  if (
    user &&
    user?.hasProfileCompleted === false &&
    pathname !== NavigationNames.EditProfile
  ) {
    return <Navigate to={NavigationNames.EditProfile} replace />;
  }
  if (!user) {
    return <Navigate to={NavigationNames.Login} replace />;
  }

  return <Outlet />;
};
