import {Dimensions, StyleSheet} from 'react-native';
import {Breakpoints, Colors, FontFamily, FontSize} from '../../utils/theme';

const {width} = Dimensions.get('window');
export const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSecView: {
    paddingVertical: 25,
    paddingHorizontal: 20,
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginHorizontal: 25,
    maxWidth: Breakpoints.sm,
    width: width - 60,
  },
  modalLogoStyle: {
    maxWidth: 150,
    alignSelf: 'center',
    marginLeft: 20,
  },
  titleText: {
    fontFamily: FontFamily.PoppinsMedium,
    fontSize: FontSize.h3,
    color: Colors.blackA100,
    textAlign: 'center',
    marginBottom: 10,
  },
  messageText: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonStyle: {
    marginTop: 40,
    height: 40,
    marginHorizontal: 7,
    paddingHorizontal: 30,
    alignSelf: 'center',
  },
  buttonRowView: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  negativeButton: {
    marginTop: 40,
    height: 40,
    marginHorizontal: 7,
    paddingHorizontal: 30,
    alignSelf: 'center',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: Colors.primary,
  },
  negativeButtonText: {
    color: Colors.primary,
    fontSize: FontSize.bodyBig,
  },
});
