import * as yup from 'yup';
import {AddressObject, Panth} from '../services/api/response/app';
import {
  emailRegex,
  middleNameRegex,
  nameRegex,
  phoneRegex,
} from '../utils/validation/consts';
import {EditProfileFormFields} from '../screens/User/EditProfile/EditProfile.types';

yup.setLocale({
  mixed: {
    required: ({path}) => `profile.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `profile.errors.${path}.matches`,
  },
});

const stateSchemaRequired = yup.object<AddressObject>({
  id: yup.number().required(),
  name: yup.string().required(),
});

const panthsSchema = yup.object<Panth>({
  id: yup.number().required(),
  panthName: yup.string().required(),
});

const createOfficeFieldValidation = (
  fieldName: keyof EditProfileFormFields,
) => {
  return yup
    .mixed()
    .test(
      fieldName as string,
      `profile.errors.${fieldName}.required`,
      function (value) {
        const {
          officeAddressLine1,
          officeAddressLine2,
          officeState,
          officeCity,
          officeDistrict,
          officeArea,
          officeLandmark,
        } = this.parent as EditProfileFormFields;

        if (
          officeAddressLine1?.trim() ||
          officeAddressLine2?.trim() ||
          officeLandmark?.trim() ||
          (officeState && officeState.id) ||
          (officeCity && officeCity.id) ||
          (officeDistrict && officeDistrict.id) ||
          (officeArea && officeArea.id)
        ) {
          if (!value) {
            return false;
          }
        }

        return true;
      },
    );
};

export const editProfileFormSchema = yup
  .object<Record<keyof EditProfileFormFields, yup.AnySchema>>({
    profileImage: yup.string(),
    firstName: yup.string().trim().required().matches(nameRegex),
    middleName: yup.string().trim().matches(middleNameRegex),
    lastName: yup.string().trim().required().matches(nameRegex),
    // email: yup
    //   .string()
    //   .trim()
    //   .test({
    //     name: 'isEmail',
    //     test: (value, {createError}) => {
    //       if (value && !emailRegex.test(value)) {
    //         return createError({
    //           message: 'profile.errors.email.matches',
    //           path: 'email',
    //         });
    //       }
    //       return true;
    //     },
    //   }),
    email: yup
      .string()
      .trim()
      .required('profile.errors.email.required')
      .matches(emailRegex, 'profile.errors.email.matches'),
    mobileNumber: yup.string().trim().required().matches(phoneRegex),
    whatsAppNumber: yup
      .string()
      .trim()
      .test({
        name: 'isWhatsAppNumber',
        test: (value, {createError}) => {
          if (value && !phoneRegex.test(value)) {
            return createError({
              message: 'profile.errors.whatsAppNumber.matches',
              path: 'whatsAppNumber',
            });
          }
          return true;
        },
      }),
    occupation: yup.mixed().required(),
    gotra: yup.string().trim().required().matches(nameRegex),
    panth: panthsSchema.required(),
    panthName: yup
      .string()
      .trim()
      .when('panth', {
        is: (value: Panth) => value.panthName === 'Other',
        then: schema =>
          schema
            .trim()
            .required('profile.errors.panth.panthName.required')
            .matches(nameRegex),
        otherwise: schema => schema.notRequired(),
      }),
    contactAddressLine1: yup.string().trim().required(),
    contactAddressLine2: yup.string().trim().required(),
    contactState: stateSchemaRequired.required(),
    contactCity: stateSchemaRequired.required(),
    contactDistrict: stateSchemaRequired.required(),
    contactArea: stateSchemaRequired.required(),
    officeAddressLine1: createOfficeFieldValidation('officeAddressLine1'),
    officeAddressLine2: createOfficeFieldValidation('officeAddressLine2'),
    officeState: createOfficeFieldValidation('officeState'),
    officeCity: createOfficeFieldValidation('officeCity'),
    officeDistrict: createOfficeFieldValidation('officeDistrict'),
    officeArea: createOfficeFieldValidation('officeArea'),
  })
  .required();
