import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  labelText: {
    fontSize: FontSize.bodyBig,
    color: Colors.black,
    marginBottom: 9,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
  },
  rowView: {
    flexDirection: 'row',
    height: 44,
    borderRadius: 10,
    backgroundColor: Colors.background,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  valueText: {
    flex: 1,
    fontSize: FontSize.bodyBig,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
  },
  error: {
    fontSize: 12,
    color: Colors.maroon,
    marginTop: 5,
    fontFamily: 'Gotham-Book',
  },
});
