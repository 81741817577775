import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 30,
  },
  image: {
    width: '100%',
    height: 200,
    resizeMode: 'contain',
    tintColor: Colors.maroon,
  },
  logoStyle: {
    alignSelf: 'center',
  },
  title: {
    fontSize: FontSize.h1,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  message: {
    fontSize: FontSize.h4,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
  },
  button: {
    paddingHorizontal: 50,
  },
});
