import * as yup from 'yup';
import {LoginFormFields} from '../screens/Login/Login.types';
import {phoneRegex} from '../utils/validation/consts';

yup.setLocale({
  mixed: {
    required: ({path}) => `profile.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `profile.errors.${path}.matches`,
  },
});

export const loginFormSchema = yup
  .object<Record<keyof LoginFormFields, yup.AnySchema>>({
    mobileNumber: yup.string().required().matches(phoneRegex),
    password: yup.string().required(),
  })
  .required();
