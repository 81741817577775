import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: Colors.background,
    paddingBottom: 10,
    flex: 1,
  },
  image: {
    width: '100%',
    height: 150,
    resizeMode: 'contain',
  },
  contentView: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    gap: 8,
  },
  title: {
    fontFamily: FontFamily.PoppinsSemiBold,
    fontSize: FontSize.h5,
    color: Colors.blackA100,
    textDecorationLine: 'underline',
  },
  description: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
  },
  rowView: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  dateAndTime: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.body,
    color: Colors.lightBlack,
  },
  buttonContainer: {
    paddingHorizontal: 10,
    height: 40,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: FontSize.body,
  },
  dotActiveStyle: {
    backgroundColor: Colors.primary,
  },
  dotInActiveStyle: {
    backgroundColor: Colors.white,
  },
});
