import {
  View,
  Text,
  Modal,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import React from 'react';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {CenterLogo} from '../../assets/svg';
import Button from '../Button/Button';
import {style} from './Alert.styles';
import {useTranslation} from 'react-i18next';

const Alert: React.FC = () => {
  const {modalContent, closeModal} = useModal();
  const {t} = useTranslation();
  return (
    <Modal
      statusBarTranslucent={true}
      transparent
      animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
      visible={!!modalContent}
      {...modalContent?.modalProps}>
      {modalContent && (
        <TouchableWithoutFeedback style={{flex: 1}} onPress={closeModal}>
          <View style={style.container}>
            <TouchableWithoutFeedback>
              <View style={style.modalSecView}>
                {!modalContent?.hideCenterLogo && (
                  <CenterLogo
                    style={style.modalLogoStyle}
                    width="90%"
                    height={140}
                  />
                )}
                {modalContent?.title && (
                  <Text style={style.titleText}>{modalContent?.title}</Text>
                )}
                {modalContent?.message && (
                  <Text style={style.messageText}>{modalContent?.message}</Text>
                )}
                <View style={style.buttonRowView}>
                  {modalContent?.negativeButtonProps && (
                    <Button
                      style={style.negativeButton}
                      textStyle={style.negativeButtonText}
                      {...modalContent?.negativeButtonProps}
                    />
                  )}
                  <Button
                    name={t('misc.ok')}
                    style={style.buttonStyle}
                    onPress={closeModal}
                    {...modalContent?.positiveButtonProps}
                  />
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      )}
    </Modal>
  );
};

export default Alert;
