import {StyleSheet, Platform} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  keyBoardView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  contentContainer: {
    flex: 1,
    paddingBottom: 15,
    gap: 15,
  },
  description: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.maroon,
    marginBottom: 20,
  },
  boxView: {
    backgroundColor: Colors.white,
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    padding: 20,
  },
  title: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.blackA100,
    marginBottom: 15,
  },
  inputContainer: {
    marginBottom: 10,
    flex: 1,
  },
  nameRowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5,
    marginTop: 15,
  },
  submitButtonContainer: {
    marginHorizontal: 20,
    marginVertical: 30,
  },
  logoutText: {
    color: Colors.primary,
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    // flex: 0,
    fontFamily: FontFamily.PoppinsMedium,
    top: 1,
  },
  logoutButton: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    width: 120,
    position: 'absolute',
    right: Platform.OS === 'web' ? 100 : 20,
    display: 'flex',
    alignSelf: 'center',
    marginTop: Platform.OS === 'web' ? 30 : 15,
  },
});
