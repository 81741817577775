import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const Filter = (props: SvgProps) => (
  <Svg
    width={128}
    height={38}
    viewBox="0 0 122 122"
    {...props}
    fill="#FFFFFF"
    {...props}>
    <Path d="m24.96 15.823 2.814-.02c3.082-.02 6.164-.031 9.246-.041l3.185-.013c5.562-.02 11.124-.035 16.687-.044 5.75-.011 11.5-.046 17.25-.085 4.418-.026 8.835-.035 13.252-.038 2.12-.005 4.24-.017 6.36-.035 2.966-.025 5.931-.024 8.898-.017l2.656-.039c4.1.035 6.167.178 9.714 2.413 3.364 3.564 3.584 6.556 3.482 11.342C117.509 34.682 113.69 38.12 110 42a8187.033 8187.033 0 0 0-3.848 4.129 1107.35 1107.35 0 0 1-8.25 8.723 236.485 236.485 0 0 0-9.092 10.112C87 67 87 67 84.792 69.067c-3.163 3.08-5.512 5.373-6.31 9.81-.026 3.186.126 6.32.33 9.498.186 12.238.186 12.238-3.46 16.488-3.153 2.88-6.675 4.987-10.352 7.137a213.696 213.696 0 0 0-4.438 3.687c-2.66 1.962-3.564 2.313-7 2.75C51 118 51 118 50 117c.013-2.853.082-5.689.176-8.54 1.623-21.37 1.623-21.37-5.896-40.3-2.435-2.202-4.913-4.202-7.643-6.027-2.134-1.477-3.652-3.234-5.262-5.258-2.536-2.955-5.165-5.807-7.827-8.649a689.88 689.88 0 0 1-6.199-6.744 458.29 458.29 0 0 0-3.454-3.744c-3.697-4.328-4.715-7.75-4.364-13.465.67-3.248 2.043-5.061 4.469-7.273 3.386-1.693 7.235-1.166 10.96-1.177ZM19 25c.423 4.832 2.74 7.358 6 10.687l3.031 3.2 1.528 1.595c2.025 2.133 3.983 4.323 5.941 6.518 4.233 4.72 8.523 9.385 12.824 14.043L51 64l1.636 1.325c3.757 3.387 4.719 6.346 5.081 11.4.15 3.74.166 7.471.158 11.212.018 1.558.039 3.116.062 4.674.052 3.797.067 7.592.063 11.389 6.627-3.14 6.627-3.14 12-8 .817-4.026.447-7.92.187-12-.12-7.653-.08-13.445 5.16-19.346a117.377 117.377 0 0 1 7.114-6.361c1.926-1.618 3.507-3.415 5.101-5.356 3.085-3.577 6.289-7.037 9.5-10.5 1.53-1.657 3.06-3.316 4.589-4.976 1.146-1.242 2.3-2.477 3.454-3.711 3.42-3.721 3.42-3.721 3.895-8.75-3.176-1.588-6.602-1.138-10.09-1.13l-2.533-.005c-2.771-.004-5.543-.001-8.315.002l-5.761-.003c-4.028-.001-8.057.001-12.085.006-5.172.005-10.344.002-15.516-.004-3.967-.004-7.934-.002-11.9 0-1.908 0-3.815 0-5.723-.002-2.662-.003-5.325.001-7.987.007l-2.406-.007c-3.971-.07-3.971-.07-7.684 1.136Z" />
  </Svg>
);
export default Filter;
