import {StyleSheet} from 'react-native';
import {Colors} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
    backgroundColor: Colors.background,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    resizeMode: 'cover',
  },
  placeholder: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGray,
    borderRadius: 100,
  },
  initialsText: {
    color: Colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textStyle: {
    color: Colors.lightBlack,
  },
  editableButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundColor: Colors.primaryBackground,
    padding: 8,
    width: 30,
    height: 30,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
