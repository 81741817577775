import {Platform, StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

// const {height} = Dimensions.get('window');

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: '80%',
    height: '36%',
    borderRadius: 20,
    backgroundColor: Colors.white,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },

  title: {
    fontSize: FontSize.h2,
    fontFamily: FontFamily.PoppinsSemiBold,
    marginBottom: 5,
    color: Colors.blackA100,
    textAlign: Platform.OS === 'web' ? 'center' : 'left',
  },
  description: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    textAlign: Platform.OS === 'web' ? 'center' : 'left',
    marginBottom: 50,
  },
  inputContainer_M_View: {
    marginBottom: 20,
  },
  inputContainer: {
    marginBottom: 40,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: Platform.OS === 'web' ? '60%' : '90%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
