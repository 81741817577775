import {StyleSheet} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  imagebackGround: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.background,
  },
  userLogo: {
    alignSelf: 'center',
    marginTop: 50,
  },
  scrollView: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  backButton: {
    padding: 20,
    paddingLeft: 15,
    alignSelf: 'flex-start',
  },
  containerView: {
    paddingBottom: 30,
    paddingHorizontal: 20,
    gap: 30,
    marginTop: 30,
  },
});
