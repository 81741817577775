import {StyleSheet, Platform} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  labelText: {
    fontSize: FontSize.bodyBig,
    color: Colors.blackA100,
    marginBottom: 9,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
    textTransform: 'capitalize',
  },
  leftIcon: {
    height: '100%',
    justifyContent: 'center',
    marginLeft: 20,
  },
  rowView: {
    width: '100%',
    flexDirection: 'row',
    height: 44,
    borderRadius: 10,
    backgroundColor: Colors.background,
  },
  textInput: {
    flex: 1,
    color: Colors.blackA100,
    paddingVertical: 0,
    height: '100%',
    paddingHorizontal: 15,
    fontFamily: FontFamily.PoppinsMedium,
    backgroundColor: Colors.background,
    borderRadius: 10,
  },
  error: {
    fontSize: FontSize.body,
    color: Colors.maroon,
    marginTop: 5,
    fontFamily: FontFamily.PoppinsRegular,
  },
  showButton: {
    position: 'absolute',
    top: 50,
    left: Platform.OS === 'web' ? '90%' : '80%',
    transform: [{translateY: -12}],
    padding: 5,
  },
  showText: {
    color: Colors.primary,
    fontSize: 15,
    textAlign: 'center',
  },
});
