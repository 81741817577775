import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
    gap: 15,
  },
  logoView: {
    width: 40,
    height: 40,
    borderRadius: 8,
    backgroundColor: Colors.primaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    flex: 1,
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.blackA100,
  },
});
