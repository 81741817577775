import {Platform, StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  labelText: {
    fontSize: FontSize.bodyBig,
    color: Colors.blackA100,
    marginBottom: 9,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
    textTransform: 'capitalize',
  },
  leftIcon: {
    height: '100%',
    justifyContent: 'center',
    marginLeft: 20,
  },
  rowView: {
    flexDirection: 'row',
    height: 44,
    borderRadius: 10,
    backgroundColor: Colors.background,
    alignItems: 'center',
  },
  clearButton: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 10,
  },
  textInput: {
    flex: 1,
    height: '100%',
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    paddingHorizontal: 15,
  },
  valueText: {
    flex: 1,
    fontSize: FontSize.bodyBig,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    marginLeft: 15,
  },
  imageButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 12,
  },
  error: {
    fontSize: 12,
    color: Colors.maroon,
    marginTop: 5,
    fontFamily: 'Gotham-Book',
  },
  flatListStyle: {
    marginTop: 5,
    borderRadius: 10,
    maxHeight: Platform.OS === 'web' ? 250 : 'auto',
  },
  renderRowView: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderBottomWidth: 0.8,
    borderBottomColor: Colors.border,
    backgroundColor: Platform.OS === 'web' ? Colors.background : 'none',
  },
  rowText: {
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
    fontFamily: FontFamily.PoppinsRegular,
    paddingHorizontal: Platform.OS === 'web' ? 0 : 15,
  },
  arrowButton: {
    height: '100%',
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    flex: 1,
  },
  modalRowView: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginTop: 20,
    gap: 15,
    maxHeight: 50,
  },
  closeButton: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
