import {Dimensions, Platform, StyleSheet} from 'react-native';
import {Breakpoints, Colors, FontFamily, FontSize} from '../../utils/theme';

const {height} = Dimensions.get('window');

export const style = StyleSheet.create({
  modalView: {
    margin: 20,
    width: '80%',
    height: '36%',
    borderRadius: 20,
    backgroundColor: Colors.white,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  chooseLangTitle: {
    fontSize: 20,
    color: Colors.maroon,
    fontWeight: '600',
  },
  langListContainer: {
    width: 200,
    height: 40,
    borderWidth: 2,
    display: 'flex',
    justifyContent: 'center',
    alignite: 'center',
    borderStyle: 'solid',
    borderColor: Colors.maroon,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  langList: {
    fontSize: 20,
    color: 'black',
    fontWeight: '400',
    fontFamily: FontFamily.PoppinsItalic,
    alignSelf: 'center',
  },
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
    height: height,
  },
  contentContainer: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
  },
  keyBoardView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  logoContainer: {
    width: 500,
    marginBottom: 30,
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logoStyle: {
    maxWidth: 350,
    marginLeft: 30,
    alignSelf: 'center',
    marginBottom: 20,
  },
  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: 20,
    color: '#17179b',
    fontWeight: '600',
  },
  logoTextContainer: {
    maxWidth: '90%',
    fontFamily: FontFamily.PoppinsBold,
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: FontSize.h2,
    fontFamily: FontFamily.PoppinsSemiBold,
    marginBottom: 5,
    color: Colors.blackA100,
    textAlign: Platform.OS === 'web' ? 'center' : 'left',
  },
  description: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    textAlign: Platform.OS === 'web' ? 'center' : 'left',
    marginBottom: 50,
  },
  inputContainer_M_View: {
    marginBottom: 20,
  },
  inputContainer: {
    marginBottom: 40,
  },
  haveAccountContainer: {
    flexDirection: 'row',
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 50,
  },
  didNotText: {
    fontSize: FontSize.body,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    marginRight: 5,
  },
  signUp: {
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.primary,
  },
  modalcontainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSecView: {
    paddingVertical: 25,
    paddingHorizontal: 20,
    backgroundColor: Colors.white,
    borderRadius: 20,
    marginHorizontal: 25,
    maxWidth: Breakpoints.sm,
  },
  modalLogoStyle: {
    maxWidth: 200,
    alignSelf: 'center',
  },
  titleText: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.body,
    color: Colors.lightBlack,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonStyle: {
    marginTop: 40,
    height: 40,
    marginHorizontal: 7,
    paddingHorizontal: 30,
    alignSelf: 'center',
  },
  contactRowView: {
    flexDirection: 'row',
    gap: 10,
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  featureInfoContainer: {
    justifyContent: 'flex-start',
    paddingVertical: 0,
    gap: 8,
  },
  featureInfoName: {
    flex: 0,
    fontSize: FontSize.body,
  },
  featureInfoLogo: {
    width: 'auto',
    height: 'auto',
  },
  cover: {
    flexDirection: 'row',
    marginBottom: 20,
    opacity: 0.8,
  },
  loginButton: {
    marginTop: 40,
    width: '100%',
    height: 50,
    marginHorizontal: 7,
    paddingHorizontal: 30,
    alignSelf: 'center',
  },
  forgotPassword: {
    fontSize: 18,
    fontWeight: '400',
    color: Colors.primary,
  },
  backButton: {
    paddingTop: 10,
    paddingHorizontal: Platform.OS === 'web' ? 0 : 20,
  },
  backText: {
    fontSize: FontSize.h4,
    fontFamily: FontFamily.PoppinsSemiBold,
    marginBottom: 5,
    color: Colors.primary,
  },
});
