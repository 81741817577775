import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    //@ts-ignore
    maxHeight: '100vh',
    display: 'flex',
    paddingHorizontal: 20,
  },
  bannerView: {
    flex: 1,
    marginTop: 40,
  },
  scrollView: {
    flex: 1,
    maxHeight: '100%',
    display: 'flex',
    gap: 30,
    paddingTop: 90,
  },
  dotActiveStyle: {
    backgroundColor: Colors.primary,
  },
  dotInActiveStyle: {
    backgroundColor: Colors.white,
    display: 'none',
  },
  aboutUs: {
    fontSize: FontSize.h3,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsBold,
    marginBottom: 15,
  },
  description: {
    fontSize: FontSize.h6,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsRegular,
    lineHeight: 30,
  },
  semiTitle: {
    fontSize: FontSize.h4,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsSemiBold,
  },
  boardMemberText: {
    fontSize: FontSize.h1,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsSemiBold,
    textAlign: 'center',
  },
  footer: {
    backgroundColor: Colors.white,
    height: 180,
    paddingVertical: 30,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: Colors.border,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  logoContainer: {
    width: 450,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },

  logoText: {
    fontFamily: FontFamily.PoppinsItalic,
    fontSize: 20,
    color: '#17179b',
    fontWeight: '900',
  },
  logoTextContainer: {
    width: '80%',
    fontFamily: FontFamily.PoppinsBold,
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerItemView: {
    flexDirection: 'row',
    gap: 25,
    paddingHorizontal: 10,
    flexWrap: 'wrap',
    maxWidth: '100%',
    justifyContent: 'center',
  },
  footerLinks: {
    fontSize: FontSize.h6,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsMedium,
    textDecorationLine: 'underline',
  },
  imageBackground: {
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
  },
  imageStripView: {
    paddingVertical: 10,
    alignItems: 'center',
    backgroundColor: Colors.lightBackground,
    paddingHorizontal: 20,
    marginBottom: 100,
  },
  imageText: {
    fontSize: FontSize.h6,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
  },
  copyRights: {
    fontSize: FontSize.bodyBig,
    textAlign: 'center',
    color: Colors.black,
    width: '300%',
    marginBottom: 20,
    fontFamily: FontFamily.PoppinsRegular,
  },
});
