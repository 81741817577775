import {Dimensions, StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    borderRadius: Dimensions.get('window').width / 2,
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoView: {
    width: 80,
    height: 80,
    padding: 15,
    borderRadius: Dimensions.get('window').width / 2,
  },
  name: {
    marginTop: 10,
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.blackA100,
  },
  webContainer: {
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: 16,
    padding: 20,
    flexDirection: 'row-reverse',
  },
  webName: {
    fontSize: FontSize.h5,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.blackA100,
  },
});
