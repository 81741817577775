import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import {style} from './Login.styles';
import {
  Button,
  CustomStatus,
  FeatureInfoButton,
  InputWithControl,
  Spinner,
} from '../../components';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavigationNames} from '../../utils/routeNames';
import {useLocation, useNavigate} from 'react-router-dom';
import {Breakpoints} from '../../utils/theme';
import useDimensionsHook from '../../hooks/useDimensions';
import {Email, CenterLogo, WhatsApp} from '../../assets/svg';
import {getErrorProps} from '../../utils/validation/validationFunctions';
import {useLoginController} from './Login.controller';
import NewUserModal from './NewUserModal';
import {Control, FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const Login: React.FC = () => {
  const {t} = useTranslation();
  const intl = useIntl();
  const {state} = useLocation();
  const {windowWidth} = useDimensionsHook();
  const navigate = useNavigate();
  const passwordRef = useRef<TextInput | null>(null);
  const [modalShown, setModalShown] = useState(false);
  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    isLoading,
    setValue,
    hasAccount,
    newUserModal,
    openEmail,
    openWhatsApp,
  } = useLoginController({
    isNewUserRegister: state?.isNewUserRegister,
  });

  useEffect(() => {
    if (hasAccount) {
      const timer = setTimeout(() => {
        passwordRef.current?.focus();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [hasAccount]);

  useEffect(() => {
    if (newUserModal && !modalShown) {
      setModalShown(true);
    } else if (!newUserModal) {
      setModalShown(false); // Reset when modal is closed
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserModal]);

  const signUpPress = (): void => {
    navigate(NavigationNames.SignUp);
  };

  const forgotPasswordPress = (): void => {
    navigate(NavigationNames.ForgotPassword);
  };

  const onSuccess = (): void => {
    navigate(NavigationNames.Home);
  };

  const profileNotCompleted = (): void => {
    navigate(NavigationNames.EditProfile);
  };

  return (
    <View
      style={[
        style.container,
        {alignItems: windowWidth > Breakpoints.md ? 'center' : 'stretch'},
      ]}>
      <CustomStatus />
      <Spinner visible={isLoading} />
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth:
                  windowWidth > Breakpoints.md ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <View style={style.logoContainer}>
              <CenterLogo style={style.logoStyle} />
              <View style={style.logoTextContainer}>
                <Text style={style.logoText}>
                  श्री समस्त राजस्थान जैन समाज , वडोदरा
                </Text>
              </View>
            </View>
            <Text style={style.title}>
              <FormattedMessage id={t('login.welcome')} />
            </Text>
            {/* <Text style={style.description}>
              <FormattedMessage id={t('login.loginMessage')} />
            </Text> */}
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="mobileNumber"
              autoFocus={windowWidth > Breakpoints.md}
              {...getErrorProps(intl, errors.mobileNumber)}
              label={t('misc.phoneNumber')}
              containerStyle={style.inputContainer}
              keyboardType="phone-pad"
              returnKeyType="done"
              maxLength={10}
              onSubmitEditing={handleSubmit(data =>
                onSubmit(data, onSuccess, profileNotCompleted),
              )}
            />
            {hasAccount && (
              <>
                <InputWithControl
                  control={control as unknown as Control<FieldValues>}
                  name="password"
                  {...getErrorProps(intl, errors.password)}
                  label={t('misc.password')}
                  containerStyle={style.inputContainer}
                  keyboardType="phone-pad"
                  returnKeyType="done"
                  ref={passwordRef}
                  secureTextEntry={true}
                  show={true}
                  onSubmitEditing={handleSubmit(data =>
                    onSubmit(data, onSuccess, profileNotCompleted),
                  )}
                />
                <Button
                  name={<FormattedMessage id={t('login.login')} />}
                  style={style.loginButton}
                  onPress={handleSubmit(data =>
                    onSubmit(data, onSuccess, profileNotCompleted),
                  )}
                />
              </>
            )}

            <View>
              <TouchableOpacity onPress={forgotPasswordPress}>
                <Text style={style.forgotPassword}>
                  <FormattedMessage id={t('user.forgotPassword')} />
                </Text>
              </TouchableOpacity>
            </View>

            <View style={style.haveAccountContainer}>
              <Text style={style.didNotText}>
                <FormattedMessage id={t('login.notHaveAccount')} />
              </Text>
              <TouchableOpacity activeOpacity={0.8} onPress={signUpPress}>
                <Text style={style.signUp}>
                  <FormattedMessage id={t('login.signUp')} />
                </Text>
              </TouchableOpacity>
            </View>
            <View style={style.contactRowView}>
              <FeatureInfoButton
                name="+917600136222"
                icon={<WhatsApp width={15} height={15} />}
                onPress={openWhatsApp}
                containerStyle={[
                  style.featureInfoContainer,
                  {justifyContent: 'flex-start'},
                ]}
                logoContainerStyle={style.featureInfoLogo}
                nameStyle={style.featureInfoName}
              />
              <FeatureInfoButton
                name="ssrjsvadodara@gmail.com"
                icon={<Email width={15} height={15} />}
                onPress={openEmail}
                containerStyle={[
                  style.featureInfoContainer,
                  {justifyContent: 'flex-end'},
                ]}
                logoContainerStyle={style.featureInfoLogo}
                nameStyle={style.featureInfoName}
              />
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      <NewUserModal
        visiable={newUserModal && !modalShown}
        setVisible={() => setValue('newUserModal', false)}
      />
    </View>
  );
};

export default Login;
