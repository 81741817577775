import {MatrimonialProfile} from '../../api/response/matrimonial';
import {UserActionType} from '../user/user.types';
import {MatrimonialActionType, MatrimonialState} from './matrimonial.types';

const initialState: MatrimonialState = {
  matrimonialProfiles: [],
};

const matrimonialReducer = (
  state: MatrimonialState = initialState,
  action: any,
): MatrimonialState => {
  switch (action.type) {
    case MatrimonialActionType.ProfileList:
      return {
        ...state,
        matrimonialProfiles: action.payload as MatrimonialProfile[],
      };
    case UserActionType.Logout:
      return initialState;
    default:
      return state;
  }
};

export default matrimonialReducer;
