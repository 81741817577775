import React from 'react';
import {ActivityIndicator, Modal, View} from 'react-native';
import {SpinnerProps} from './Spinner.types';
import {style} from './Spinner.styles';
import {Colors} from '../../utils/theme';

const Spinner: React.FC<SpinnerProps> = ({
  activityColor = Colors.primary,
  activitySize = 'large',
  containerStyle,
  visible = false,
}) => {
  return (
    <Modal visible={visible} transparent statusBarTranslucent={true}>
      <View style={[style.container, containerStyle]}>
        <ActivityIndicator size={activitySize} color={activityColor} />
      </View>
    </Modal>
  );
};

export default Spinner;
