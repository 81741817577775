import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    borderRadius: 10,
    flex: 1,
    alignItems: 'center',
    shadowColor: Colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: Colors.white,
    paddingVertical: 25,
    paddingHorizontal: 10,
    gap: 15,
  },
  image: {
    width: 60,
    height: 60,
    resizeMode: 'cover',
    borderRadius: 60,
    backgroundColor: Colors.background,
  },
  name: {
    color: Colors.blackA100,
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  occupation: {
    color: Colors.lightBlack,
    fontSize: FontSize.body,
    fontFamily: FontFamily.PoppinsRegular,
    textAlign: 'center',
  },
  buttonStyle: {
    width: '60%',
    height: 30,
    borderRadius: 5,
  },
  buttonTextStyle: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.body,
  },
});
