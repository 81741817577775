import {KeyboardAvoidingView, Platform, ScrollView, View} from 'react-native';
import React from 'react';
import {useAddProfile} from './AddProfile.controller';
import {
  Button,
  DropDown,
  Header,
  PDFPicker,
  Spinner,
} from '../../../components';
import {BackArrow} from '../../../assets/svg';
import {FormattedMessage, useIntl} from 'react-intl';
import {style} from './AddProfile.styles';
import {Control, Controller, FieldValues} from 'react-hook-form';
import {getErrorProps} from '../../../utils/validation/validationFunctions';
import {useNavigate} from 'react-router-dom';
import useDimensionsHook from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {useTranslation} from 'react-i18next';

const AddProfile: React.FC = () => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const intl = useIntl();
  const {
    control,
    isLoading,
    familyMembers,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
  } = useAddProfile();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const onBackButtonPress = (): void => {
    navigate(-1);
  };

  return (
    <View style={[style.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <Spinner visible={isLoading} />
      <Header
        leftComponent={<BackArrow width={20} height={20} />}
        leftComponentPress={onBackButtonPress}
        title={<FormattedMessage id={t('matrimonial.addMatrimonialProfile')} />}
        rightComponent={null}
      />
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth: isWeb ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <Controller
              control={control as unknown as Control<FieldValues>}
              name="familyMember"
              render={({field: {onChange, value}}) => (
                <DropDown
                  data={familyMembers}
                  displayKey="name"
                  label={t('matrimonial.selectFamilyMember') + '*'}
                  disabled={familyMembers.length === 0}
                  value={value?.name}
                  onSelectItem={onChange}
                  {...getErrorProps(intl, errors.familyMember?.name)}
                />
              )}
            />
            <PDFPicker
              label={t('matrimonial.bioDataOptional')}
              onPdfPicked={document => setValue('biodata', document)}
            />
            <Button
              disabled={isLoading || familyMembers.length === 0}
              style={style.submitButtonContainer}
              name={<FormattedMessage id={t('misc.submit')} />}
              onPress={handleSubmit(data => onSubmit(data, onBackButtonPress))}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default AddProfile;
