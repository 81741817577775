import {useCallback, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {BoardMembersController, LoadingState} from './BoardMembers.types';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {AllBoardMembers} from '../../../services/api/response/community';
import {boardMembersAction} from '../../../services/redux/community/community.action';

export const useBoardMembersController = (): BoardMembersController => {
  const dispatch = useAppDispatch();
  const {boardMembers} = useAppSelector(state => state.communityReducer);
  const [loading, setLoading] = useState<LoadingState>({
    fetching: false,
    refreshing: false,
  });

  const fetchBoardMembers = useCallback(
    async (isRefreshing?: boolean) => {
      try {
        setLoading({
          fetching: boardMembers?.length === 0,
          refreshing: !!isRefreshing,
        });
        const {data} = await axios.post<AllBoardMembers>(
          ApiRoutes.BoardMembers,
        );
        dispatch(boardMembersAction(data.boardMembers));
        setLoading({
          fetching: false,
          refreshing: false,
        });
      } catch (e) {
        console.error(e);
        setLoading({
          fetching: false,
          refreshing: false,
        });
      }
    },
    [boardMembers, dispatch],
  );

  useEffect(() => {
    fetchBoardMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {boardMembers, loading, fetchBoardMembers};
};
