import React from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {NavigationNames} from '../../utils/routeNames';
import {
  EditProfile,
  Home,
  Login,
  AccountAndSettings,
  ProfileList,
  SignUp,
  AddFamilyMember,
  FamilyMembers,
  ProflleDetail,
  FamilyMemberDetails,
  AddProfile,
  Events,
  EventDetails,
  AllMembers,
  BoardMembers,
  LandingPage,
  ContactUs,
  PageNotFound,
  ForgotPassword,
  OtherFamilyMembers,
} from '../../screens';
import {NavigationContainer} from '@react-navigation/native';
import {Platform, StyleSheet, View} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {ProtectedRoute} from './web/ProtectedRoute';
import {PublicRoute} from './web/PublicRoute';
import {ResponseInterceptor} from '../../services/api/responseInterceptor/responseInterceptor.web';
import {Breakpoints} from '../../utils/theme';
import HomeWrapper from '../../screens/WrapperPages/HomeWrapper/HomeWrapper';
import useDimensionsHook from '../../hooks/useDimensions';

export const routes = [
  {
    path: '*',
    element: <PageNotFound />,
  },
  {
    path: '/',
    element: <PublicRoute />,
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: NavigationNames.ContactUs,
        element: <ContactUs />,
      },
      {
        path: NavigationNames.Login,
        element: <Login />,
      },
      {
        path: NavigationNames.SignUp,
        element: <SignUp />,
      },
      {
        path: NavigationNames.ForgotPassword,
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <HomeWrapper />,
        children: [
          {
            path: NavigationNames.Home,
            element: <Home />,
          },
          {
            path: NavigationNames.Event,
            element: <Events />,
          },
          {
            path: NavigationNames.Matrimonial,
            element: <ProfileList />,
          },
          {
            path: NavigationNames.Community,
            element: <AllMembers />,
          },
          {
            path: NavigationNames.OtherFamilyMembers,
            element: <OtherFamilyMembers />,
          },
          {
            path: NavigationNames.BoardMembers,
            element: <BoardMembers />,
          },
        ],
      },
      {
        path: NavigationNames.AccountAndSettings,
        element: <AccountAndSettings />,
      },
      {
        path: NavigationNames.EditProfile,
        element: <EditProfile />,
      },
      {
        path: NavigationNames.FamilyMembers,
        element: <FamilyMembers />,
      },
      {
        path: NavigationNames.AddFamilyMember,
        element: <AddFamilyMember />,
      },
      {
        path: NavigationNames.ProfileDetail,
        element: <ProflleDetail />,
      },
      {
        path: NavigationNames.FamilyMemberDetails,
        element: <FamilyMemberDetails />,
      },
      {
        path: NavigationNames.AddMatrimonialProfile,
        element: <AddProfile />,
      },
      {
        path: NavigationNames.EventDetails,
        element: <EventDetails />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const Routes = () => {
  const {windowWidth} = useDimensionsHook();
  const isScreenIsSmaller =
    Platform.OS === 'web' &&
    windowWidth < Breakpoints.xl &&
    windowWidth > Breakpoints.md;
  return (
    <NavigationContainer
      documentTitle={{
        enabled: true,
        formatter: () => {
          return 'Shree Samast Rajasthan Jain Samaj';
        },
      }}>
      <View
        style={[
          style.container,
          {paddingHorizontal: isScreenIsSmaller ? '8%' : undefined},
        ]}>
        <SafeAreaProvider>
          <RouterProvider router={router} />
          <ResponseInterceptor />
        </SafeAreaProvider>
      </View>
    </NavigationContainer>
  );
};

export default Routes;

const style = StyleSheet.create({
  container: {
    //@ts-ignore
    minHeight: '100vh',
    maxWidth: Breakpoints.xl,

    width: '100%',
    alignSelf: 'center',
    position: 'relative',
  },
});
