import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  imageBackgroundView: {
    width: '100%',
    height: 350,
    backgroundColor: Colors.background,
  },
  scrollView: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  backButton: {
    width: 40,
    height: 40,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    alignSelf: 'flex-start',
    borderRadius: 50,
    backgroundColor: 'rgba(0,0,0,0.4)',
    position: 'absolute',
  },
  containerView: {
    paddingBottom: 30,
    paddingHorizontal: 20,
    gap: 15,
    marginTop: 30,
  },
  title: {
    fontFamily: FontFamily.PoppinsSemiBold,
    fontSize: FontSize.h5,
    color: Colors.primary,
    textDecorationLine: 'underline',
  },
  description: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
    textAlign: 'justify',
  },
  subTitleView: {
    paddingVertical: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.border,
    borderBottomColor: Colors.border,
    paddingHorizontal: 20,
  },
  subTitle: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.body,
    color: Colors.blackA100,
  },
  rowView: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    paddingHorizontal: 20,
    marginTop: 10,
  },
  dateAndTime: {
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
  },
  button: {
    marginHorizontal: 20,
    marginVertical: 40,
    borderRadius: 8,
  },
  dotActiveStyle: {
    backgroundColor: Colors.primary,
  },
  dotInActiveStyle: {
    backgroundColor: Colors.white,
  },
  image: {
    width: '100%',
    height: 350,
    resizeMode: 'contain',
  },
});
