import React, {createContext, useContext, useState, ReactNode} from 'react';
import {ModalContent, ModalContextType} from './modalContext.types';

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({children}) => {
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);

  const openModal = ({
    message,
    modalProps,
    negativeButtonProps,
    positiveButtonProps,
    title,
    content,
    hideCenterLogo = false,
  }: ModalContent) => {
    setModalContent({
      title,
      message,
      content,
      positiveButtonProps,
      negativeButtonProps,
      modalProps,
      hideCenterLogo,
    });
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const contextValue: ModalContextType = {
    modalContent,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};
