import React, {useCallback, useState} from 'react';
import {PDFPickerProps} from './PDFPicker.types';
import {Text, TouchableOpacity, View} from 'react-native';
import {style} from './PDFPicker.styles';
import {PdfIcon} from '../../assets/svg';
import {Colors} from '../../utils/theme';
import {FormattedMessage} from 'react-intl';

const PdfPicker: React.FC<PDFPickerProps> = ({
  onPdfPicked,
  label,
  labelStyle,
  ...restProps
}) => {
  const [pdf, setPdf] = useState<string>();
  const pickPdf = useCallback(async () => {
    try {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'application/pdf');

      input.onchange = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64 = reader.result?.toString().split(',')[1];
          setPdf(base64?.toString());
          onPdfPicked && onPdfPicked(base64?.toString() || '');
        };

        reader.readAsDataURL(file);
      };

      input.click();
    } catch (err) {
      console.error(err);
    }
  }, [onPdfPicked]);

  return (
    <View>
      {label !== '' && label && (
        <Text style={[style.labelText, labelStyle]}>{label}</Text>
      )}
      <TouchableOpacity
        {...restProps}
        onPress={pickPdf}
        activeOpacity={0.5}
        style={style.container}>
        <PdfIcon fill={Colors.primary} />
        <Text style={style.nameText}>
          {pdf ? (
            `${pdf.slice(0, 15)}.pdf`
          ) : (
            <FormattedMessage id="matrimonial.choosePDF" />
          )}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default PdfPicker;
