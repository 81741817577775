import React from 'react';
import {NavigationNames} from '../../../utils/routeNames';
import {AllMembers, BoardMembers} from '../../../screens';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {TopTabNavigatorParamList} from '../routes.types';
import {Colors, FontFamily, FontSize} from '../../../utils/theme';
import {StyleSheet} from 'react-native';
import {useTranslation} from 'react-i18next';

const Tab = createMaterialTopTabNavigator<TopTabNavigatorParamList>();

const CommunityTopTab = () => {
  const {t} = useTranslation();
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors.primary,
        tabBarIndicatorStyle: {
          backgroundColor: Colors.primary,
        },
        tabBarLabelStyle: style.tabBarLabelStyle,
      }}
      initialRouteName={NavigationNames.AllMembers}>
      <Tab.Screen
        name={NavigationNames.AllMembers}
        component={AllMembers}
        options={{title: t('misc.allMembers')}}
      />
      <Tab.Screen
        name={NavigationNames.BoardMembers}
        component={BoardMembers}
        options={{
          title: t('misc.boardMembers'),
        }}
      />
    </Tab.Navigator>
  );
};

export default CommunityTopTab;

const style = StyleSheet.create({
  tabBarLabelStyle: {
    textTransform: 'capitalize',
    fontFamily: FontFamily.PoppinsRegular,
    fontSize: FontSize.bodyBig,
  },
});
