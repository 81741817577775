import {StyleProp, TextStyle, ViewStyle} from 'react-native';
import {FontFamily, FontSize} from '../../utils/theme';
import {AvatarSize} from './Avatar.types';

export const getAvatarTextVariant = (
  size: AvatarSize,
): StyleProp<TextStyle> => {
  if (size === 'small') {
    return {fontSize: FontSize.h6, fontFamily: FontFamily.PoppinsRegular};
  } else if (size === 'medium') {
    return {
      fontSize: FontSize.h5,
      fontFamily: FontFamily.PoppinsMedium,
    };
  }
  return {fontSize: FontSize.h4, fontFamily: FontFamily.PoppinsSemiBold};
};

export const getAvatarHeightWidth = (
  size: AvatarSize,
): StyleProp<ViewStyle> => {
  if (size === 'large') {
    return {width: 80, height: 80};
  } else if (size === 'medium') {
    return {width: 60, height: 60};
  }
  return {width: 40, height: 40};
};
