import {RouteProp} from '@react-navigation/native';
import {PublicNavigatorParamList} from '../../../setup/routes/routes.types';
import {NavigationNames} from '../../../utils/routeNames';
import {User} from '../../../services/api/response/user';
import {StackNavigationProp} from '@react-navigation/stack';

export interface ProfileDetailController {
  profile: User | undefined;
  isLoading: boolean;
  openWhatsApp: (phoneNumber: string) => Promise<void>;
}

export type ProfileDetailNavigationProp =
  StackNavigationProp<PublicNavigatorParamList>;

export type ProfileDetailRouteProp = RouteProp<
  PublicNavigatorParamList,
  NavigationNames.ProfileDetail
>;

export const getWhatsAppURLs = (number: string) => ({
  UserWhatsApp: `whatsapp://send?&phone=${number}`,
  UserWhatsAppWeb: `https://api.whatsapp.com/send?phone=${number}`,
});
