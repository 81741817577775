import {StyleSheet} from 'react-native';
import {Colors} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'stretch',
    borderRadius: 10,
    backgroundColor: Colors.background,
  },
});
