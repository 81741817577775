import {useCallback, useEffect, useMemo, useState} from 'react';
import axios from '../../services/api/api';
import {ApiRoutes} from '../../services/api/path';
import {useNavigation} from '@react-navigation/native';
import {getBannerAction} from '../../services/redux/app/app.action';
import {useAppDispatch, useAppSelector} from '../../services/redux/hook';
import {HomeController, HomeNavigationProps} from './Home.types';
import {FeatureTileProps} from '../../components/featureTile/featureTile.types';
import Matrimonial from '../../assets/svg/category/matrimonial';
import {NavigationNames} from '../../utils/routeNames';
import {CommunityMembers, AboutUs, BoardMembers} from '../../assets/svg';
import {useTranslation} from 'react-i18next';
import {BannerResponse} from '../../services/api/response/app';

export const useHomeController = (): HomeController => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeNavigationProps>();
  const {banners} = useAppSelector(state => state.appReducer);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const {t} = useTranslation();

  const fetchBanners = useCallback(
    async (isRefreshing?: boolean) => {
      try {
        setRefreshing(!!isRefreshing);
        const {data} = await axios.post<BannerResponse>(ApiRoutes.Banners);
        const bannerData = data.loginBanner?.map(banner => ({
          ...banner,
          path: `${ApiRoutes.BaseURL}${banner.path.substring(1)}`,
        }));
        dispatch(getBannerAction(bannerData));
        setRefreshing(false);
      } catch (e) {
        setRefreshing(false);
        console.error(e);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  const features = useMemo((): FeatureTileProps[] => {
    return [
      {
        icon: BoardMembers,
        title: t('home.boardMembers'),
        onPress: () => {
          navigation.navigate(NavigationNames.BoardMembers);
        },
      },
      {
        icon: CommunityMembers,
        title: t('home.community'),
        onPress: () => {
          navigation.navigate(NavigationNames.Community);
        },
      },
      {
        icon: Matrimonial,
        title: t('home.matrimonial'),
        onPress: () => {
          navigation.navigate(NavigationNames.Matrimonial);
        },
      },
      {
        icon: AboutUs,
        title: t('home.aboutUs'),
        onPress: () => {
          navigation.navigate(NavigationNames.AboutUs);
        },
      },
    ];
  }, [t, navigation]);

  return {
    banners,
    refreshing,
    features,
    fetchBanners,
  };
};
