import {Image, ImageURISource, TouchableOpacity} from 'react-native';
import React from 'react';
import {style} from './Banner.styles';
import {BannerProps} from './Banner.types';

const Banner: React.FC<BannerProps> = ({
  image,
  containerStyle,
  local,
  onPress,
}) => {
  const imageSource = local
    ? (image as ImageURISource)
    : {uri: image as string};
  return (
    <TouchableOpacity
      style={[style.container, containerStyle]}
      onPress={onPress}
      activeOpacity={0.8}>
      <Image style={style.image} source={imageSource} />
    </TouchableOpacity>
  );
};

export default Banner;
