import React from 'react';
import {
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import {style} from './ForgotPassword.styles';
import {
  Button,
  CustomStatus,
  FeatureInfoButton,
  InputWithControl,
  Spinner,
} from '../../components';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavigationNames} from '../../utils/routeNames';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {Breakpoints} from '../../utils/theme';
import useDimensionsHook from '../../hooks/useDimensions';
import {Email, CenterLogo, WhatsApp} from '../../assets/svg';
import {getErrorProps} from '../../utils/validation/validationFunctions';
import {useLoginController} from './ForgotPassword.controller';
import {Control, FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const Login: React.FC = () => {
  const intl = useIntl();
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const navigate: NavigateFunction = useNavigate();
  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    isLoading,
    openEmail,
    openWhatsApp,
  } = useLoginController();

  const signUpPress = (): void => {
    navigate(NavigationNames.SignUp);
  };

  const {t} = useTranslation();

  const onSuccess = (): void => {
    // @ts-ignore
    navigate(NavigationNames.Login);
  };

  return (
    <View style={[style.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <CustomStatus />
      <Spinner visible={isLoading} />
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth: isWeb ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <TouchableOpacity
              style={style.backButton}
              onPress={() => navigate(-1)}>
              <Text style={style.backText}>
                <FormattedMessage id={t('misc.back')} />
              </Text>
            </TouchableOpacity>
            <View style={style.logoContainer}>
              <CenterLogo style={style.logoStyle} />
              <View style={style.logoTextContainer}>
                <Text style={style.logoText}>
                  श्री समस्त राजस्थान जैन समाज वडोदरा
                </Text>
              </View>
            </View>
            <Text style={style.title}>
              <FormattedMessage id={t('user.forgotPassword')} />
            </Text>
            <InputWithControl
              control={control as unknown as Control<FieldValues>}
              name="mobileNumber"
              autoFocus={isWeb}
              {...getErrorProps(intl, errors.mobileNumber)}
              label={t('user.enterMobileNumber')}
              containerStyle={style.inputContainer}
              keyboardType="phone-pad"
              returnKeyType="done"
              maxLength={10}
              onSubmitEditing={handleSubmit((data: any) =>
                onSubmit(data, onSuccess),
              )}
            />
            <Button
              name={<FormattedMessage id={t('user.submit')} />}
              style={style.loginButton}
              onPress={handleSubmit((data: any) => onSubmit(data, onSuccess))}
            />
            <View style={style.haveAccountContainer}>
              <Text style={style.didNotText}>
                <FormattedMessage id={t('login.notHaveAccount')} />
              </Text>
              <TouchableOpacity activeOpacity={0.8} onPress={signUpPress}>
                <Text style={style.signUp}>
                  <FormattedMessage id={t('login.signUp')} />
                </Text>
              </TouchableOpacity>
            </View>

            <View style={style.contactRowView}>
              <FeatureInfoButton
                name="+917600136222"
                icon={<WhatsApp width={15} height={15} />}
                onPress={openWhatsApp}
                rightComponent={null}
                containerStyle={[
                  style.featureInfoContainer,
                  {justifyContent: 'flex-start'},
                ]}
                logoContainerStyle={style.featureInfoLogo}
                nameStyle={style.featureInfoName}
              />
              <FeatureInfoButton
                name="ssrjsvadodara@gmail.com"
                icon={<Email width={15} height={15} />}
                onPress={openEmail}
                rightComponent={null}
                containerStyle={[
                  style.featureInfoContainer,
                  {justifyContent: 'flex-end'},
                ]}
                logoContainerStyle={style.featureInfoLogo}
                nameStyle={style.featureInfoName}
              />
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default Login;
