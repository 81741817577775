import {View, Text, Image, TouchableOpacity} from 'react-native';
import React from 'react';
import {EventInfoProps} from './EventInfo.types';
import {ApiRoutes} from '../../services/api/path';
import {styles} from './EventInfo.styles';
import {CalenderOutLine, Clock} from '../../assets/svg';
import moment from 'moment';
import Button from '../Button/Button';
import {NavigationNames} from '../../utils/routeNames';
import {useNavigate} from 'react-router-dom';
import {noImage} from '../../assets/png';
import Swiper from 'react-native-web-swiper';
import {FormattedMessage} from 'react-intl';

const EventInfo: React.FC<EventInfoProps> = ({
  images,
  title,
  description,
  scheduleDt,
  ...restProps
}) => {
  const navigaion = useNavigate();

  const onEventPress = (): void => {
    navigaion(NavigationNames.EventDetails, {
      state: {event: {images, title, description, scheduleDt, ...restProps}},
    });
  };

  return (
    <TouchableOpacity
      onPress={onEventPress}
      activeOpacity={0.8}
      style={styles.container}>
      <View style={{height: 150}}>
        <Swiper
          key={images?.length}
          loop
          timeout={5}
          springConfig={{speed: 11}}
          controlsProps={{
            dotsTouchable: true,
            prevPos: false,
            nextPos: false,
            dotActiveStyle: styles.dotActiveStyle,
            dotProps: {
              badgeStyle: styles.dotInActiveStyle,
            },
          }}
          controlsEnabled={images.length > 1}
          minDistanceForAction={0}>
          {images?.map(image => (
            <Image
              key={image + Math.random()}
              source={{uri: `${ApiRoutes.BaseURL}${image?.substring(1)}`}}
              style={[styles.image, {resizeMode: image ? 'cover' : 'contain'}]}
              defaultSource={noImage}
            />
          ))}
        </Swiper>
      </View>
      <View style={styles.contentView}>
        <View style={styles.rowView}>
          <View style={{flex: 1, gap: 5}}>
            <Text style={styles.title}>{title}</Text>
            <Text
              style={styles.description}
              numberOfLines={1}
              ellipsizeMode="tail">
              {description}
            </Text>
          </View>
          <Button
            style={styles.buttonContainer}
            textStyle={styles.buttonText}
            name={<FormattedMessage id="event.registerNow" />}
          />
        </View>
        <View style={styles.rowView}>
          <CalenderOutLine width={20} height={20} />
          <Text style={styles.dateAndTime}>
            {moment(scheduleDt).format('DD MMM YYYY')}
          </Text>
        </View>
        <View style={styles.rowView}>
          <Clock width={20} height={20} style={{marginRight: 2}} />
          <Text style={styles.dateAndTime}>
            {moment(scheduleDt).format('LT')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default EventInfo;
