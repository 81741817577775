import React, {memo} from 'react';
import {View, StatusBar} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {CustomStatusProps} from './CustomStatus.types';

const CustomStatus: React.FC<CustomStatusProps> = ({
  backgroundColor = 'white',
  containerStyle,
  ...restProps
}) => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={[
        {height: insets.top, backgroundColor: backgroundColor},
        containerStyle,
      ]}>
      <StatusBar
        animated={true}
        backgroundColor={backgroundColor}
        barStyle="dark-content"
        {...restProps}
      />
    </View>
  );
};

export default memo(CustomStatus);
