import {FamilyMembers, User} from '../../api/response/user';

export interface UserState {
  user?: User;
  familyMembers: FamilyMembers[];
}

export enum UserActionType {
  UserInfo = 'userInfo',
  Logout = 'logout',
  UpdateUserInfo = 'updateUserInfo',
  FamilyMembers = 'familyMembers',
}
