import React, {useState, forwardRef} from 'react';
import {InputWithControlProps} from './Input.types';
import Input from './Input';
import {Controller, FieldValues} from 'react-hook-form';
import {TextInput, TouchableOpacity, Text, View} from 'react-native';
import {style} from './Input.styles';
import {useTranslation} from 'react-i18next';

const InputWithControl = forwardRef(
  <T extends FieldValues>(
    {
      name,
      control,
      rules,
      placeholder,
      containerStyle,
      secureTextEntry = false,
      show = false,
      ...inputProps
    }: InputWithControlProps<T> & {show?: boolean},
    ref?: React.Ref<TextInput>,
  ) => {
    const [isSecure, setIsSecure] = useState(secureTextEntry);
    const {t} = useTranslation();
    const toggleSecureEntry = () => {
      setIsSecure(prev => !prev);
    };

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({field, fieldState}) => (
          <View style={containerStyle}>
            <Input
              {...inputProps}
              {...field}
              ref={ref}
              placeholder={placeholder}
              onChangeText={field.onChange}
              value={field.value ?? ''}
              secureTextEntry={isSecure}
              // style={styles.input}
            />
            {show && !fieldState.error && (
              <TouchableOpacity
                style={style.showButton}
                onPress={toggleSecureEntry}>
                <Text style={style.showText}>
                  {isSecure ? t('misc.showPassword') : t('misc.hidePassword')}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        )}
      />
    );
  },
);

export default InputWithControl;

// import React, {useState, forwardRef} from 'react';
// import {Controller, FieldValues, Control, Path} from 'react-hook-form';
// import {
//   TextInput,
//   TouchableOpacity,
//   Text,
//   View,
//   StyleProp,
//   ViewStyle,
// } from 'react-native';
// import {useTranslation} from 'react-i18next';
// import Input from './Input'; // Assuming this is a custom TextInput component
// import {style} from './Input.styles';

// interface InputWithControlProps<T extends FieldValues> {
//   name: Path<T>;
//   control: Control<T>;
//   rules?: object;
//   containerStyle?: StyleProp<ViewStyle>;
//   secureTextEntry?: boolean;
//   show?: boolean;
//   editable?: boolean;
//   placeholder?: string;
//   value?: string;
//   onPress?: () => void;
//   [x: string]: any;
// }

// const InputWithControl = forwardRef(
//   <T extends FieldValues>(
//     {
//       name,
//       control,
//       rules,
//       containerStyle,
//       secureTextEntry = false,
//       show = false,
//       editable = true,
//       onPress,
//       ...props
//     }: InputWithControlProps<T>,
//     ref: any,
//   ) => {
//     const {t} = useTranslation();
//     const [hidePassword, setHidePassword] = useState(true);

//     if (!control) {
//       console.error('Control is not provided for InputWithControl component');
//       return null;
//     }

//     return (
//       <View style={containerStyle}>
//         <Controller
//           control={control}
//           name={name}
//           rules={rules}
//           render={({field: {onChange, value, onBlur}}) => (
//             <Input
//               {...props}
//               onChangeText={onChange}
//               value={value}
//               onBlur={onBlur}
//               editable={editable}
//               secureTextEntry={secureTextEntry && hidePassword}
//               ref={ref}
//               style={style.input}
//             />
//           )}
//         />
//         {secureTextEntry && (
//           <TouchableOpacity
//             style={style.showButton}
//             onPress={() => setHidePassword(!hidePassword)}>
//             <Text style={style.showButtonText}>
//               {hidePassword ? t('misc.show') : t('misc.hide')}
//             </Text>
//           </TouchableOpacity>
//         )}
//       </View>
//     );
//   },
// );

// export default InputWithControl;
