import React from 'react';
import {Text} from 'react-native';
import {style} from './ErrorMessage.styles';
import {memo} from 'react';
import {ErrorMessageProps} from './ErrorMessage.types';

const ErrorMessage: React.FC<ErrorMessageProps> = props => {
  if (!props.error) {
    return null;
  }
  return (
    <Text style={[style.errorMessage, props.textStyle]}>
      {props.helperText}
    </Text>
  );
};

export default memo(ErrorMessage);
