import {StyleSheet} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 30,
    paddingHorizontal: 20,
    gap: 15,
    paddingVertical: 15,
  },
  columnWrapperStyle: {
    paddingVertical: 10,
    gap: 15,
  },
});
