import {View, Text} from 'react-native';
import React, {useLayoutEffect, useState} from 'react';
import NetInfo, {NetInfoState} from '@react-native-community/netinfo';
import {style} from './InternetInfo.styles';
import {FormattedMessage} from 'react-intl';

const InternetInfo: React.FC = () => {
  const [isConnected, setIsConnected] = useState<boolean>(true);

  useLayoutEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state: NetInfoState) => {
      if (
        state.isConnected !== undefined &&
        state.isInternetReachable !== null
      ) {
        setIsConnected(!!state.isConnected && !!state.isInternetReachable);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isConnected) {
    return null;
  }

  return (
    <View style={[style.container, {display: isConnected ? 'none' : 'flex'}]}>
      <Text style={style.text}>
        <FormattedMessage id="misc.noInternet" />
      </Text>
    </View>
  );
};

export default InternetInfo;
