export const bloodGroups = [
  {id: '1', name: 'A+'},
  {id: '2', name: 'A-'},
  {id: '3', name: 'B+'},
  {id: '4', name: 'B-'},
  {id: '5', name: 'O+'},
  {id: '6', name: 'O-'},
  {id: '7', name: 'AB+'},
  {id: '8', name: 'AB-'},
];
