// import * as yup from 'yup';
import {StackNavigationProp} from '@react-navigation/stack';
import {PublicNavigatorParamList} from '../../setup/routes/routes.types';
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
// import {NavigationNames} from '../../utils/routeNames';
// import {RouteProp} from '@react-navigation/native';

export type ChangePasswordScreenNavigationProp =
  StackNavigationProp<PublicNavigatorParamList>;

export interface changePasswordFormFields {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  isLoading: boolean;
}

export interface changePasswordController {
  control: Control<changePasswordFormFields, any>;
  onSubmit: (
    data: changePasswordFormFields,
    onSuccess: () => void,
    onClose: () => void,
  ) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<changePasswordFormFields, undefined>;
  errors: FieldErrors<changePasswordFormFields>;
  isLoading: boolean;
  setValue: UseFormSetValue<changePasswordFormFields>;
  reset: () => void;
  watch: any;
}

export interface changePasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

export enum Password {
  minLength = 'Password length should be at least 6 characters.',
  oldPassword = 'Old password is required.',
  required = 'Password length should be at least 6 characters.',
  mismatch = 'Passwords must match with new password.',
  confirmPasswordrequired = 'Confirm password is required.',
  newpasswordrequired = 'New password is required.',
  newPassword = 'newPassword',
}
