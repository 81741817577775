import {useForm} from 'react-hook-form';
import {ContactUsController, ContactUsFields} from './ContactUs.types';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../services/redux/hook';
import {fetchAllCategorie} from '../../services/redux/app/app.action';
import {ApiRoutes} from '../../services/api/path';
import axios from '../../services/api/api';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {yupResolver} from '@hookform/resolvers/yup';
import {contactUsFormSchema} from '../../validationSchemas/contactUs';
import {Linking} from 'react-native';
import {useTranslation} from 'react-i18next';

export const useContactUsController = (): ContactUsController => {
  const {t} = useTranslation();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: {errors},
  } = useForm<ContactUsFields>({
    resolver: yupResolver(contactUsFormSchema),
    defaultValues: {
      isLoading: false,
    },
  });
  const isLoading = watch('isLoading');
  const dispatch = useAppDispatch();
  const {openModal} = useModal();
  const {categories} = useAppSelector(state => state.appReducer);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchAllCategorie());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: ContactUsFields) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post(ApiRoutes.InquirySubmit, {
        MessageCategoryId: data.category?.id,
        Message: data.message,
        Name: data.name,
        ContactNo: data.phoneNumber,
        Email: data.email,
        RequestById: 5,
      });
      setValue('isLoading', false);
      openModal({title: 'Success !', message: response?.message});
      +reset({
        email: '',
        category: undefined,
        isLoading: false,
        message: '',
        name: '',
        phoneNumber: '',
      });
    } catch (error) {
      setValue('isLoading', false);
      console.error(error);
    }
  };

  const openLinks = async (url: string) => {
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  return {
    control,
    categories,
    handleSubmit,
    onSubmit,
    isLoading,
    errors,
    openLinks,
  };
};
