import * as React from 'react';
import Svg, {SvgProps, Path, G} from 'react-native-svg';
const FamilyMembers = (props: SvgProps) => (
  <Svg viewBox="0 0 500 500" {...props}>
    <Path
      d="M185.77 91.83c-21.57 2.84-42.36 8.9-60.81 18.41-21.73 11.2-43.96 28.66-37.38 51.49 12.11 42.15 70.21 42.92 58.61 71.26-13.14 32.1-4.33 69.75 54.63 80.23 112 19.88 247.35-96.49 209.33-160.44C386.48 113 300.27 76.71 185.77 91.83Z"
      fill="#e21f23"
    />
    <Path
      d="M185.77 91.83c-21.57 2.84-42.36 8.9-60.81 18.41-21.73 11.2-43.96 28.66-37.38 51.49 12.11 42.15 70.21 42.92 58.61 71.26-13.14 32.1-4.33 69.75 54.63 80.23 112 19.88 247.35-96.49 209.33-160.44C386.48 113 300.27 76.71 185.77 91.83Z"
      fill="#fff"
      opacity={0.9}
    />
    <Path
      d="M458.88 454.73c0 3.38-93.52 6.13-208.88 6.13s-208.88-2.75-208.88-6.13 93.52-6.14 208.88-6.14 208.88 2.75 208.88 6.14Z"
      fill="#f5f5f5"
    />
    <Path
      d="m233.13 173.32-7.34 19.53-44.28 19.53 2 8.4s48.4-6.66 52-10.55 15.52-31 15.52-31Z"
      fill="#e9b376"
    />
    <Path
      d="m231.33 178.11-1.16 3.07 13.98 5.61 1.69 4.06 1.17-2.53-.54-6.67-15.14-3.54z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M191.52 210.25s-1.69-3.65-4.83-4.76-5.34-2.82-7.73-3.48-.53 2.12.76 3.4 2.73 2.74 1.61 3.56S168.4 211 167 212.34s.55 1.69.55 1.69-2.86.39-3 1.56c0 .31.36 1.08 1.69 1.11 3 .07 9.28-.12 9.28-.12-.36 0-8.11.79-9.25 1.06-1.5.35-2 1.28-1.79 2.14.33 1.23 10 .58 10.39.84 0 0-5.56.18-6.3 1.64s2.77 1.54 5.88 1c2.84-.48 8.55-.67 11.4-1.44 3.51-1 5.65-2.37 6.2-5.73a11.6 11.6 0 0 0-.53-5.84Z"
      fill="#e9b376"
    />
    <Path
      d="M167.9 214a.13.13 0 0 1 0-.25c2.3-.25 4.64-.4 6.94-.44a.12.12 0 0 1 .13.12.13.13 0 0 1-.13.13c-2.29 0-4.62.19-6.91.44ZM177 220.74a.12.12 0 0 1-.11-.07 15.6 15.6 0 0 1-.72-8.89.12.12 0 1 1 .24.06 15.31 15.31 0 0 0 .71 8.73.13.13 0 0 1-.07.17ZM187.46 214.13a5.4 5.4 0 0 1-5.25-4 .13.13 0 0 1 .09-.15.13.13 0 0 1 .16.08 5.14 5.14 0 0 0 5.34 3.83.14.14 0 0 1 .14.12.12.12 0 0 1-.12.13Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M201.16 156.84s8.42 0 11.05 10.18 2.1 48.78 3.44 54.49 12.56 48.23 15.11 68.78c0 0-11.9 1-13 .81s-18.87-83.87-18.87-83.87Z"
      fill="#e21f23"
    />
    <Path
      d="M201.16 156.84s8.42 0 11.05 10.18 2.1 48.78 3.44 54.49 12.56 48.23 15.11 68.78c0 0-11.9 1-13 .81s-18.87-83.87-18.87-83.87Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M217.9 175.34c5.93 12 15.37 24.69 15.37 24.69 3-.46 23.1-8.67 23.1-8.67l2.21 8.58a98.72 98.72 0 0 1-13.33 11c-12.12 8-16.59 7.45-22.77 1s-18.78-30.22-18.78-30.22l4.4-21.56-1.1-1.25c6.67 2.9 8 10.66 10.9 16.43Z"
      fill="#e21f23"
    />
    <Path
      d="M217.9 175.34c5.93 12 15.37 24.69 15.37 24.69 3-.46 23.1-8.67 23.1-8.67l2.21 8.58a98.72 98.72 0 0 1-13.33 11c-12.12 8-16.59 7.45-22.77 1s-18.78-30.22-18.78-30.22l4.4-21.56-1.1-1.25c6.67 2.9 8 10.66 10.9 16.43Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M214.56 200.68s1-10.38 0-16.15-2.09-11.21-2.09-11.21 3.82 8.73 4.25 15.1.16 16 .16 16ZM245.05 195.88c-.08 0 1.65 14.07 1.65 14.07l2.85-2.11-1.83-13Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="m147.88 443.91 10.78 1.05 5.89-27.53-16.67-1.56v28.04z"
      fill="#e9b376"
    />
    <Path
      d="M160.23 440.57h-3.85a2.76 2.76 0 0 0-2.2 1.15c-2.22 3-3.88 1.88-6.3-1-1.54-.18-1.95 1.59-2.07 2.06l-2.88 10.47a1.64 1.64 0 0 0 1.13 2 1.55 1.55 0 0 0 .48.07H175c4.85 0 5.86-4.89 3.86-5.34-9-2-15.35-4.63-18.13-7.25-.47-.4.05-.73-.5-2.16Z"
      fill="#e21f23"
    />
    <Path
      d="M160.23 440.57h-3.85a2.76 2.76 0 0 0-2.2 1.15c-2.22 3-3.88 1.88-6.3-1-1.54-.18-1.95 1.59-2.07 2.06l-2.88 10.47a1.64 1.64 0 0 0 1.13 2 1.55 1.55 0 0 0 .48.07H175c4.85 0 5.86-4.89 3.86-5.34-9-2-15.35-4.63-18.13-7.25-.47-.4.05-.73-.5-2.16Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M168.58 443.55a1.57 1.57 0 0 0 .65-1.26.67.67 0 0 0-.31-.67c-1.21-.86-6 1.39-6.56 1.65a.24.24 0 0 0-.13.13.22.22 0 0 0 .13.28 17.49 17.49 0 0 0 3.61.46 4.28 4.28 0 0 0 2.61-.59Zm0-1.57a.26.26 0 0 1 .12.29 1.28 1.28 0 0 1-.47.92c-1.1.81-3.74.46-5.16.2 2.04-.88 4.93-1.87 5.55-1.39Z"
      fill="#263238"
    />
    <Path
      d="M162.52 443.73c1.45-.61 4.48-3 4.31-4.24 0-.26-.21-.58-.86-.66-2.64-.31-3.78 4.43-3.78 4.63a.23.23 0 0 0 .07.23h.15Zm3.36-4.51c.49 0 .49.22.49.28.14.77-2.06 2.74-3.63 3.57.32-1.1 1.29-3.87 3-3.87Z"
      fill="#263238"
    />
    <Path
      d="M158.81 445.9a.22.22 0 0 1-.13 0 .25.25 0 0 1-.08-.34 4.92 4.92 0 0 1 3.45-2.22.25.25 0 0 1 .28.21.24.24 0 0 1-.21.28 4.39 4.39 0 0 0-3.09 2 .25.25 0 0 1-.22.07ZM161.1 447.5a.2.2 0 0 1-.13 0 .24.24 0 0 1-.08-.34 4.88 4.88 0 0 1 3.45-2.22.25.25 0 0 1 .28.21.26.26 0 0 1-.22.29 4.39 4.39 0 0 0-3.09 2 .26.26 0 0 1-.21.06ZM164.37 449.11a.28.28 0 0 1-.13 0 .25.25 0 0 1-.08-.35 4.88 4.88 0 0 1 3.45-2.22.25.25 0 0 1 .28.21.24.24 0 0 1-.22.28 4.44 4.44 0 0 0-3.09 2 .26.26 0 0 1-.21.08Z"
      fill="#263238"
    />
    <Path
      d="m200.14 443.07 10.78 1.06 5-27.63-15.32-1.67-.46 28.24z"
      fill="#e9b376"
    />
    <Path
      d="M212.49 439.74h-3.85a2.75 2.75 0 0 0-2.2 1.14c-2.22 3-3.88 1.89-6.3-1-1.53-.18-1.95 1.58-2.07 2.05l-2.88 10.47a1.64 1.64 0 0 0 1.13 2 1.54 1.54 0 0 0 .48.06h30.5c4.85 0 5.86-4.89 3.86-5.34-9-2-15.35-4.63-18.13-7.24-.51-.38.02-.72-.54-2.14Z"
      fill="#e21f23"
    />
    <Path
      d="M220.84 442.72a1.58 1.58 0 0 0 .65-1.26.69.69 0 0 0-.31-.68c-1.21-.85-6 1.4-6.56 1.66a.21.21 0 0 0 0 .4 17.5 17.5 0 0 0 3.61.47 4.35 4.35 0 0 0 2.61-.59Zm0-1.57a.25.25 0 0 1 .12.28 1.26 1.26 0 0 1-.47.92c-1.1.82-3.74.47-5.16.2 2.04-.88 4.88-1.87 5.55-1.4Z"
      fill="#263238"
    />
    <Path
      d="M214.78 442.89c1.45-.6 4.48-3 4.31-4.23 0-.27-.21-.58-.86-.67-2.64-.3-3.77 4.44-3.77 4.64a.19.19 0 0 0 .06.22.19.19 0 0 0 .15 0Zm3.36-4.51c.49.05.49.23.49.29.14.77-2.06 2.74-3.63 3.57.32-1.11 1.29-3.87 3-3.87Z"
      fill="#263238"
    />
    <Path
      d="M211.89 445.07a.28.28 0 0 1-.13 0 .25.25 0 0 1-.08-.35 4.88 4.88 0 0 1 3.44-2.22.27.27 0 0 1 .29.22.26.26 0 0 1-.22.28 4.41 4.41 0 0 0-3.09 2 .25.25 0 0 1-.21.07ZM214.28 446.87a.33.33 0 0 1-.13 0 .26.26 0 0 1-.08-.35 4.94 4.94 0 0 1 3.45-2.22.26.26 0 0 1 .28.22.25.25 0 0 1-.22.28 4.39 4.39 0 0 0-3.09 2 .24.24 0 0 1-.21.07ZM217.26 448.38a.25.25 0 0 1-.14 0 .24.24 0 0 1-.07-.34 4.88 4.88 0 0 1 3.44-2.22.25.25 0 0 1 .29.21.26.26 0 0 1-.22.28 4.44 4.44 0 0 0-3.09 2 .25.25 0 0 1-.21.07ZM217.48 239.08c.78 2.31 9.49 76.06 9.39 92-.08 12.88-9.61 102-9.61 102h-21s2.74-45.79 2.19-60.68 3-42.86 3-42.86L179 262.38l.84-22.53Z"
      fill="#263238"
    />
    <Path
      d="M150.41 338.17c.5-2.15 1.28-6.51 2.25-12.27h-.09c.71-7.62-1.73-47.06.67-69.58 1.86-14.35 5.28-22 9-26.73l.52-.67c.62-.76 1-1.18 1-1.18.7.17 1.4.34 2.09.49l.78.16c26.07 5.46 46.91-1.68 46.91-1.68 2.4 5.92 4.94 19.95 6.69 33.06-2.73 3-13.34 5.77-27.07 8.34l-3.91 17.71c-2.06 11-4.71 21.74-7 31.44l-2.34 10.62c-.15.69-.29 1.38-.42 2.06C178.3 336 165 433.51 165 433.51l-19.16-.44s-2.11-65.8 4.57-94.9Z"
      fill="#263238"
    />
    <Path
      d="M212.49 439.74h-3.85a2.75 2.75 0 0 0-2.2 1.14c-2.22 3-3.88 1.89-6.3-1-1.53-.18-1.95 1.58-2.07 2.05l-2.88 10.47a1.64 1.64 0 0 0 1.13 2 1.54 1.54 0 0 0 .48.06h30.5c4.85 0 5.86-4.89 3.86-5.34-9-2-15.35-4.63-18.13-7.24-.51-.38.02-.72-.54-2.14Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="m155.84 187.77-2.82-25c4.35-6.49 21.82-9.94 21.82-9.94l14.18.78s7.5 1.77 12.17 3.29a49.46 49.46 0 0 1 5.45 9.84s6.86 14.67 5.79 25c-.45 4.34-1.43 20.88-.22 28.33l6.43 19.74c-25.91 20.18-57.86-.91-57.86-.91 1.16-3.57 3.72-11.46 4.25-14.94-.38-4.45-1.63-7.36-3.51-15.15-.36-1.53-5.05-19.58-5.68-21.04Z"
      fill="#e21f23"
    />
    <Path
      d="m186.61 209.71 5.22-.46 2.08 7.37-5.24 1.8-2.06-8.71z"
      fill="#e9b376"
    />
    <Path
      d="M190.29 208.86s.78-3.62 3.36-5.25 4.22-3.62 6.22-4.7 2.33 1.17 1.44 2.58-3.31 3.64-2.14 4.14 12-.78 13.56.1-.15 1.63-.15 1.63 2.44-.27 3 .78-.89 1.68-.89 1.68 1.64-.13 1.6 1-9.73 3.21-10.06 3.52c0 0 5.63-2 6.59-.83s-2.11 2.43-4.54 3.5a18.21 18.21 0 0 1-8.37 1.16c-3.37-.3-8.06.1-9.24-2.81a12.76 12.76 0 0 1-.38-6.5Z"
      fill="#e9b376"
    />
    <Path
      d="M170.25 188.42c-1.14-13.22-3.74-34-9.23-31.51-3.51 1.57-5.72 2.64-7.53 4.19-1.19 1-1.39 3.43-2.19 6-2.22 7.18 1.72 58.26 1.72 58.26l-12.6 71.08 25.58 3.35s5.4-98.14 4.25-111.37Z"
      fill="#e21f23"
    />
    <Path
      d="M170.25 188.42c-1.14-13.22-3.74-34-9.23-31.51-3.51 1.57-5.72 2.64-7.53 4.19-1.19 1-1.39 3.43-2.19 6-2.22 7.18 1.92 57.29 1.92 57.29l-12.82 72.16 25.6 3.24s5.4-98.14 4.25-111.37Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M158.31 215.51s4.26-24.81 4-36.32l-8.07 31ZM151.5 224.9a12 12 0 0 0 4.26 3.35c3.48 1.45 13.79-1.7 13.79-1.7l.14-4.4Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M162.29 179.19c-.64 0-5.13 28.86-5.13 28.86l31.76-.71 1.78 12.79s-13.9 3.38-29.28 5.89c-15.86 2.58-21.8-4.44-21.87-15.2-.07-9.87 4-33.84 4-33.84 2.06-9.44 6.11-12.92 11.09-16.79Z"
      fill="#e21f23"
    />
    <Path
      d="M162.29 179.19c-.64 0-5.13 28.86-5.13 28.86l31.76-.71 1.78 12.79s-13.9 3.38-29.28 5.89c-15.86 2.58-21.8-4.44-21.87-15.2-.07-9.87 4-33.84 4-33.84 2.06-9.44 6.11-12.92 11.09-16.79Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M170.93 104.35s3.78-5.64 13.82-6.38 21.29 2.17 26.73 15.94l-4.39 1.1a102.87 102.87 0 0 1 3.8 31.89s-30.78 4.12-50.69.69a168.24 168.24 0 0 1 2.08-27.92c2.47-16.23 8.65-15.32 8.65-15.32Z"
      fill="#263238"
    />
    <Path
      d="M171.68 154.23s4.52 3.2 10.37 7.85a21.31 21.31 0 0 0 3.95 2.48c3.79 1.82 7.21 2.81 8.05 1a6 6 0 0 0 .43-1.28c.66-3 .17-7.22-2.32-9.05-2.81-2.06-4.2-3.87-3.82-6.83a21.76 21.76 0 0 1 1.36-5.11l-3.22-1.77-11.67-10.89c1.56 6.89 2.62 19.26-3.13 23.6Z"
      fill="#e9b376"
    />
    <Path
      d="M188.27 149c-3.16-1.71-6.67-4.75-6.41-8.4a16.83 16.83 0 0 1 .73-3.94l6.59 8.05a21.35 21.35 0 0 0-.91 4.29Z"
      opacity={0.2}
    />
    <Path
      d="M172.13 117.94c1.51 11.49 1.91 16.37 8.1 22.18 9.31 8.75 23 5.15 24.88-6.38 1.73-10.37-1-27.42-12.54-31.82-11.34-4.34-21.95 4.53-20.44 16.02Z"
      fill="#e9b376"
    />
    <Path
      d="M187.82 121.11c.11 1.21.81 2.19 1.57 2.18s1.29-1 1.18-2.23-.8-2.2-1.56-2.18-1.29 1.01-1.19 2.23ZM199.54 120.59c.1 1.18.8 2.12 1.56 2.11s1.28-1 1.18-2.17-.8-2.12-1.56-2.11-1.29.99-1.18 2.17ZM184.65 117a.52.52 0 0 0 .4-.15 4.2 4.2 0 0 1 3.76-1.21.52.52 0 0 0 .65-.41.62.62 0 0 0-.45-.69 5.17 5.17 0 0 0-4.77 1.47.59.59 0 0 0 0 .81.61.61 0 0 0 .41.18ZM202.19 114.69a.45.45 0 0 0 .25-.27.62.62 0 0 0-.31-.73 4.8 4.8 0 0 0-4.69-.12.57.57 0 0 0 .63.94 3.86 3.86 0 0 1 3.71.14.54.54 0 0 0 .41.04Z"
      fill="#263238"
    />
    <Path
      d="M195.47 120.18a39.34 39.34 0 0 0 4.12 6.86.9.9 0 0 1-.37 1.38 5.67 5.67 0 0 1-3.08.17Z"
      fill="#d58745"
    />
    <Path
      d="m188.58 118.94 2.62-.73s-1.37 2.03-2.62.73ZM200.32 118.48l2.62-.73s-1.36 2.03-2.62.73Z"
      fill="#263238"
    />
    <Path
      d="M179.1 124.34s2.76-8.33 1.18-15c0 0 21.26-9.67 24.94 11.59 0 0-2.85-23.67-21.18-21.84s-19.64 29.73-9.22 36.53c0 .06 1.88-5.41 4.28-11.28Z"
      fill="#263238"
    />
    <Path
      d="M169 127.47a9.32 9.32 0 0 0 5.78 4.14c3.08.65 4.47-4.32 3.3-7.34a6.12 6.12 0 0 0-7.06-3.7c-2.89.7-3.67 4.26-2.02 6.9Z"
      fill="#e9b376"
    />
    <Path
      d="M175.44 126.85a.25.25 0 0 1-.25-.22 3.95 3.95 0 0 0-4.32-3.5.27.27 0 0 1-.28-.23.25.25 0 0 1 .23-.27 4.45 4.45 0 0 1 4.87 4 .25.25 0 0 1-.22.27Z"
      opacity={0.2}
    />
    <Path
      d="M192.42 132.47a4.17 4.17 0 0 1-3.7-2.46.25.25 0 0 1 .13-.33.26.26 0 0 1 .33.14 3.6 3.6 0 0 0 4.34 2 .24.24 0 0 1 .3.18.25.25 0 0 1-.18.3 5 5 0 0 1-1.22.17Z"
      fill="#263238"
    />
    <Path
      d="M251.57 194.4s-.47-4 1.63-6.58 3.08-5.2 4.77-7 1.56 1.53 1.13 3.29-.89 3.77.48 3.88 12.11-5 14-4.63.42 1.74.42 1.74 2.64-1.17 3.38-.25c.2.24.26 1.11-.86 1.83-2.52 1.62-8 4.75-8 4.75.31-.19 7.33-3.57 8.45-3.94 1.46-.48 2.39 0 2.64.89.36 1.22-8.19 5.71-8.42 6.15 0 0 4.84-2.75 6.23-1.89s-1.56 2.76-4.48 3.94c-2.68 1.07-7.65 3.89-10.47 4.73-3.49 1-6.06.93-8.29-1.65a11.57 11.57 0 0 1-2.61-5.26Z"
      fill="#e9b376"
    />
    <Path
      d="M267.49 188.54a.14.14 0 0 1-.11-.06.13.13 0 0 1 .05-.17c2-1.17 4.05-2.27 6.14-3.25a.12.12 0 0 1 .16.06.11.11 0 0 1-.06.16c-2.07 1-4.13 2.08-6.12 3.25ZM269.36 195.78a.13.13 0 0 1-.12-.11 15.39 15.39 0 0 0-4-7.82.12.12 0 0 1 0-.17.13.13 0 0 1 .18 0 15.62 15.62 0 0 1 4 7.95.12.12 0 0 1-.1.14ZM256.68 195.77a.12.12 0 0 1-.06-.23A5.31 5.31 0 0 0 259 193a5.23 5.23 0 0 0 .18-3.49.13.13 0 0 1 .09-.16.12.12 0 0 1 .15.08 5.4 5.4 0 0 1-2.68 6.36Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M282.6 83c2.51-1.36-6.21 6.44-5.26 14.13s2.77 26.07 2.77 26.07l17.6-4.68Z"
      fill="#263238"
    />
    <Path
      d="m385.9 185.23-26.46 17.49a20.91 20.91 0 0 1-2.2-2.59c-3.55-4.59-8.67-12.85-10.32-15.56l-13.34 20c3.9 4.07 19.8 14.86 22.48 15.3 6.95 1.15 34.76-28.95 34.76-28.95Z"
      fill="#e9b376"
    />
    <Path
      d="M287.47 123.16c-6 2.58-15.49 19.33-15.49 19.33l2.46 3.94 4.44 2.08s7.2-11.7 11.95-13.8-3.36-11.55-3.36-11.55Z"
      fill="#e21f23"
    />
    <Path
      d="M287.47 123.16c-6 2.58-15.49 19.33-15.49 19.33l2.46 3.94 4.44 2.08s7.2-11.7 11.95-13.8-3.36-11.55-3.36-11.55Z"
      fill="#fff"
      opacity={0.30000000000000004}
    />
    <Path
      d="M287.47 123.16a45.45 45.45 0 0 1 31.36-4.45c3.15 1.54 5.51 4.12 9.05 23 1.17 6.28 7.52 6.39 10.23 12.41a4.93 4.93 0 0 1-1.06 6.89 4.74 4.74 0 0 1-1.1.61c-9.43 3.76-27.15 9.88-37.29 7.15.01.01-10.54-25.16-11.19-45.61Z"
      fill="#e21f23"
    />
    <Path
      d="M287.47 123.16a45.45 45.45 0 0 1 31.36-4.45c3.15 1.54 5.51 4.12 9.05 23 1.17 6.28 7.52 6.39 10.23 12.41a4.93 4.93 0 0 1-1.06 6.89 4.74 4.74 0 0 1-1.1.61c-9.43 3.76-27.15 9.88-37.29 7.15.01.01-10.54-25.16-11.19-45.61Z"
      fill="#fff"
      opacity={0.30000000000000004}
    />
    <Path
      d="M336.83 216c9.9 25.86 17.3 42.7 17.3 42.7l-6.37 3.3s-14.62-15.57-27.9-42.71a211 211 0 0 1-8.57-20l16.59-14a246.25 246.25 0 0 0 8.95 30.71Z"
      fill="#e9b376"
    />
    <Path
      d="m311.29 199.33 11.93-6.33c1.62 8.05 8.91 6.58 11.76 17.78-3.75 1.12-13.56 3.83-17.57 3.36a116.47 116.47 0 0 1-6.12-14.81Z"
      opacity={0.2}
    />
    <Path
      d="m344.09 257.94 8.41-2.74a.55.55 0 0 1 .71.31l2.58 6.53a1.39 1.39 0 0 1-.88 1.74c-2.95.91-4.41 1.16-8.11 2.37a74.41 74.41 0 0 1-10 2.91c-3.27.52-3.68-2.73-2.34-3.26 3.16-1.21 6.87-4.71 8.44-7.06a2.16 2.16 0 0 1 1.19-.8Z"
      fill="#263238"
    />
    <Path
      d="M340.27 260.18a1 1 0 0 1-.53-.91.7.7 0 0 1 .24-.62c.8-.66 3.4.08 3.7.18a.26.26 0 0 1 .13.16.16.16 0 0 1-.1.17 9.64 9.64 0 0 1-1.91.93 2.13 2.13 0 0 1-1.53.09Zm0-1.22a.32.32 0 0 0-.12.31.68.68 0 0 0 .32.58c.47.23 1.47-.05 2.72-.71-1-.28-2.5-.5-2.94-.14Z"
      fill="#e21f23"
    />
    <Path
      d="M343.58 259.22c-.89-.2-2.52-1.41-2.61-2.28a.68.68 0 0 1 .46-.73 1 1 0 0 1 .83 0c1 .49 1.52 2.73 1.55 2.79a.27.27 0 0 1-.06.17.08.08 0 0 1-.08 0 .14.14 0 0 1-.09.05Zm-2-2.68a.29.29 0 0 0-.24.36 3.79 3.79 0 0 0 2 1.89c-.19-.71-.67-1.92-1.27-2.2a.48.48 0 0 0-.42 0Z"
      fill="#e21f23"
    />
    <Path
      d="m388.58 193.23-5.72-6.73a.57.57 0 0 1 0-.79l5.06-4.87a1.4 1.4 0 0 1 1.95.15c2 2.38 2.81 3.62 5.27 6.57a72.88 72.88 0 0 1 6.49 8.11c1.71 2.84-1.13 4.44-2.11 3.4-2.32-2.47-7-4.56-9.75-5.12a2.27 2.27 0 0 1-1.19-.72Z"
      fill="#263238"
    />
    <Path
      d="M392.08 195.88a1 1 0 0 1-.64.83.65.65 0 0 1-.66 0c-.94-.49-1.2-3.18-1.23-3.49a.22.22 0 0 1 .09-.17.18.18 0 0 1 .2 0 9.27 9.27 0 0 1 1.58 1.42 2 2 0 0 1 .66 1.41Zm-1.1.5a.31.31 0 0 0 .32 0 .66.66 0 0 0 .42-.52c0-.52-.6-1.34-1.73-2.23.15 1.07.5 2.49 1 2.76Z"
      fill="#e21f23"
    />
    <Path
      d="M389.94 193.18c.15.94-.34 2.86-1.11 3.28a.72.72 0 0 1-.86-.15.94.94 0 0 1-.31-.76c.08-1.11 1.95-2.45 2-2.49a.19.19 0 0 1 .18 0 .06.06 0 0 1 0 .06.24.24 0 0 1 .1.06Zm-1.69 2.82a.33.33 0 0 0 .43.1 3.92 3.92 0 0 0 .94-2.57c-.58.46-1.53 1.35-1.57 2a.54.54 0 0 0 .12.42Z"
      fill="#e21f23"
    />
    <Path
      d="M309.24 119.13s-5.27 6.07-10.63 6.79c-4.18.55-4-3.07-4-3.07a7.44 7.44 0 0 0 .94-2c1.46-4.69-3.33-8.09-3.33-8.09l4.29-2.23 9.38-4.9c-2.59 9 3.35 13.5 3.35 13.5Z"
      fill="#e9b376"
    />
    <Path
      d="M305.07 109.47s-2.82 9.77-9.46 11.36c1.45-4.69-3.33-8.09-3.33-8.09l4.29-2.23Z"
      opacity={0.2}
    />
    <Path
      d="M311.43 93.72c1.11 8.58-1.75 18.93-8.5 21.64-12.42 5-17.95 3.51-21.33-5.76-3.08-8.33-2.81-22.51 7.34-27.21s20.93-.59 22.49 11.33Z"
      fill="#e9b376"
    />
    <Path
      d="M292.59 100.1c.18 1.09-.26 2.07-.94 2.18s-1.43-.68-1.61-1.77.26-2 .94-2.16 1.43.65 1.61 1.75ZM283.84 101.09c.17 1-.24 2-.94 2.09s-1.36-.66-1.53-1.69.25-2 .94-2.08 1.41.65 1.53 1.68ZM288.57 95.16a.47.47 0 0 1-.2-.22.48.48 0 0 1 .29-.63 4.85 4.85 0 0 1 4.49.48.49.49 0 0 1 .09.69.51.51 0 0 1-.69.1 3.86 3.86 0 0 0-3.58-.31.55.55 0 0 1-.4-.11ZM280.23 97a.52.52 0 0 1-.29-.09.5.5 0 0 1 0-.7 4.83 4.83 0 0 1 4.17-1.73.49.49 0 0 1 .42.55.52.52 0 0 1-.57.42 3.88 3.88 0 0 0-3.29 1.44.53.53 0 0 1-.44.11Z"
      fill="#263238"
    />
    <Path
      d="m285.75 102.08 1.87 4.41a6 6 0 0 1-3.49.38 14 14 0 0 0 1.62-4.79Z"
      fill="#d58745"
    />
    <Path
      d="M302.93 100.8s1.27-5.63 4.28-4.5 4.09 10 .08 10.71-5.65-3.01-4.36-6.21Z"
      fill="#e9b376"
    />
    <Path
      d="M288.11 110.23a27.9 27.9 0 0 1 2.76 2.89l.61-.35c2.73-1.65 3.46-3.31 3.43-4.65a4.65 4.65 0 0 0-1.17-2.81c-.94 1.73-3.37 3.45-4.69 4.31a8.42 8.42 0 0 1-.94.61Z"
      fill="#263238"
    />
    <Path
      d="m289 109.66.84.84c2.57-1.55 4.17-3 4.51-4.25a4 4 0 0 0-.65-.94c-.9 1.77-3.33 3.49-4.7 4.35Z"
      fill="#fff"
    />
    <Path
      d="M291.48 112.77c2.73-1.65 3.46-3.31 3.43-4.65a10.4 10.4 0 0 0-2.67 2.41 3.85 3.85 0 0 0-.76 2.24Z"
      fill="#c15854"
    />
    <Path
      d="M302 139.21s5.19-6.4 8.3-1.74c3.86 5.77-8.39 14.76-8.39 14.76s-8.82-7.21-6.48-12.68c2.96-6.9 6.57-.34 6.57-.34Z"
      fill="#fff"
    />
    <Path
      d="M296.36 165c-2.89 7.25-1.48 31.9 4.25 48.23 0 0 7.58 2.35 28.81-3.66 17.48-4.94 27.95-11.78 27.95-11.78-6.19-12.81-16.77-43.08-19.29-44Z"
      fill="#e21f23"
    />
    <Path
      d="M357.37 197.75s-1.55 1-4.43 2.53c-1.37.72-3 1.56-5 2.46-1.74.8-3.71 1.66-5.9 2.51q-2.24.92-4.79 1.81c-2.41.85-5 1.69-7.83 2.48-.76.22-1.49.42-2.21.61-1.34.36-2.63.69-3.86.94-3 .71-5.64 1.25-8 1.61-1.88.31-3.48.52-4.94.65a45.7 45.7 0 0 1-5.36.26 14.73 14.73 0 0 1-2-.08 12.77 12.77 0 0 1-2.53-.37c-5.72-16.37-7.13-41-4.24-48.22L338 153.68c2.6 1 13.18 31.32 19.37 44.07Z"
      opacity={0.2}
    />
    <Path
      d="m299.92 188.32 5.18 25.33a14.73 14.73 0 0 1-2-.08ZM310.46 213.39l-3.75-38.21 8.65 37.53c-1.8.29-3.44.55-4.9.68ZM323.36 211.16l-7.16-38.56 11 37.59c-1.32.32-2.61.65-3.84.97ZM337.26 207.06l-9.38-38.24 14.21 36.43c-1.53.61-3.09 1.22-4.83 1.81ZM352.94 200.28c-1.37.72-3 1.56-5 2.46l-11.12-36.33ZM359.44 202.72a26.54 26.54 0 0 1 0 5.15 9.17 9.17 0 0 0 1.76-6.31ZM287.23 137.48l.52-7.75 1.07 6.21-1.59 1.54z"
      opacity={0.2}
    />
    <Path
      d="M325 110.44c-.65-1.41-8.26-26.83-16.94-31.37-4.7-2.9-11.26-3.18-17.56-1.76-9.15 2-11.41 9.15-6 12 .09.44 10.39 2.54 14.14 8.47 4 6.34.66 9.24 3 13.9s3.37 5.42 3.37 5.42l17-4.65s3.68-.45 2.99-2.01Z"
      fill="#263238"
    />
    <Path
      d="m247.68 418.56-9.21 25.62-8.59-2.69 2.69-26.97 15.11 4.04z"
      fill="#e9b376"
    />
    <Path
      d="M239.84 451.73c-.2 1.87-.34 3.16-.34 3.16h-7.77a5.9 5.9 0 0 0-2.49-.72c-.92.12-4.12.74-4.12.74h-20.64a15.94 15.94 0 0 1 .6-2.32 10.07 10.07 0 0 1 1-2.13 5.8 5.8 0 0 1 4.91-3c2.89-.19 12-6.28 12-6.28a5.43 5.43 0 0 0 3.47-4.68c.06-1.31 2-2.92 2.93-3.06 1.88-.28 2.94.92 3.21 2.8l.33 2.19 4.35-.68s4.43-2.25 4.22-.08"
      fill="#263238"
    />
    <G opacity={0.2}>
      <Path
        d="M204.48 455h20.64s3.2-.62 4.12-.74a5.9 5.9 0 0 1 2.49.72h7.77s.14-1.29.34-3.16l-34.76.92a15.94 15.94 0 0 0-.6 2.26Z"
        fill="#fff"
      />
    </G>
    <Path
      d="M218.41 443.55a.29.29 0 0 1 .35 0 3.66 3.66 0 0 1 1.24 1.42c.15.32-.31.62-.47.3a3.15 3.15 0 0 0-1.08-1.25.28.28 0 0 1 0-.43ZM220.05 442.58a.29.29 0 0 1 .35 0 3.66 3.66 0 0 1 1.24 1.42c.15.31-.31.62-.47.3a3.08 3.08 0 0 0-1.08-1.25.28.28 0 0 1-.05-.44ZM221.74 441.67a.29.29 0 0 1 .35 0 3.58 3.58 0 0 1 1.24 1.42c.15.32-.31.63-.46.31a3.21 3.21 0 0 0-1.09-1.26.28.28 0 0 1-.05-.43Z"
      fill="#e21f23"
    />
    <Path
      d="m307.07 415.72 4.06 27.73 9.05.37 2.67-26.8-15.78-1.3z"
      fill="#e9b376"
    />
    <Path
      d="m328 456.18-20.24.2s-.45-.84 0-3.11q.1-.52.3-1.17s0 0 0-.06c.19-.63.4-1.4.62-2.24.89-3.39 1.94-7.83 1.94-7.83s3.3-7.25 7.26-5.49c3.47 1.55 2.7 6.08 2.7 6.08a8.85 8.85 0 0 0 3 5c1.64 1.43 3.26 2.54 4.06 4.33a6.51 6.51 0 0 1 .35 1.08.59.59 0 0 1 0 .14 8.4 8.4 0 0 1 .01 3.07Z"
      fill="#263238"
    />
    <G opacity={0.2}>
      <Path
        d="M307.8 453.27c-.48 2.27 0 3.11 0 3.11l20.24-.2a8.4 8.4 0 0 0 .05-3.11Z"
        fill="#fff"
      />
    </G>
    <Path
      d="M319.82 443.7a2.72 2.72 0 0 0-3.65.26c-.28.28-.7-.14-.43-.41a3.34 3.34 0 0 1 4.5-.28c.31.24-.12.66-.42.43ZM320.41 445.59a2.71 2.71 0 0 0-3.65.26c-.28.27-.7-.15-.43-.42a3.33 3.33 0 0 1 4.5-.27c.31.23-.12.66-.42.43ZM321 447.32a2.71 2.71 0 0 0-3.65.26.3.3 0 0 1-.43-.42 3.34 3.34 0 0 1 4.5-.27c.31.24-.12.66-.42.43Z"
      fill="#e21f23"
    />
    <Path
      d="m327.22 240.31-42.39.77-26.61-.54s-17.89 80.72-19.33 95.9-14.27 95.18-14.27 95.18l20.73 4.38s22.68-85.62 24.29-93.85c1.41-7.16 17.3-53.17 21.36-64.87.44 2.38.9 4.77 1.36 7.15 1.16 10.74 4.88 45 6.48 57.72 0 0 3.92 72.82 7.85 93.94l19.46-.16c2.28-30.06 2.89-135.57 2.89-135.57 1.21-22.97-1.82-60.05-1.82-60.05Z"
      fill="#263238"
    />
    <G
      style={{
        opacity: 0.5,
      }}>
      <Path d="M269.25 252.47a25.73 25.73 0 0 1-17.07 17.2c-.43.13-.24.82.2.68a26.44 26.44 0 0 0 17.56-17.69.36.36 0 0 0-.69-.19ZM291.45 252.25l-1.22 21.35c0 .46-.73.47-.71 0q.32-5.48.62-11c-1.74 1.37-3.81-.25-4.4-2.05-1-2.93-.64-6.42.06-9.36a.36.36 0 0 1 .69.18 20.25 20.25 0 0 0-.52 6.3 8.45 8.45 0 0 0 .71 3.29c.57 1.1 2 2 3.13 1a.35.35 0 0 1 .37-.06c.19-3.22.38-6.46.56-9.68.02-.42.73-.42.71.03ZM312.37 253.26a18 18 0 0 0 16.71 17.89c.45 0 .44-.67 0-.7a17.24 17.24 0 0 1-16-17.2.35.35 0 0 0-.7 0Z" />
    </G>
    <Path
      d="M242.19 142.15c-8.38 4.92-14.65 34.72-14.65 34.72l23.46 7.66 8.14-17.07Z"
      fill="#e21f23"
    />
    <Path
      d="m247.77 166.55-1.34 16.6-5.89-2c2.38-6.36 1.2-29.83 1.2-29.83s3.87 11.02 6.03 15.23Z"
      opacity={0.2}
    />
    <Path
      d="M246.83 140c6-1.72 25.94-8.67 51.84-9.2l11.93 25.4 12.87 62.64s2.53 7.18 4.65 20.87c-23.7 15.78-56.15 15.44-75 8.81-.74-8.08.52-6.51-1.24-22.08-3.1-27.42-9-58.89-10-84 .12-.59 4.35-2.25 4.95-2.44Z"
      fill="#e21f23"
    />
    <Path
      d="M282.93 131.37c-4.65-7.81-11.73-14-11.64-25.58l-14.39 21.6a21.34 21.34 0 0 1 1.9 2.34 10.55 10.55 0 0 1 2 5c.14 1.5-2.53 4.77-2.53 4.77s6.8 3.64 18.27-.22c6.83-2.28 6.39-7.91 6.39-7.91Z"
      fill="#e9b376"
    />
    <Path
      d="M268.64 116.07c3.84 8.61-3.74 15.77-7.82 18.63a10.55 10.55 0 0 0-2-4.95Z"
      opacity={0.2}
    />
    <Path
      d="M270.84 100.13c.8 11.5 1.48 18-3.54 24.54-7.54 9.87-22 6.58-26.22-4.49-3.8-10-4.46-27.11 6.15-33a15.91 15.91 0 0 1 23.61 12.95Z"
      fill="#e9b376"
    />
    <Path
      d="M267.41 83.8c-4.91-6.5-15.08-1.67-19.06 2.12-.6.56 9.35 4 8.76 3.9l3 2.84c.42-.15 1 3.82 1.56 7.27a17.52 17.52 0 0 0 3.83 8.59 45.61 45.61 0 0 0 6.64 6.62c3.73-2.47 3.05-9.44 3.77-13.73 1.09-6.41 1.68-18.47-8.5-17.61Z"
      fill="#263238"
    />
    <Path
      d="M276.6 109.05a10.86 10.86 0 0 1-4.54 7c-3.19 2.14-4.95-1.42-5.09-5.06-.14-3.27.29-7.34 4-8s6.23 2.56 5.63 6.06Z"
      fill="#e9b376"
    />
    <Path
      d="M257.93 105.17c.16 1.17-.34 2.19-1.11 2.29s-1.51-.78-1.66-1.95.34-2.19 1.11-2.28 1.51.77 1.66 1.94ZM244.73 107.15c.15 1.17-.34 2.19-1.11 2.28s-1.51-.78-1.66-1.94.34-2.19 1.11-2.29 1.51.8 1.66 1.95Z"
      fill="#263238"
    />
    <Path
      d="M247.16 106.16s-2.94 6.29-3.82 8.81c1.82 1.25 4.53.21 4.53.21Z"
      fill="#d58745"
    />
    <Path
      d="M257.07 99.68a.54.54 0 0 1-.56 0 4.5 4.5 0 0 0-4-.38.55.55 0 0 1-.74-.25.54.54 0 0 1 .25-.73 5.49 5.49 0 0 1 5 .42.54.54 0 0 1 .2.75.52.52 0 0 1-.15.19Z"
      fill="#263238"
    />
    <Path
      d="M268.82 110.59a.25.25 0 0 1-.25-.25 5.92 5.92 0 0 1 .74-3 3.85 3.85 0 0 1 1.89-1.79 2.33 2.33 0 0 1 2.57.51.25.25 0 0 1 0 .35.25.25 0 0 1-.36 0 1.85 1.85 0 0 0-2-.38 3.44 3.44 0 0 0-1.64 1.57 5.48 5.48 0 0 0-.67 2.74.26.26 0 0 1-.25.26Z"
      opacity={0.2}
    />
    <Path
      d="M237.81 103a.53.53 0 0 1-.29-.12.56.56 0 0 1-.1-.78 5.42 5.42 0 0 1 4.51-2.17.55.55 0 0 1-.09 1.1 4.19 4.19 0 0 0-3.55 1.76.57.57 0 0 1-.48.21ZM267.41 83.8a19.53 19.53 0 0 0-18.84-7c-9.91 1.72-15.44 8.57-16 15.4a13.81 13.81 0 0 0 .94 6.75 1.41 1.41 0 0 0 2.64-.1 9.28 9.28 0 0 1 .45-1 2.56 2.56 0 0 0 .11.24 1.43 1.43 0 0 0 2.68-.48 7.21 7.21 0 0 1 .22-1.3 1.41 1.41 0 0 0 2.58-.32 10.22 10.22 0 0 1 13.9-6.13l4.08 2.76c4.99-2.5 7.24-8.82 7.24-8.82ZM253 118.56a33.14 33.14 0 0 1 4 2.34c.18-.2.38-.39.56-.6 2.53-2.73 2.81-4.83 2.34-6.33a5 5 0 0 0-1.21-1.94 4.63 4.63 0 0 0-1-.79c-.48 2.25-2.63 5-3.85 6.38-.57.58-.84.94-.84.94Z"
      fill="#263238"
    />
    <Path
      d="m253.78 117.62 1.21.65c2.37-2.57 3.71-4.69 3.67-6.24a4.57 4.57 0 0 0-1-.79c-.5 2.27-2.66 4.97-3.88 6.38Z"
      fill="#fff"
    />
    <Path
      d="M257.53 120.3c2.54-2.73 2.82-4.83 2.34-6.33a11.91 11.91 0 0 0-2.21 3.58 4.53 4.53 0 0 0-.13 2.75Z"
      fill="#c15854"
    />
    <Path
      d="m256.5 138.38 3.73-6.02 8.67 6.26 9.82-13.84 7.61 6.95-9.93 15.36-11.27-.93-8.63-7.78z"
      fill="#e21f23"
    />
    <Path
      d="M352.43 153.32c-4.25-5-32.67-13.2-38.68-15.39l-8.65 17.93 7.49 1.75 29.46 7.6 6.69 9.27a35.82 35.82 0 0 0 4.51-6.62c2.29-4.15 1.18-12.2-.82-14.54Z"
      fill="#e9b376"
    />
    <Path
      d="m328.87 142.26-4.59 18.38-9.73-2.32 3.38-19.32s5.07 1.64 10.94 3.26Z"
      opacity={0.2}
    />
    <Path
      d="M290.87 134c-1.73 5.71-.91 16 7.81 20.61 6.2 3.29 20.65 7.9 20.65 7.9l10.49-23.32s-36.07-14.7-38.95-5.19Z"
      fill="#e21f23"
    />
    <Path
      d="m311.33 159.79-9.15-4.34 10.24 9.61-1.09-5.27z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M292.92 236.3c5.4-1.22 9.86-6.92 12.18-11.94a139.37 139.37 0 0 0 5.08-13.92l-9.52 12.28a37.05 37.05 0 0 1-6.46 7c-3.51 2.68-7.85 4-12.13 5.07a128.31 128.31 0 0 1-16.85 3 84.08 84.08 0 0 0 27.7-1.49Z"
      opacity={0.2}
    />
    <Path
      d="M279.63 143.76s-1.63-2.71-3.74-3.33-3.76-1.79-5.34-2.13-1.07 1.57-.07 2.47 3.2 2 2.63 2.76-7.68 2.6-8.35 3.72.6 1.27.6 1.27-1.59.44-1.62 1.44 1.08 1.12 1.08 1.12-1.05.34-.65 1.26 7 0 7.34.15c0 0-4.12-.1-4.34 1.09s2.07 1.39 3.92 1.61a9.58 9.58 0 0 0 5.54-1.32c2-1.14 5-2.08 4.83-4.74a14.22 14.22 0 0 0-1.83-5.37Z"
      fill="#e9b376"
    />
    <Path
      d="M311.78 120.84c-4.51 4-11.45 16-14.55 16.76S280 144.49 280 144.49l.64 7.4s18.61-1.56 23.09-4 15.67-11.58 17.9-15.81c4.24-8.08-5.84-15.14-9.85-11.24Z"
      fill="#e21f23"
    />
    <G opacity={0.30000000000000004}>
      <Path
        d="M311.78 120.84c-4.51 4-11.45 16-14.55 16.76S280 144.49 280 144.49l.64 7.4s18.61-1.56 23.09-4 15.67-11.58 17.9-15.81c4.24-8.08-5.84-15.14-9.85-11.24Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default FamilyMembers;
