import {StyleSheet, View} from 'react-native';
import React from 'react';
import {Avatar, Header} from '../../../components';
import {useAppSelector} from '../../../services/redux/hook';
import {ApiRoutes} from '../../../services/api/path';
import {Outlet} from 'react-router-dom';

const HomeWrapper: React.FC = () => {
  const {user} = useAppSelector(state => state.userReducer);
  return (
    <View style={styles.container}>
      <Header
        rightComponent={
          <Avatar
            image={
              user?.profileImage
                ? {
                    uri: `${ApiRoutes.BaseURL}${user?.profileImage?.substring(
                      1,
                    )}`,
                  }
                : 0
            }
            firstName={user?.firstName}
            lastName={user?.lastName}
          />
        }
      />
      <View style={styles.scrollView}>
        <Outlet />
      </View>
    </View>
  );
};

export default HomeWrapper;

const styles = StyleSheet.create({
  container: {
    //@ts-ignore
    height: '100vh',
    display: 'flex',
  },
  scrollView: {
    flex: 1,
    maxHeight: '100%',
    display: 'flex',
    overflow: 'scroll',
    //@ts-ignore
    '::-webkit-scrollbar': {display: 'none'},
  },
});
