import i18next, {InitOptions, Resource} from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from '../setup/intl/languages/en.json';
import hi from '../setup/intl/languages/hi.json';

export const languageResources: Resource = {
  en: {translation: en},
  hi: {translation: hi},
};

const initOptions: InitOptions = {
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  resources: languageResources,
};

i18next.use(initReactI18next).init(initOptions);

export default i18next;
