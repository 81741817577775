import {View, Text} from 'react-native';
import React from 'react';
import {EmptyComponentProps} from './EmptyComponent.styles';
import {style} from './EmptyComponent.types';

const EmptyComponent: React.FC<EmptyComponentProps> = ({
  icon: Icon,
  isFetching,
  message,
}) => {
  if (isFetching) {
    return null;
  }
  return (
    <View style={style.container}>
      {Icon && Icon}
      <Text style={style.meesageText}>{message}</Text>
    </View>
  );
};

export default EmptyComponent;
