import {
  landingBanner1,
  landingBanner2,
  landingBanner3,
  landingBanner4,
  landingBanner5,
} from '../../assets/png';

export const landingPageData = [
  {
    id: 1,
    name: 'Bhadreshwar Jain Temple, Kutch, Gujarat',
    image: landingBanner1,
  },
  {
    id: 2,
    name: 'Naulakha Temple, Rajgir, Bihar',
    image: landingBanner2,
  },
  {
    id: 3,
    name: 'Gadarmal Temple, Badoh and Pathari, Madhya Pradesh',
    image: landingBanner3,
  },
  {
    id: 4,
    name: 'Mahavirji Tample, Kaurali, Rajasthan',
    image: landingBanner4,
  },
  {
    id: 5,
    name: 'Kundalpur Jain Temple, Damoh, Madhya Pradesh',
    image: landingBanner5,
  },
];
