import {useEffect, useRef} from 'react';
import axios from '../api';
import {useAppDispatch} from '../../redux/hook';
import {userLogoutAction} from '../../redux/user/user.action';
import {isBoolean} from 'lodash';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';

export const ResponseInterceptor = () => {
  const dispatch = useAppDispatch();
  const {openModal} = useModal();
  const {t} = useTranslation();
  const interceptorId = useRef<number | null>(null);

  useEffect(() => {
    interceptorId.current = axios.interceptors.response.use(
      async response => {
        if (
          isBoolean(response.data?.isAuthorisedUser) &&
          response.data?.isAuthorisedUser === false
        ) {
          openModal({
            title: t('home.sessionTimeout'),
            message: response?.data?.message,
          });
          dispatch(userLogoutAction());
        }
        return response;
      },
      error => {
        openModal({
          title: t('misc.somethingWentWrong'),
          message: t('misc.somethingWentWrongMessage'),
        });
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptorId.current as number);
    };
  }, [dispatch, openModal, t]);

  return null;
};
