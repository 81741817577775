import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: Colors.background,
    borderRadius: 10,
    paddingHorizontal: 40,
    gap: 20,
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: FontSize.h4,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsBold,
    textAlign: 'center',
  },
  message: {
    fontSize: FontSize.h6,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
    textAlign: 'center',
  },
});
