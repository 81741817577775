import * as yup from 'yup';
import {SignUpFormFields} from '../screens/SignUp/SignUp.types';
import {
  bloodGroupRegex,
  emailRegex,
  middleNameRegex,
  nameRegex,
  phoneRegex,
} from '../utils/validation/consts';

yup.setLocale({
  mixed: {
    required: ({path}) => `profile.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `profile.errors.${path}.matches`,
  },
});

export const signUpFormSchema = yup
  .object<Record<keyof SignUpFormFields, yup.AnySchema>>({
    profileImage: yup.string(),
    firstName: yup.string().trim().required().matches(nameRegex),
    middleName: yup.string().trim().matches(middleNameRegex),
    lastName: yup.string().trim().required().matches(nameRegex),
    dob: yup.string().required(),
    bloodGroup: yup.string().trim().required().matches(bloodGroupRegex),
    email: yup
      .string()
      .trim()
      .test({
        name: 'isEmail',
        test: (value, {createError}) => {
          if (value && !emailRegex.test(value)) {
            return createError({
              message: 'profile.errors.email.matches',
              path: 'email',
            });
          }
          return true;
        },
      }),
    mobileNumber: yup.string().trim().required().matches(phoneRegex),
    whatsAppNumber: yup
      .string()
      .trim()
      .test({
        name: 'isWhatsAppNumber',
        test: (value, {createError}) => {
          if (value && !phoneRegex.test(value)) {
            return createError({
              message: 'profile.errors.whatsAppNumber.matches',
              path: 'whatsAppNumber',
            });
          }
          return true;
        },
      }),
    acceptTandC: yup.boolean().isTrue('profile.errors.acceptTandC'),
  })
  .required();
