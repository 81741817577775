import {useCallback, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {FamilyMembersController, LoadingState} from './FamilyMembers.types';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {AllFamilyMembers} from '../../../services/api/response/user';
import {familyMembersAction} from '../../../services/redux/user/user.action';
import {useFocusEffect} from '@react-navigation/native';

export const useFamilyMembersController = (): FamilyMembersController => {
  const dispatch = useAppDispatch();
  const {familyMembers, user} = useAppSelector(state => state.userReducer);
  const [loading, setLoading] = useState<LoadingState>({
    fetching: false,
    refreshing: false,
  });

  const fetchFamilyMembers = useCallback(
    async (isRefreshing?: boolean) => {
      try {
        setLoading({
          fetching: familyMembers?.length === 0,
          refreshing: !!isRefreshing,
        });
        const {data} = await axios.post<AllFamilyMembers>(
          ApiRoutes.FamilyMembers,
          {
            UId: user?.UId,
            ApiSecret: user?.ApiSecret,
            SkipSize: 0,
            PageSize: 100,
          },
        );
        if (data.isAuthorisedUser) {
          dispatch(familyMembersAction(data.familyMembers));
        }
        setLoading({
          fetching: false,
          refreshing: false,
        });
      } catch (e) {
        console.error(e);
        setLoading({
          fetching: false,
          refreshing: false,
        });
      }
    },
    [familyMembers, dispatch, user],
  );

  useFocusEffect(
    useCallback(() => {
      fetchFamilyMembers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  return {familyMembers, loading, fetchFamilyMembers};
};
