import {
  Text,
  Platform,
  ImageSourcePropType,
  TouchableOpacity,
} from 'react-native';
import React from 'react';
import {style} from './MemberInfo.styles';
import {Breakpoints} from '../../utils/theme';
import useDimensionsHook from '../../hooks/useDimensions';
import Avatar from '../Avatar/Avatar';
import {MemberInfoProps} from './MemberInfo.types';
import Button from '../Button/Button';

const MemberInfo: React.FC<MemberInfoProps> = ({
  name,
  position,
  image,
  buttonProps,
  containerStyle,
  onPress,
  disabled = false,
}) => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled}
      style={[
        style.container,
        {minWidth: isWeb ? 250 : 'auto'},
        containerStyle,
      ]}>
      <Avatar image={image as ImageSourcePropType} />
      <Text style={style.name} numberOfLines={2}>
        {name}
      </Text>
      <Text style={style.occupation}>{position}</Text>
      {buttonProps && (
        <Button
          {...buttonProps}
          style={[style.buttonStyle, buttonProps?.style]}
          textStyle={[style.buttonTextStyle, buttonProps?.textStyle]}
        />
      )}
    </TouchableOpacity>
  );
};

export default MemberInfo;
