import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const User = (props: SvgProps) => (
  <Svg width={30} height={30} viewBox="0 0 90 90" {...props}>
    <Path
      fill="#272828"
      d="M37.5 10.9C30.6 14 27 20.2 27 28.8c0 12 10.1 20.9 21.4 18.8C60 45.4 66.5 31.1 61.1 19.5c-2.2-4.8-4.4-6.9-9.4-9-4.8-2-9.3-1.9-14.2.4zM34.5 58.4c-2.7.7-7.7 2.4-11 4-10.3 4.7-14 10-9.7 13.9 1.6 1.5 5.3 1.7 31.4 1.7 27.3 0 29.6-.1 31.1-1.8 3.8-4.1.7-8.8-9-13.6-10.3-5.2-22.5-6.7-32.8-4.2z"
      {...props}
    />
  </Svg>
);
export default User;
