import {useCallback, useState} from 'react';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {
  FamilyMemberResponse,
  FamilyMembers,
} from '../../../services/api/response/user';
import {useFocusEffect} from '@react-navigation/native';
import {
  FamilyMembersController,
  LoadingState,
} from './OtherFamilyMembers.types';

export const useOtherFamilyMembersController = (
  loginId: number, // Use loginId as parameter
): FamilyMembersController => {
  const [loading, setLoading] = useState<LoadingState>({
    fetching: false,
    refreshing: false,
  });
  const [familyDetail, setFamilyDetail] = useState<FamilyMembers[]>([]);

  useFocusEffect(
    useCallback(() => {
      fetchFamilyMembers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const fetchFamilyMembers = async (isRefreshing?: boolean) => {
    try {
      if (!loginId) {
        console.error('Login ID is missing');
        return;
      }

      setLoading({
        fetching: true,
        refreshing: !!isRefreshing,
      });

      const {data} = await axios.post<FamilyMemberResponse>(
        ApiRoutes.AllFamilyMembersByLoginId,
        {
          UId: loginId,
          SkipSize: 0,
          PageSize: 100,
        },
      );

      setFamilyDetail(data?.familyMembers ?? []);

      setLoading({
        fetching: false,
        refreshing: false,
      });
    } catch (e) {
      console.error(e);
      setLoading({
        fetching: false,
        refreshing: false,
      });
    }
  };

  return {loading, familyDetail, fetchFamilyMembers};
};
