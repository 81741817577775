import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform,
  Image,
} from 'react-native';
import React from 'react';
import {styles} from './EventDetails.styles';
import {ApiRoutes} from '../../../services/api/path';
import useDimensionsHook from '../../../hooks/useDimensions';
import {BackArrow, CalenderOutLine, Clock} from '../../../assets/svg';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {Button} from '../../../components';
import {useLocation, useNavigate} from 'react-router-dom';
import {Breakpoints} from '../../../utils/theme';
import {noImage} from '../../../assets/png';
import Swiper from 'react-native-web-swiper';

const EventDetails: React.FC = () => {
  const {state} = useLocation();
  const navigate = useNavigate();
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const {title, images, description, scheduleDt} = state?.event;

  const onBackPress = (): void => {
    navigate(-1);
  };

  return (
    <View
      style={[styles.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={[
          styles.scrollView,
          {
            minWidth: isWeb ? Breakpoints.md : 'auto',
            maxWidth: Breakpoints.md,
          },
        ]}>
        <View style={styles.imageBackgroundView}>
          <Swiper
            key={images?.length}
            loop
            timeout={5}
            springConfig={{speed: 11}}
            controlsProps={{
              dotsTouchable: true,
              prevPos: false,
              nextPos: false,
              dotActiveStyle: styles.dotActiveStyle,
              dotProps: {
                badgeStyle: styles.dotInActiveStyle,
              },
            }}
            controlsEnabled={images.length > 1}
            minDistanceForAction={0}>
            {images?.map((image: string) => (
              <Image
                key={image + Math.random()}
                source={{uri: `${ApiRoutes.BaseURL}${image?.substring(1)}`}}
                style={[
                  styles.image,
                  {resizeMode: image ? 'stretch' : 'contain'},
                ]}
                defaultSource={noImage}
              />
            ))}
          </Swiper>

          <TouchableOpacity
            style={styles.backButton}
            activeOpacity={0.8}
            onPress={onBackPress}>
            <BackArrow width={20} height={20} />
          </TouchableOpacity>
        </View>
        <View style={styles.containerView}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{description}</Text>
        </View>
        <View style={styles.subTitleView}>
          <Text style={styles.subTitle}>
            <FormattedMessage id="event.eventDetails" />
          </Text>
        </View>
        <View style={[styles.rowView, {marginTop: 15}]}>
          <CalenderOutLine width={20} height={20} />
          <Text style={styles.dateAndTime}>
            {moment(scheduleDt).format('DD MMMM YYYY')}
          </Text>
        </View>
        <View style={styles.rowView}>
          <Clock width={20} height={20} style={{marginRight: 2}} />
          <Text style={styles.dateAndTime}>
            {moment(scheduleDt).format('LT')}
          </Text>
        </View>
        <Button
          style={styles.button}
          name={<FormattedMessage id="event.registerNow" />}
        />
      </ScrollView>
    </View>
  );
};

export default EventDetails;
