import {StyleSheet} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  contentContainer: {
    flex: 1,
    padding: 15,
    paddingHorizontal: 20,
  },
  keyBoardView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  avatarStyle: {
    alignSelf: 'center',
    marginBottom: 40,
    marginTop: 20,
  },
  nameRowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 5,
    marginTop: 15,
  },
  inputContainer: {
    marginTop: 10,
  },
  dropDownContainer: {
    flex: 1,
  },
  buttonStyle: {
    marginTop: 60,
    marginBottom: 50,
    height: 50,
  },
});
