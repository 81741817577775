import axios from '../../api/api';
import {ApiRoutes} from '../../api/path';
import {
  AddressObject,
  Banner,
  CategoryResponse,
  Occupation,
  OccupationResponse,
  Panth,
} from '../../api/response/app';
import {AppDispatch} from '../store';
import {AppActionType} from './app.types';

const getStateDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetState,
  payload,
});

export const getContactDistrictsDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetContactDistrics,
  payload,
});

export const getContactCitiesDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetContactCities,
  payload,
});

export const getContactAreasDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetContactAreas,
  payload,
});

export const getOfficeDistrictsDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetOfficeDistrics,
  payload,
});

export const getOfficeCitiesDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetOfficeCities,
  payload,
});

export const getOfficeAreasDataAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetOfficeAreas,
  payload,
});

export const fetchStateData = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post(ApiRoutes.State);

    const states: AddressObject[] = response.data.states;
    dispatch(getStateDataAction(states));
  } catch (error) {
    console.error(error);
  }
};

export const fetchDistrictsByState = async (stateId: number) => {
  try {
    const response = await axios.post(ApiRoutes.Districts, {
      StateId: stateId,
    });
    const districts: AddressObject[] = response.data?.districts;
    return districts;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCitiesByDistruct = async (districtId: number) => {
  try {
    const response = await axios.post(ApiRoutes.Cities, {
      DistrictId: districtId,
    });
    const cities: AddressObject[] = response.data?.cities;
    return cities;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAreaByCity = async (cityId: number) => {
  try {
    const response = await axios.post(ApiRoutes.Area, {
      CityId: cityId,
    });
    const area: AddressObject[] = response.data?.areaList;
    return area;
  } catch (error) {
    console.error(error);
  }
};

const getPanthDataAction = (payload: Panth[]) => ({
  type: AppActionType.GetPanths,
  payload,
});

export const fetchAllPanths =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const {data} = await axios.post(ApiRoutes.PanthNames);
      const panths: Panth[] = data.panths;
      panths?.push({id: panths.length + 1, panthName: 'Other'});
      dispatch(getPanthDataAction(panths));
    } catch (error) {
      console.error(error);
    }
  };

const getOccupationsAction = (payload: Occupation[]) => ({
  type: AppActionType.GetOccupations,
  payload,
});

export const fetchAllOccupation =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const {data} = await axios.post<OccupationResponse>(
        ApiRoutes.AllOccupation,
      );
      const occupations: Occupation[] = data.occupations;
      occupations?.push({
        id: occupations.length + 1,
        occupationName: 'Other',
      });
      dispatch(getOccupationsAction(occupations));
    } catch (error) {
      console.error(error);
    }
  };

export const getBannerAction = (payload: Banner[]) => ({
  type: AppActionType.GetBanner,
  payload,
});

const getCategoriesAction = (payload: AddressObject[]) => ({
  type: AppActionType.GetCategories,
  payload,
});

export const fetchAllCategorie =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const {data} = await axios.post<CategoryResponse>(
        ApiRoutes.AllCategories,
      );
      dispatch(getCategoriesAction(data.messageCategories));
    } catch (error) {
      console.error(error);
    }
  };
