import {combineReducers} from 'redux';
import appReducer from './app/app.reducer';
import userReducer from './user/user.reducer';
import communityReducer from './community/community.reducer';
import matrimonialReducer from './matrimonial/matrimonial.reducer';
const rootReducer = combineReducers({
  appReducer,
  userReducer,
  communityReducer,
  matrimonialReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
