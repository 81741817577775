import {Linking, ScrollView, Text, View, Image} from 'react-native';
import React, {useState} from 'react';
import {style} from './AccountAndSettings.styles';
import {Avatar, FeatureInfoButton, Header, Spinner} from '../../../components';
import {FormattedMessage} from 'react-intl';
import {
  BackArrow,
  OnOff,
  User,
  Family,
  Policy,
  Edit,
  Delete,
  Lock,
} from '../../../assets/svg';
import {NavigationNames} from '../../../utils/routeNames';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {ApiRoutes, SupportURLs} from '../../../services/api/path';
import {Colors} from '../../../utils/theme';
import {userLogoutAction} from '../../../services/redux/user/user.action';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import axios from '../../../services/api/api';
import i18next from '../../../services/i18next';
import {useTranslation} from 'react-i18next';
import {languageIcon} from '../../../assets/png';
import ChangePasswordModal from '../../../components/modal/changePasswordModal';

const AccountAndSettings: React.FC = () => {
  const {user} = useAppSelector(state => state.userReducer);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {openModal, closeModal} = useModal();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const logOutButtonPress = () => {
    openModal({
      title: t('misc.confirmLogout'),
      message: t('misc.areYouSure'),
      hideCenterLogo: true,
      positiveButtonProps: {
        name: t('misc.ok'),
        onPress: () => {
          closeModal();
          dispatch(userLogoutAction());
          navigate(NavigationNames.Login, {
            replace: true,
          });
        },
      },
      negativeButtonProps: {
        name: t('misc.cancel'),
        onPress: closeModal,
      },
    });
  };

  const changeLang = (lng: any) => {
    i18next.changeLanguage(lng);
  };

  const deleteAccountAPi = async () => {
    try {
      closeModal();
      setIsLoading(true);
      const {} = await axios.post(ApiRoutes.DeleteAccount, {
        UId: user?.uId,
        ApiSecret: user?.apiSecret,
      });
      setIsLoading(false);
      dispatch(userLogoutAction());
      navigate(NavigationNames.Login, {
        replace: true,
      });
      openModal({
        title: t('misc.success'),
        message: t('misc.deleteSuccessMessage'),
        positiveButtonProps: {
          name: t('misc.ok'),
          onPress: closeModal,
        },
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const deleteAccountButtonPress = () => {
    openModal({
      title: t('misc.warning'),
      message: t('misc.deleteAccountDescription'),
      hideCenterLogo: true,
      positiveButtonProps: {
        name: t('misc.yes'),
        onPress: deleteAccountAPi,
      },
      negativeButtonProps: {
        name: t('misc.cancel'),
        onPress: closeModal,
      },
    });
  };
  const openPrivacyPolicy = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.PrivacyPolicy);

    if (supported) {
      await Linking.openURL(SupportURLs.PrivacyPolicy);
    }
  };

  const changePasswordButtonPress = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <View style={style.container}>
      <Spinner visible={isLoading} />
      <Header
        leftComponent={<BackArrow width={20} height={20} />}
        leftComponentPress={() =>
          navigate(NavigationNames.Home, {
            replace: true,
          })
        }
        title={<FormattedMessage id={t('misc.accountAndSettings')} />}
        rightComponent={null}
      />
      <ScrollView contentContainerStyle={style.contentContainerStyle}>
        <View style={style.profileView}>
          <Avatar
            image={{
              uri: `${ApiRoutes.BaseURL}${user?.profileImage?.substring(1)}`,
            }}
            firstName={user?.firstName}
            middleName={user?.middleName}
            lastName={user?.lastName}
            size="large"
          />
          <Text style={style.titleText}>{`${user?.firstName} ${
            user?.middleName === null ? '' : user?.middleName
          } ${user?.lastName}`}</Text>
          <Text style={style.occupationText}>{user?.occupation}</Text>
          <View style={style.langSelectionWrapperSetting}>
            <Image
              style={{
                width: 60,
                height: 50,
                resizeMode: 'contain',
              }}
              source={languageIcon}
            />
            <Text
              style={style.languageName}
              onPress={() => {
                changeLang('en');
              }}>
              English |
            </Text>

            <Text
              style={style.languageName}
              onPress={() => {
                changeLang('hi');
              }}>
              हिंदी
            </Text>
          </View>
        </View>
        <View style={style.boxView}>
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.viewProfile')} />}
            icon={<User width={25} height={25} fill={Colors.primary} />}
            onPress={() =>
              navigate(NavigationNames.ProfileDetail, {
                state: {
                  profile: {
                    image: user?.profileImage ?? '',
                    loginId: user?.uId,
                    name: `${user?.firstName} ${user?.middleName} ${user?.lastName}`,
                    position: user?.occupation,
                  },
                },
              })
            }
          />
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.editProfile')} />}
            icon={<Edit width={25} height={25} fill={Colors.primary} />}
            onPress={() => navigate(NavigationNames.EditProfile)}
          />
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.familyMembers')} />}
            icon={<Family width={25} height={25} fill={Colors.primary} />}
            onPress={() => navigate(NavigationNames.FamilyMembers)}
          />
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.privacyPolicy')} />}
            icon={<Policy width={25} height={25} fill={Colors.primary} />}
            onPress={openPrivacyPolicy}
          />
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.changePassword')} />}
            icon={<Lock width={25} height={25} fill={Colors.primary} />}
            onPress={changePasswordButtonPress}
          />

          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.deleteAccount')} />}
            icon={<Delete width={25} height={25} fill={Colors.primary} />}
            onPress={deleteAccountButtonPress}
          />

          <View>
            <ChangePasswordModal
              visible={isModalVisible}
              onClose={handleCloseModal}
            />
          </View>
          <FeatureInfoButton
            name={<FormattedMessage id={t('misc.logout')} />}
            rightComponent={
              <OnOff width={20} height={20} fill={Colors.maroon} />
            }
            onPress={logOutButtonPress}
            nameStyle={style.logoutText}
            containerStyle={style.logoutButton}
          />
        </View>
        <Text style={style.appVersionText}>
          <FormattedMessage id={t('misc.appVersion')} />
        </Text>
      </ScrollView>
    </View>
  );
};

export default AccountAndSettings;
