import React, {useState, useRef} from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {style} from './Avatar.styles';
import {AvatarProps} from './Avatar.types';
import {Edit, User} from '../../assets/svg';
import {getAvatarHeightWidth, getAvatarTextVariant} from './Avatar.utils';
import {Colors} from '../../utils/theme';

const Avatar: React.FC<AvatarProps> = ({
  image,
  firstName,
  lastName,
  size = 'medium',
  containerStyle,
  editable,
  onIconPress,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const inputElement = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setSelectedImage(result);
        onIconPress && onIconPress(result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <View style={[style.container, getAvatarHeightWidth(size), containerStyle]}>
      {selectedImage ? (
        <Image style={style.image} source={{uri: selectedImage}} />
      ) : image ? (
        <Image style={style.image} source={image} />
      ) : firstName ? (
        <Text style={[style.textStyle, getAvatarTextVariant(size)]}>
          {`${firstName?.[0]}${lastName?.[0]}`.toUpperCase()}
        </Text>
      ) : (
        <User fill={Colors.lightGray} />
      )}
      {editable && (
        <TouchableOpacity
          onPress={() => inputElement.current?.click()}
          activeOpacity={0.8}
          style={style.editableButton}>
          <Edit onPress={() => inputElement.current?.click()} />
          <input
            type="file"
            accept="image/*"
            style={{display: 'none'}}
            onChange={handleImageChange}
            ref={inputElement}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Avatar;
