import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const AboutUs = (props: SvgProps) => (
  <Svg width={120} height={120} viewBox="0 0 90 90" {...props}>
    <Path d="M38.3 19.5C33.8 21.4 32 25.6 32 34c0 6.3.3 7.6 3.1 11.6 4.7 6.9 4 9-5 13.9C22 64 19.4 66.7 18.4 71.7l-.6 3.3h54.5l-.7-3.3c-1-5-3.6-7.7-11.7-12.2-9-4.9-9.7-7-5-14.1 2.7-4 3.1-5.3 3.1-11.5 0-8.5-.7-9.9-6.4-13.3-5-2.9-8.5-3.2-13.3-1.1z" />
    <Path d="M16.3 26.9c-2.9 2.1-4.2 3.8-4.6 6.3-.9 5.7-.1 12.2 2.2 15.8 4.4 7.3 4.3 7.7-3.9 12.1-6.7 3.5-8.6 5.7-9.6 10.7L-.2 75h6.1c5.5 0 6.1-.2 6.1-2 0-5.6 5.6-13.7 11.5-16.7 1.7-.8 4-2.5 5.2-3.6l2.3-2-2.5-4.3c-2.2-3.7-2.5-5.5-2.5-13.3V24h-2.8c-1.6 0-4.7 1.3-6.9 2.9zM64 33.1c0 7.8-.3 9.6-2.5 13.3L59 50.7l2.3 2c1.2 1.1 3.6 2.8 5.2 3.6C72.4 59.3 78 67.4 78 73c0 1.8.6 2 6.1 2h6.2l-.6-3c-1-4.9-3.4-7.6-9.8-11-8.1-4.3-8.2-4.7-3.8-12 2.3-3.6 3.1-10.1 2.2-15.8-.7-4.1-7.1-9.2-11.5-9.2H64v9.1z" />
  </Svg>
);
export default AboutUs;
