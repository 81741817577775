import {
  View,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
} from 'react-native';
import React from 'react';
import {style} from './EditProfile.styles';
import {
  Avatar,
  Button,
  DatePicker,
  DropDown,
  FeatureInfoButton,
  Header,
  InputWithControl as Input,
  Spinner,
} from '../../../components';
import {BackArrow, OnOff} from '../../../assets/svg';
import {FormattedMessage, useIntl} from 'react-intl';
import {Control, Controller, FieldValues} from 'react-hook-form';
import {getErrorProps} from '../../../utils/validation/validationFunctions';
import {useEditProfileController} from './EditProfile.controller';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {ApiRoutes} from '../../../services/api/path';
import {useNavigate} from 'react-router-dom';
import useDimensionsHook from '../../../hooks/useDimensions';
import {Breakpoints, Colors} from '../../../utils/theme';
import {NavigationNames} from '../../../utils/routeNames';
import {useTranslation} from 'react-i18next';
import {bloodGroups} from '../../SignUp/BloodGroups';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import {userLogoutAction} from '../../../services/redux/user/user.action';

const EditProfile: React.FC = () => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const navigate = useNavigate();
  const intl = useIntl();
  const {user} = useAppSelector(state => state.userReducer);
  const {openModal, closeModal} = useModal();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {
    states,
    control,
    contactDistrictData,
    contactAreasData,
    contactCitiesData,
    officeDistrictData,
    officeAreasData,
    officeCitiesData,
    panths,
    panth,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    contactCity,
    contactState,
    contactDistrict,
    officeCity,
    officeDistrict,
    officeState,
    setValue,
    occupations,
    occupation,
  } = useEditProfileController();

  const onSuccess = (): void => {
    if (user?.hasProfileCompleted) {
      navigate(NavigationNames.AccountAndSettings, {
        replace: true,
      });
    } else {
      navigate(NavigationNames.Home, {
        replace: true,
      });
    }
  };
  const logOutButtonPress = () => {
    openModal({
      title: t('misc.confirmLogout'),
      message: t('misc.areYouSure'),
      hideCenterLogo: true,
      positiveButtonProps: {
        name: t('misc.ok'),
        onPress: () => {
          closeModal();
          dispatch(userLogoutAction());
          navigate(NavigationNames.Login, {
            replace: true,
          });
        },
      },
      negativeButtonProps: {
        name: t('misc.cancel'),
        onPress: closeModal,
      },
    });
  };
  return (
    <View style={[style.container, {alignItems: isWeb ? 'center' : 'stretch'}]}>
      <Spinner visible={isLoading} />

      <Header
        leftComponent={
          user?.hasProfileCompleted ? (
            <BackArrow width={20} height={20} />
          ) : null
        }
        leftComponentPress={onSuccess}
        title={
          user?.hasProfileCompleted ? (
            <FormattedMessage id={t('misc.editProfile')} />
          ) : (
            <FormattedMessage id={t('misc.completeYourProfile')} />
          )
        }
        rightComponent={null}
      />
      <FeatureInfoButton
        name={<FormattedMessage id={t('misc.logout')} />}
        rightComponent={<OnOff width={20} height={20} fill={Colors.maroon} />}
        onPress={logOutButtonPress}
        nameStyle={style.logoutText}
        containerStyle={style.logoutButton}
      />
      <KeyboardAvoidingView
        style={style.keyBoardView}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={style.scrollView}>
          <View
            style={[
              style.contentContainer,
              {
                minWidth: isWeb ? Breakpoints.md : 'auto',
                maxWidth: Breakpoints.md,
              },
            ]}>
            <View style={style.boxView}>
              {!user?.hasProfileCompleted && (
                <Text style={style.description}>
                  <FormattedMessage id={t('profile.completeMandatoryFields')} />
                </Text>
              )}
              <Text style={style.title}>
                <FormattedMessage id={t('profile.personalInfo')} />
              </Text>
              <Avatar
                image={
                  user?.profileImage
                    ? {
                        uri: `${
                          ApiRoutes.BaseURL
                        }${user?.profileImage?.substring(1)}`,
                      }
                    : 0
                }
                firstName={user?.firstName}
                middleName={user?.middleName}
                lastName={user?.lastName}
                size="large"
                editable
                onIconPress={image => setValue('profileImage', image)}
              />
              <View style={style.nameRowView}>
                <Input
                  control={control as unknown as Control<FieldValues>}
                  name="firstName"
                  label={intl.formatMessage({id: t('profile.firstName')})}
                  containerStyle={style.inputContainer}
                  {...getErrorProps(intl, errors.firstName)}
                  maxLength={30}
                  onSubmitEditing={handleSubmit(data =>
                    onSubmit(data, onSuccess),
                  )}
                />
                <Input
                  control={control as unknown as Control<FieldValues>}
                  name="middleName"
                  label={intl.formatMessage({id: t('profile.middleName')})}
                  containerStyle={style.inputContainer}
                  onSubmitEditing={handleSubmit(data =>
                    onSubmit(data, onSuccess),
                  )}
                  {...getErrorProps(intl, errors.middleName)}
                />
                <Input
                  control={control as unknown as Control<FieldValues>}
                  name="lastName"
                  {...getErrorProps(intl, errors.lastName)}
                  label={intl.formatMessage({id: t('profile.lastName')})}
                  containerStyle={style.inputContainer}
                  maxLength={30}
                  onSubmitEditing={handleSubmit(data =>
                    onSubmit(data, onSuccess),
                  )}
                />
              </View>
              <Input
                control={control as unknown as Control<FieldValues>}
                name="email"
                {...getErrorProps(intl, errors.email)}
                label={intl.formatMessage({id: t('profile.email')})}
                containerStyle={style.inputContainer}
                keyboardType="email-address"
                returnKeyType="done"
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Controller
                control={control}
                name="bloodGroup"
                render={({field: {onChange, value}}) => (
                  <DropDown
                    data={bloodGroups}
                    displayKey="name"
                    //@ts-ignore
                    value={value}
                    onSelectItem={item => {
                      onChange(item.name);
                    }}
                    label={t('profile.bloodGroup')}
                    placeHolder={t('profile.selectBloodGroup')}
                    containerStyle={style.inputContainer}
                  />
                )}
              />

              <Controller
                control={control}
                name="dob"
                render={({field: {onChange, value}}) => (
                  <DatePicker
                    label={t('profile.dateOfBirth')}
                    containerStyle={style.inputContainer}
                    {...getErrorProps(intl, errors.dob)}
                    onChange={onChange}
                    value={value}
                    placeHolder="DD-MM-YYYY"
                    mode="date"
                    maximumDate={new Date()}
                  />
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="mobileNumber"
                {...getErrorProps(intl, errors.mobileNumber)}
                maxLength={10}
                label={intl.formatMessage({id: t('misc.phoneNumber')})}
                containerStyle={style.inputContainer}
                keyboardType="phone-pad"
                returnKeyType="done"
                editable={false}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="whatsAppNumber"
                {...getErrorProps(intl, errors.whatsAppNumber)}
                maxLength={10}
                label={intl.formatMessage({id: t('profile.whatsAppNumber')})}
                containerStyle={style.inputContainer}
                keyboardType="phone-pad"
                returnKeyType="done"
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Controller
                control={control as unknown as Control<FieldValues>}
                name="occupation"
                render={({field: {onChange, value}}) => (
                  <DropDown
                    data={occupations}
                    displayKey="occupationName"
                    label={intl.formatMessage({id: t('profile.occupation')})}
                    containerStyle={style.inputContainer}
                    value={value?.occupationName}
                    onSelectItem={onChange}
                    {...getErrorProps(intl, errors.occupation)}
                  />
                )}
              />
              {occupation && occupation.occupationName === 'Other' && (
                <Controller
                  control={control}
                  name="occupationName"
                  render={() => (
                    <Input
                      control={control as unknown as Control<FieldValues>}
                      name="occupationName"
                      placeholder={intl.formatMessage({
                        id: 'profile.occupationName',
                      })}
                      containerStyle={style.inputContainer}
                      {...getErrorProps(intl, errors.occupation)}
                    />
                  )}
                />
              )}
              <Input
                control={control as unknown as Control<FieldValues>}
                name="gotra"
                {...getErrorProps(intl, errors.gotra)}
                label={intl.formatMessage({id: t('profile.gotra')})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <View style={[style.nameRowView, {marginTop: 0}]}>
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="panth"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={panths}
                      displayKey="panthName"
                      label={intl.formatMessage({id: t('profile.panth')})}
                      containerStyle={style.inputContainer}
                      value={value?.panthName}
                      onSelectItem={onChange}
                      {...getErrorProps(intl, errors.panth?.panthName)}
                    />
                  )}
                />
              </View>
              {panth && panth.panthName === 'Other' && (
                <Controller
                  control={control}
                  name="panthName"
                  render={() => (
                    <Input
                      control={control as unknown as Control<FieldValues>}
                      name="panthName"
                      placeholder={intl.formatMessage({
                        id: t('profile.panthName'),
                      })}
                      {...getErrorProps(intl, errors.panthName)}
                      containerStyle={style.inputContainer}
                      onSubmitEditing={handleSubmit(data =>
                        onSubmit(data, onSuccess),
                      )}
                    />
                  )}
                />
              )}
            </View>
            <View style={style.boxView}>
              <Text style={style.title}>
                <FormattedMessage id={t('profile.contactDetails')} />
              </Text>
              <Input
                control={control as unknown as Control<FieldValues>}
                name="contactAddressLine1"
                {...getErrorProps(intl, errors.contactAddressLine1)}
                label={
                  intl.formatMessage({id: t('profile.addressLine1')}) + '*'
                }
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="contactAddressLine2"
                {...getErrorProps(intl, errors.contactAddressLine2)}
                label={
                  intl.formatMessage({id: t('profile.addressLine2')}) + '*'
                }
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="contactLandmark"
                label={intl.formatMessage({id: 'profile.landmark'})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <View style={style.nameRowView}>
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="contactState"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={states}
                      displayKey="name"
                      label={intl.formatMessage({id: t('profile.state')}) + '*'}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('contactDistrict', undefined);
                        setValue('contactCity', undefined);
                        setValue('contactArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.contactState?.name)}
                    />
                  )}
                />
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="contactDistrict"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={contactDistrictData}
                      displayKey="name"
                      disabled={!contactState}
                      label={
                        intl.formatMessage({id: t('profile.district')}) + '*'
                      }
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('contactCity', undefined);
                        setValue('contactArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.contactDistrict?.name)}
                    />
                  )}
                />
              </View>
              <View style={style.nameRowView}>
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="contactCity"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={contactCitiesData}
                      displayKey="name"
                      disabled={!contactDistrict}
                      label={intl.formatMessage({id: t('profile.city')}) + '*'}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('contactArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.contactCity?.name)}
                    />
                  )}
                />
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="contactArea"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={contactAreasData}
                      displayKey="name"
                      disabled={!contactCity}
                      label={intl.formatMessage({id: t('profile.area')}) + '*'}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={onChange}
                      {...getErrorProps(intl, errors.contactArea?.name)}
                    />
                  )}
                />
              </View>
            </View>
            <View style={style.boxView}>
              <Text style={style.title}>
                <FormattedMessage id={t('profile.officeDetails')} />
              </Text>
              <Input
                control={control as unknown as Control<FieldValues>}
                name="businessName"
                {...getErrorProps(intl, errors.businessName)}
                label={intl.formatMessage({id: t('profile.businessName')})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="officeAddressLine1"
                {...getErrorProps(intl, errors.officeAddressLine1)}
                label={intl.formatMessage({id: t('profile.addressLine1')})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="officeAddressLine2"
                {...getErrorProps(intl, errors.officeAddressLine2)}
                label={intl.formatMessage({id: t('profile.addressLine2')})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <Input
                control={control as unknown as Control<FieldValues>}
                name="officeLandmark"
                label={intl.formatMessage({id: t('profile.landmark')})}
                containerStyle={style.inputContainer}
                onSubmitEditing={handleSubmit(data =>
                  onSubmit(data, onSuccess),
                )}
              />
              <View style={style.nameRowView}>
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="officeState"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={states}
                      displayKey="name"
                      label={intl.formatMessage({id: t('profile.state')})}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('officeDistrict', undefined);
                        setValue('officeCity', undefined);
                        setValue('officeArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.officeState)}
                      clearValue={() => {
                        onChange(undefined);
                        setValue('officeDistrict', undefined);
                        setValue('officeCity', undefined);
                        setValue('officeArea', undefined);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="officeDistrict"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={officeDistrictData}
                      displayKey="name"
                      disabled={!officeState}
                      label={intl.formatMessage({id: t('profile.district')})}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('officeCity', undefined);
                        setValue('officeArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.officeDistrict)}
                      clearValue={() => {
                        onChange(undefined);
                        setValue('officeCity', undefined);
                        setValue('officeArea', undefined);
                      }}
                    />
                  )}
                />
              </View>
              <View style={style.nameRowView}>
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="officeCity"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={officeCitiesData}
                      displayKey="name"
                      disabled={!officeDistrict}
                      label={intl.formatMessage({id: t('profile.city')})}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={v => {
                        onChange(v);
                        setValue('officeArea', undefined);
                      }}
                      {...getErrorProps(intl, errors.officeCity)}
                      clearValue={() => {
                        onChange(undefined);
                        setValue('officeArea', undefined);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control as unknown as Control<FieldValues>}
                  name="officeArea"
                  render={({field: {onChange, value}}) => (
                    <DropDown
                      data={officeAreasData}
                      displayKey="name"
                      disabled={!officeCity}
                      label={intl.formatMessage({id: t('profile.area')})}
                      containerStyle={style.inputContainer}
                      value={value?.name}
                      onSelectItem={onChange}
                      {...getErrorProps(intl, errors.officeArea)}
                      clearValue={() => onChange(undefined)}
                    />
                  )}
                />
              </View>
            </View>
            <Button
              disabled={isLoading}
              style={style.submitButtonContainer}
              name={<FormattedMessage id={t('misc.submit')} />}
              onPress={handleSubmit(data => onSubmit(data, onSuccess))}
            />
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default EditProfile;
