import {useForm} from 'react-hook-form';
import {
  AddFamilyMemberController,
  AddFamilyMemberControllerProps,
  AddFamilyMemberFormFields,
  onSubmit,
} from './AddFamilyMember.types';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {AddFamilyMember, SignUp} from '../../../services/api/response/user';
import {useIntl} from 'react-intl';
import {familyRelation, gender, maritalStatus} from '../../../utils/json';
import {useEffect} from 'react';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import {fetchAllOccupation} from '../../../services/redux/app/app.action';
import moment from 'moment';
import {addFamilyMemberFormSchema} from '../../../validationSchemas/familyMembers';
import {yupResolver} from '@hookform/resolvers/yup';

export const useAddFamilyMemberController = (
  props: AddFamilyMemberControllerProps,
): AddFamilyMemberController => {
  const dispatch = useAppDispatch();
  const {openModal} = useModal();
  const {user} = useAppSelector(state => state.userReducer);
  const intl = useIntl();
  const {occupations} = useAppSelector(state => state.appReducer);

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm<AddFamilyMemberFormFields>({
    resolver: yupResolver(addFamilyMemberFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: props.member?.name,
      relationShip: props.member
        ? familyRelation.find(x => x.name === props.member?.relationship)
        : undefined,
      dob: props.member?.dob ? new Date(props.member?.dob) : undefined,
      mobileNumber: props.member?.mobileNo,
      occupation: props.member
        ? occupations?.find(x => x.occupationName === props.member?.practice) ??
          occupations?.[occupations?.length]
        : undefined,
      occupationName: props.member ? props.member?.practice : undefined,
      gender: gender.find(x => x.id === props.member?.gender),
      maritalStatus: maritalStatus.find(
        x => x.id === props.member?.maritalStatus,
      ),
      bloodGroup: props.member?.bloodGroup,
    },
  });

  const {isLoading, bloodGroup, occupation} = watch();

  useEffect(() => {
    if (bloodGroup) {
      setValue('bloodGroup', bloodGroup?.toUpperCase());
    }
  }, [bloodGroup, setValue]);

  useEffect(() => {
    if (occupations?.length) {
      setValue(
        'occupation',
        props.member
          ? occupations?.find(
              x => x.occupationName === props.member?.practice,
            ) ?? occupations?.[occupations?.length]
          : undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupations?.length, setValue, props.member?.practice]);

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchAllOccupation());
  }, [dispatch]);

  const registerFamilyMember = async (data: any) => {
    try {
      setValue('isLoading', true);
      await axios.post<SignUp>(ApiRoutes.SignUp, {
        ProfilePicture: data?.profileImage || '',
        FirstName: data.name || '',
        MiddleName: data.middleName || '',
        LastName: data.lastName || '',
        Email: data.email ?? '',
        MobileNo: data.mobileNumber,
        WhatsAppNo: data.whatsAppNumber || '',
        BloodGroup: data.bloodGroup || '',
        DOB: moment(data.dob).format('DD MM YYYY'),
        RequestById: 5,
        IsAdmin: 0,
      });
      setValue('isLoading', false);
    } catch (e) {
      setValue('isLoading', false);
      console.error(e);
    }
  };

  const onSubmit = async ({data, onSuccess}: onSubmit) => {
    try {
      setValue('isLoading', true);
      console.log('on submitted api call 1');
      const {data: response} = await axios.post<AddFamilyMember>(
        ApiRoutes.AddFamilymember,
        {
          Id: props.member?.id || null,
          UId: user?.UId,
          ApiSecret: user?.ApiSecret,
          Name: data.name,
          Relationship: data.relationShip?.name,
          MobileNo: data.mobileNumber,
          DOB: moment(data.dob).format('DD MM YYYY'),
          OccupationId:
            data.occupation?.occupationName === 'Other'
              ? null
              : data.occupation?.id,
          Occupation:
            data.occupation?.occupationName === 'Other'
              ? data.occupationName
              : data.occupation?.occupationName,
          BloodGroup: data.bloodGroup,
          MaritalStatus: data.maritalStatus.id,
          Gender: data.gender.id,
          ProfilePicture: data?.profileImage ?? null,
        },
      );

      console.log(response, 'response coming ');
      if (response.isSaved) {
        await registerFamilyMember(data);
        onSuccess && onSuccess();
        openModal({
          title: intl.formatMessage({id: 'misc.success'}),
          message: response.message,
        });
        return;
      } else {
        openModal({
          title: intl.formatMessage({id: 'familyMember.addFamilyFailed'}),
          message: response.message,
        });
        return;
      }
    } catch (e) {
      console.log('error coming here', e);
      setValue('isLoading', false);
      // console.error(e);
    }
  };

  return {
    control,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    setValue,
    occupation,
    occupations,
  };
};
