import {StyleSheet} from 'react-native';
import {Colors, FontFamily} from '../../utils/theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
    color: Colors.maroon,
  },
  content: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'justify',
    fontFamily: FontFamily.PoppinsRegular,
  },
  readMore: {
    color: 'blue',
    marginTop: 10,
  },
  pdfContainer: {
    flex: 1,
    marginTop: 16,
  },
  pdf: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
