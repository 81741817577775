import {StyleSheet} from 'react-native';
import {Colors} from '../../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: Colors.white,
  },
  keyBoardView: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  contentContainer: {
    flex: 1,
    paddingVertical: 30,
    gap: 15,
    paddingHorizontal: 20,
  },
  submitButtonContainer: {
    marginTop: 30,
  },
});
