type FlattenedObject = {[key: string]: string};

const flattenObject = (
  obj: object,
  parent: string | null = null,
  res: FlattenedObject = {},
): FlattenedObject => {
  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? `${parent}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      flattenObject(value, propName, res);
    } else {
      res[propName] = String(value);
    }
  }

  return res;
};

export default flattenObject;
