import {View, Text, TouchableOpacity} from 'react-native';
import React, {useRef} from 'react';
import {DatePickerProps} from './DatePicker.types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {style} from './DatePicker.styles';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import moment from 'moment';
import {Calendar} from '../../assets/svg';
import {Colors} from '../../utils/theme';

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  containerStyle,
  disabled,
  error,
  helperText,
  labelStyle,
  onChange,
  placeHolder,
  value,
}) => {
  const datePickerRef = useRef<ReactDatePicker>(null);

  const handleDatePickerClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const onChangeDate = (date: null | Date): void => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(false);
    }
    if (date) {
      onChange && onChange(date);
    }
  };
  return (
    <View style={[containerStyle]}>
      {label && <Text style={[style.labelText, labelStyle]}>{label}</Text>}
      <ReactDatePicker
        ref={datePickerRef}
        selected={value ?? new Date()}
        disabled={disabled}
        customInput={
          <TouchableOpacity
            disabled={disabled}
            activeOpacity={0.8}
            onPress={handleDatePickerClick}
            style={[
              style.rowView,
              {
                opacity: disabled ? 0.5 : 1,
                borderColor: error ? Colors.maroon : undefined,
                borderWidth: error ? 1 : undefined,
              },
            ]}>
            <Text style={style.valueText} numberOfLines={1}>
              {value ? moment(value).format('DD-MM-YYYY') : placeHolder}
            </Text>
            <Calendar fill={Colors.lightBlack} />
          </TouchableOpacity>
        }
        onChange={onChangeDate}
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        adjustDateOnChange
        maxDate={new Date()}
        portalId="root"
      />
      <ErrorMessage error={error} helperText={helperText} />
    </View>
  );
};

export default DatePicker;
