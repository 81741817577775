export enum Colors {
  white = 'white',
  black = 'black',
  shadow = '#d7d7d9',
  blackA100 = '#272828',
  lightBlack = '#585858',
  Secondary = '#51ce00',
  maroon = '#800000',
  background = '#F3F3F3',
  primary = '#8d002d',
  blue = '#0000FF',
  primaryBackground = 'rgba(226,31,35,0.1)',
  border = '#d7d9d9',
  lightGray = '#A8A8A8',
  lightBackground = 'rgba(243, 243, 243,0.6)',
}

export enum FontSize {
  h1 = 26,
  h2 = 24,
  h3 = 22,
  h4 = 20,
  h5 = 18,
  h6 = 16,
  bodyBig = 14,
  body = 12,
  bodySmall = 10,
}

export enum FontFamily {
  PoppinsSemiBold = 'Poppins-SemiBold',
  PoppinsBold = 'Poppins-Bold',
  PoppinsRegular = 'Poppins-Regular',
  PoppinsMedium = 'Poppins-Medium',
  PoppinsItalic = 'Poppins-Italic',
  PoppinsThin = 'Poppins-Thin',
}

export enum Breakpoints {
  xs = 0,
  sm = 600,
  md = 900,
  lg = 1200,
  xl = 1536,
}
export enum ImageOptions {
  UploadImage = 'Upload Image',
  Cancel = 'Cancel',
  ChooseImage = 'Choose from Library',
  CaptureImage = 'Capture Image',
  Data = 'data',
  Base64 = 'base64',
}
