import {StyleSheet, Platform} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  bannerView: {
    marginBottom: 30,
    backgroundColor: Colors.background,
    borderRadius: 15,
  },
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: 20,
    paddingVertical: 20,
    paddingBottom: 100,
  },
  flatlistStyle: {
    paddingBottom: 30,
    paddingTop: 15,
    paddingHorizontal: 16,
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingBottom: 30,
  },
  columnWrapperStyle: {
    paddingVertical: 15,
    gap: 15,
  },
  dotActiveStyle: {
    backgroundColor: Colors.primary,
  },
  dotInActiveStyle: {
    backgroundColor: Colors.white,
    display: 'none',
  },
  aboutUs: {
    fontSize: FontSize.h3,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsBold,
    marginBottom: 15,
  },
  semiTitle: {
    fontSize: FontSize.h4,
    color: Colors.primary,
    fontFamily: FontFamily.PoppinsSemiBold,
  },
  title: {
    fontSize: FontSize.h5,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsMedium,
  },
  description: {
    fontSize: FontSize.h6,
    color: Colors.blackA100,
    fontFamily: FontFamily.PoppinsRegular,
    lineHeight: 30,
  },
  arrowLeft: {
    height: 50,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: 10,
    transform: [{translateY: -12}],
    zIndex: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 200,
    padding: 10,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.primary,
  },
  arrowRight: {
    height: 50,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: [{translateY: -12}],
    zIndex: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 200,
    padding: 10,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.primary,
  },
  swipeArrow: {
    fontSize: Platform.OS === 'web' ? 20 : 10,
    fontWeight: '500',
    color: Colors.primary,
  },
  iconView: {
    // borderWidth: 2,
    // borderColor: 'black',
    // borderStyle: 'solid',
    margin: 20,
  },
});
