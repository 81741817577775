import * as yup from 'yup';
import {emailRegex, nameRegex, phoneRegex} from '../utils/validation/consts';
import {AddressObject} from '../services/api/response/app';
import {ContactUsFields} from '../screens/ContactUs/ContactUs.types';

yup.setLocale({
  mixed: {
    required: ({path}) => `contactUs.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `contactUs.errors.${path}.matches`,
  },
});

const objectSchema = yup.object<AddressObject>({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const contactUsFormSchema = yup
  .object<Record<keyof ContactUsFields, yup.AnySchema>>({
    phoneNumber: yup.string().trim().required().matches(phoneRegex),
    name: yup.string().trim().required().matches(nameRegex),
    email: yup.string().trim().required().matches(emailRegex),
    category: objectSchema.required(),
    message: yup.string().trim().required(),
  })
  .required();
