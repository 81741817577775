import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: Colors.primary,
  },
  text: {
    fontSize: FontSize.h6,
    color: Colors.white,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsMedium,
  },
});
