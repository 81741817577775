import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {LoadingState, MatrimonialProfileController} from './ProfileList.types';
import {MatrimonialProfileResponse} from '../../../services/api/response/matrimonial';
import {matrimonialProfilesAction} from '../../../services/redux/matrimonial/matrimonial.action';

export const useProfileListController = (): MatrimonialProfileController => {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector(state => state.userReducer);
  const {matrimonialProfiles} = useAppSelector(
    state => state.matrimonialReducer,
  );
  const [loading, setLoading] = useState<LoadingState>({
    fetching: false,
    refreshing: false,
    dataTotalCount: 0,
    isLoadMore: false,
  });

  const fetchMatrimonialProfiles = async (
    skipSize: number,
    isRefreshing?: boolean,
    isLoadMore?: boolean,
  ) => {
    try {
      setLoading(prv => ({
        ...prv,
        isLoadMore: !!isLoadMore,
        fetching: matrimonialProfiles?.length === 0,
        refreshing: !!isRefreshing,
      }));
      const {data} = await axios.post<MatrimonialProfileResponse>(
        ApiRoutes.MatrimonialProfiles,
        {
          UId: user?.UId,
          ApiSecret: user?.ApiSecret,
          IsVerify: user?.IsVerified,
          SkipSize: skipSize,
          PageSize: 20,
        },
      );
      if (data.isAuthorisedUser) {
        dispatch(
          matrimonialProfilesAction(
            skipSize === 0
              ? data.profiles
              : [...matrimonialProfiles, ...data.profiles],
          ),
        );
      }
      setLoading({
        dataTotalCount: data.totalCount,
        fetching: false,
        refreshing: false,
        isLoadMore: false,
      });
    } catch (e) {
      console.error(e);
      setLoading(prv => ({
        ...prv,
        fetching: false,
        refreshing: false,
        isLoadMore: false,
      }));
    }
  };

  useEffect(() => {
    fetchMatrimonialProfiles(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {loading, fetchMatrimonialProfiles, matrimonialProfiles};
};
