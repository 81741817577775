import {View, FlatList, RefreshControl, Text} from 'react-native';
import React, {useEffect, useState} from 'react';
import {style} from './BoardMembers.styles';
import {EmptyComponent, MemberInfo, Spinner} from '../../../components';
import {useBoardMembersController} from './BoardMembers.controller';
import {ApiRoutes} from '../../../services/api/path';
import {FormattedMessage} from 'react-intl';
import {CommunityMembers} from '../../../assets/svg';
import {BoardMember} from '../../../services/api/response/community';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../../utils/routeNames';
import {useTranslation} from 'react-i18next';

const AllMembers: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {boardMembers, loading, fetchBoardMembers} =
    useBoardMembersController();
  const [groupedMembers, setGroupedMembers] = useState<{
    [key: string]: BoardMember[];
  }>({});

  useEffect(() => {
    const grouped: {[key: string]: BoardMember[]} = {};
    boardMembers.forEach((member: BoardMember) => {
      if (!grouped[member.position]) {
        grouped[member.position] = [];
      }
      grouped[member.position].push(member);
    });
    Object.keys(grouped).forEach(position => {
      grouped[position].sort((a, b) => a.name.localeCompare(b.name));
    });
    setGroupedMembers(grouped);
  }, [boardMembers]);

  const customOrder = [
    'President',
    'Vice President',
    'Secretary',
    'Treasurer',
    'Interim Auditor',
    'Imm. Former President',
  ];

  const onProfilePress = (item: BoardMember): void => {
    navigate(NavigationNames.ProfileDetail, {
      state: {
        profile: item,
      },
    });
  };

  return (
    <View style={style.container}>
      <Spinner visible={loading.fetching} />
      {customOrder.map((position: string) => (
        <View key={position} style={style.memberWrapper}>
          <Text style={style.positionTitle}>{t(`${position}`)}</Text>
          <FlatList
            data={groupedMembers[position] || []}
            keyExtractor={item => item.loginId.toString()}
            numColumns={5}
            renderItem={({item}) => (
              <MemberInfo
                {...item}
                image={
                  item?.image
                    ? {uri: `${ApiRoutes.BaseURL}${item.image.substring(1)}`}
                    : 0
                }
                onPress={() => onProfilePress(item)}
              />
            )}
            columnWrapperStyle={style.columnWrapperStyle}
            contentContainerStyle={style.contentContainerStyle}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={loading.refreshing}
                onRefresh={() => fetchBoardMembers(true)}
              />
            }
            ListEmptyComponent={() => (
              <EmptyComponent
                icon={<CommunityMembers width={250} height={250} />}
                isFetching={loading.fetching}
                message={<FormattedMessage id="community.noBoardMembers" />}
              />
            )}
          />
        </View>
      ))}
      {Object.keys(groupedMembers)
        .filter(position => !customOrder.includes(position))
        .map(position => (
          <View key={position} style={style.memberWrapper}>
            <Text style={style.positionTitle}>{t(`${position}`)}</Text>
            <FlatList
              data={groupedMembers[position]}
              keyExtractor={item => item.loginId.toString()}
              numColumns={5}
              renderItem={({item}) => (
                <MemberInfo
                  {...item}
                  image={
                    item?.image
                      ? {uri: `${ApiRoutes.BaseURL}${item.image.substring(1)}`}
                      : 0
                  }
                  onPress={() => onProfilePress(item)}
                />
              )}
              columnWrapperStyle={style.columnWrapperStyle}
              // contentContainerStyle={style.contentContainerStyle}
              showsVerticalScrollIndicator={false}
              refreshControl={
                <RefreshControl
                  refreshing={loading.refreshing}
                  onRefresh={() => fetchBoardMembers(true)}
                />
              }
              ListEmptyComponent={() => (
                <EmptyComponent
                  icon={<CommunityMembers width={250} height={250} />}
                  isFetching={loading.fetching}
                  message={<FormattedMessage id="community.noBoardMembers" />}
                />
              )}
            />
          </View>
        ))}
    </View>
  );
};

export default AllMembers;
