import {
  View,
  FlatList,
  RefreshControl,
  ActivityIndicator,
  Platform,
} from 'react-native';
import React from 'react';
import {useEventsController} from './Events.controller';
import {EmptyComponent, EventInfo} from '../../../components';
import {style} from './Events.styles';
import {Breakpoints, Colors} from '../../../utils/theme';
import {FormattedMessage} from 'react-intl';
import {Event} from '../../../assets/svg';
import {useDimensions} from '../../../hooks/useDimensions';

const Events: React.FC = () => {
  const {states, fetchEvents} = useEventsController();
  const {windowWidth} = useDimensions();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  return (
    <View style={style.container}>
      {states.fetching ? (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      ) : (
        <FlatList
          key={String(isWeb)}
          data={states.events}
          numColumns={isWeb ? 2 : 1}
          keyExtractor={item => item.id.toString()}
          renderItem={({item}) => <EventInfo {...item} />}
          contentContainerStyle={style.contentContainerStyle}
          columnWrapperStyle={isWeb ? style.columnWrapperStyle : undefined}
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              refreshing={states.refreshing}
              onRefresh={() => fetchEvents(0, true)}
            />
          }
          onEndReachedThreshold={0.5}
          onEndReached={({distanceFromEnd}) => {
            if (
              distanceFromEnd > 10 &&
              states.events?.length < states?.eventTotalCounts
            ) {
              fetchEvents(states.events?.length, false, true);
            }
          }}
          ListFooterComponent={() => (
            <View>
              {states.isLoadMore && (
                <ActivityIndicator size="large" color={Colors.primary} />
              )}
            </View>
          )}
          ListEmptyComponent={() => (
            <EmptyComponent
              icon={<Event width={250} height={250} />}
              isFetching={states.fetching}
              message={<FormattedMessage id="event.noEvents" />}
            />
          )}
        />
      )}
    </View>
  );
};

export default Events;
