import * as yup from 'yup';
import {FamilyMembers} from '../services/api/response/user';
import {AddProfileFormFields} from '../screens/Matrimonial/AddProfile/AddProfile.types';

yup.setLocale({
  mixed: {
    required: ({path}) => `matrimonial.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `matrimonial.errors.${path}.matches`,
  },
});

const familyMemberSchemaRequired = yup.object<FamilyMembers>({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const addMatrimonialFormSchema = yup
  .object<Record<keyof AddProfileFormFields, yup.AnySchema>>({
    familyMember: familyMemberSchemaRequired.required(),
    biodata: yup.string(),
  })
  .required();
