import {View, Text, Modal, TouchableWithoutFeedback} from 'react-native';
import React from 'react';
import {CenterLogo} from '../../assets/svg';
import {style} from './Login.styles';
import {Button} from '../../components';
import {NewUserModelProps} from './Login.types';
import {t} from 'i18next';

const NewUserModal: React.FC<NewUserModelProps> = ({
  setVisible,
  visiable,
  message = t('signUp.newSignUpMessage'),
  ...restProps
}) => {
  return (
    <Modal
      visible={visiable}
      statusBarTranslucent={true}
      transparent
      animationType="slide"
      {...restProps}>
      <TouchableWithoutFeedback style={{flex: 1}} onPress={setVisible}>
        <View style={style.modalcontainer}>
          <TouchableWithoutFeedback>
            <View style={style.modalSecView}>
              <CenterLogo style={style.modalLogoStyle} />
              <Text style={style.titleText}>{message}</Text>
              <Button
                style={style.buttonStyle}
                name={'Done'}
                onPress={setVisible}
              />
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default NewUserModal;
