import React, {useEffect} from 'react';
import {Modal, View, Button, Platform} from 'react-native';
import {styles} from './changePasswordstyles';
import InputWithControl from '../Input/InputWithControl';
import {getErrorProps} from '../../utils/validation/validationFunctions';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints, Colors} from '../../utils/theme';
import {Control, FieldValues} from 'react-hook-form';
import {useChangePasswordController} from './changePasswordController';
import {useIntl} from 'react-intl';
import {useTranslation} from 'react-i18next';
import {changePasswordModalProps} from './changePassword.types';
import {userLogoutAction} from '../../services/redux/user/user.action';
import {useAppDispatch} from '../../services/redux/hook';
import {NavigationNames} from '../../utils/routeNames';
import {useNavigation} from '@react-navigation/native';
import Spinner from '../Spinner/Spinner';

const ChangePasswordModal: React.FC<changePasswordModalProps> = ({
  visible,
  onClose,
}) => {
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const intl = useIntl();
  const {t} = useTranslation();
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const {control, errors, handleSubmit, onSubmit, reset, isLoading} =
    useChangePasswordController();

  const onSuccess = (): void => {
    onClose();
    dispatch(userLogoutAction());
    //@ts-ignore
    navigation.navigate(NavigationNames.Login);
  };

  const onCancel = (): void => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible, reset]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}>
      <View style={styles.modalContainer}>
        <Spinner visible={isLoading} />
        <View style={styles.modalContent}>
          <InputWithControl
            control={control as unknown as Control<FieldValues>}
            name="oldPassword"
            autoFocus={isWeb}
            {...getErrorProps(intl, errors.oldPassword)}
            label={t('user.oldPassword')}
            containerStyle={styles.inputContainer}
            keyboardType="default"
            returnKeyType="done"
            secureTextEntry={true}
            onSubmitEditing={handleSubmit(data =>
              onSubmit(data, onSuccess, onClose),
            )}
          />
          <InputWithControl
            control={control as unknown as Control<FieldValues>}
            name="newPassword"
            {...getErrorProps(intl, errors.newPassword)}
            label={t('user.newPassword')}
            containerStyle={styles.inputContainer}
            keyboardType="default"
            returnKeyType="done"
            secureTextEntry={true}
            onSubmitEditing={handleSubmit(data =>
              onSubmit(data, onSuccess, onClose),
            )}
          />
          <InputWithControl
            control={control as unknown as Control<FieldValues>}
            name="confirmPassword"
            {...getErrorProps(intl, errors.confirmPassword)}
            label={t('user.confirmPassword')}
            containerStyle={styles.inputContainer}
            keyboardType="default"
            returnKeyType="done"
            secureTextEntry={true}
            onSubmitEditing={handleSubmit(data =>
              onSubmit(data, onSuccess, onClose),
            )}
          />
          <View style={styles.buttonContainer}>
            <Button title="Cancel" onPress={onCancel} color="gray" />
            <Button
              title="Submit"
              color={Colors.primary}
              onPress={handleSubmit(data => {
                onSubmit(data, onSuccess, onClose);
              })}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ChangePasswordModal;
