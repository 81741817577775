import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const Email = (props: SvgProps) => (
  <Svg
    width={69.333}
    height={69.333}
    viewBox="0 0 52 52"
    {...props}
    fill="#BB001B">
    <Path d="M2 10c-1.8 1.8-2 3.3-2 16 0 19.4-2 18 26 18s26 1.4 26-18S54 8 26 8C5.3 8 3.9 8.1 2 10zm35.3 10.7c9.8-5.1 10.7-5.4 7.3-2.5-2.2 1.8-5.1 4.3-6.5 5.5l-2.6 2.2 6 6.2c6.5 6.7 6.1 6.8-3.2.1L32 27.8 29 30l-3 2.1-3-2.1-3-2.1-6.2 4.4c-9.4 6.6-9.9 6.6-3.3-.3l6-6.2-3.9-3.1c-9.1-7.4-8.8-7.7 2.2-2L26 26.5l11.3-5.8z" />
  </Svg>
);
export default Email;
