import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../../utils/theme';

const useDynamicStyles = () => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.white,
    },
    image: {
      alignSelf: 'center',
    },
    editButtonText: {
      fontSize: FontSize.h5,
      fontFamily: FontFamily.PoppinsSemiBold,
      color: Colors.primary,
    },
    scrollView: {
      flexGrow: 1,
      gap: 15,
      paddingBottom: 30,
      paddingHorizontal: 20,
      paddingTop: 20,
    },
    nameText: {
      fontSize: FontSize.h6,
      fontFamily: FontFamily.PoppinsMedium,
      color: Colors.blackA100,
      textAlign: 'center',
      marginVertical: 10,
      textTransform: 'capitalize',
    },
    title: {
      fontSize: FontSize.h4,
      fontFamily: FontFamily.PoppinsSemiBold,
      color: Colors.primary,
      marginVertical: 10,
    },
    profileTile: {
      marginTop: 10,
      color: Colors.lightGray,
      // borderColor: 'black',
      // borderWidth: 2,
      // borderStyle: 'solid',
    },
    rowView: {flexDirection: 'row', gap: 20},
    boxView: {
      flex: 1,
    },
  });
};

export default useDynamicStyles;
