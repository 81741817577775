import React, {useState} from 'react';
import {FlatList, Text, TouchableOpacity, View} from 'react-native';
import {DropDownProps, RenderItemProps} from './DropDown.types';
import {style} from './DropDown.styles';
import {CloseRound, NextArrow} from '../../assets/svg';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import {Colors} from '../../utils/theme';

const DropDown: React.FC<DropDownProps> = ({
  data,
  displayKey,
  onSelectItem,
  value,
  containerStyle,
  error,
  inputViewStyle,
  label,
  labelStyle,
  helperText,
  disabled,
  clearValue,
  placeHolder,
}) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);

  const RenderFlatlistItem: React.FC<RenderItemProps> = ({item, index}) => {
    return (
      <TouchableOpacity
        style={[
          style.renderRowView,
          {borderBottomWidth: data?.length === index + 1 ? 0 : 1},
        ]}
        activeOpacity={0.7}
        onPress={() => {
          onSelectItem && onSelectItem(item);
          setOpenDropDown(!openDropDown);
        }}>
        <Text style={style.rowText}>{item[displayKey]}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <View style={[containerStyle]}>
      {label && <Text style={[style.labelText, labelStyle]}>{label}</Text>}
      <TouchableOpacity
        disabled={disabled}
        activeOpacity={0.8}
        onPress={() => setOpenDropDown(!openDropDown)}
        style={[
          style.rowView,
          {
            opacity: disabled ? 0.5 : 1,
            borderColor: error ? Colors.maroon : undefined,
            borderWidth: error ? 1 : undefined,
          },
          inputViewStyle,
        ]}>
        <Text style={style.valueText} numberOfLines={1}>
          {value ?? placeHolder}
        </Text>
        <TouchableOpacity
          style={style.clearButton}
          activeOpacity={0.8}
          disabled={!clearValue || !value}
          onPress={clearValue}>
          {clearValue && value ? (
            <CloseRound width={25} height={25} fill={Colors.lightGray} />
          ) : (
            <NextArrow
              width={15}
              height={15}
              style={[
                style.arrowButton,
                {
                  transform: [{rotate: openDropDown ? '-90deg' : '90deg'}],
                },
              ]}
            />
          )}
        </TouchableOpacity>
      </TouchableOpacity>
      {openDropDown && (
        <FlatList
          data={data}
          style={style.flatListStyle}
          nestedScrollEnabled
          keyboardShouldPersistTaps="handled"
          renderItem={({item, index}) => (
            <RenderFlatlistItem item={item} index={index} />
          )}
        />
      )}
      <ErrorMessage error={error} helperText={helperText} />
    </View>
  );
};

export default DropDown;
