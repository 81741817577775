import {AppRegistry} from 'react-native';
import App from './App';
import './assets/css/fonts.css';
AppRegistry.registerComponent(
  {
    name: 'SamasthRajasthan',
    displayName: 'SSRJS',
  },
  () => App,
);

AppRegistry.runApplication(
  {
    name: 'SamasthRajasthan',
    displayName: 'SSRJS',
  },
  {
    rootTag: document.getElementById('root'),
  },
);
