import {useAppSelector} from '../../../services/redux/hook';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {EventFormState, EventsController} from './Events.types';
import {EventResponse} from '../../../services/api/response/events';
import {useForm} from 'react-hook-form';
import {useEffect} from 'react';

export const useEventsController = (): EventsController => {
  const {user} = useAppSelector(state => state.userReducer);
  const {watch, setValue} = useForm<EventFormState>({
    mode: 'onSubmit',
    defaultValues: {
      events: [],
      fetching: true,
      isLoadMore: false,
      refreshing: false,
    },
  });
  const states = watch();

  const fetchEvents = async (
    skipSize: number,
    isRefreshing?: boolean,
    isLoadMore?: boolean,
  ) => {
    try {
      setValue('refreshing', !!isRefreshing);
      setValue('isLoadMore', !!isLoadMore);
      const {data} = await axios.post<EventResponse>(ApiRoutes.Events, {
        UId: user?.uId,
        ApiSecret: user?.apiSecret,
        SkipSize: skipSize,
        PageSize: 20,
      });
      setValue('events', data.profiles);
      setValue('fetching', false);
      setValue('refreshing', false);
      setValue('isLoadMore', false);
      setValue('eventTotalCounts', data.totalCount);
    } catch (e) {
      console.error(e);
      setValue('fetching', false);
      setValue('refreshing', false);
      setValue('isLoadMore', false);
    }
  };

  useEffect(() => {
    fetchEvents(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {states, fetchEvents};
};
