import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    zIndex: 1,
  },
  webView: {
    flex: 1,
    width: 500,
    height: 500,
    backgroundColor: Colors.black,
  },
  webContainer: {
    flex: 1,
    borderRadius: 5,
    paddingRight: 10,
  },
  webText: {
    color: Colors.primary,
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsMedium,
  },
  pdfContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
