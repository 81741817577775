import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  title: {
    color: Colors.lightGray,
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsRegular,
    textAlign: 'left',
    flex: 1,
    maxWidth: '50%',
  },
  value: {
    color: Colors.blackA100,
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsMedium,
    flex: 1,
    maxWidth: '100%',
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    maxWidth: '50%',
  },
  iconContainer: {
    marginRight: 8,
  },
});
