import React, {PropsWithChildren} from 'react';
import {IntlProvider} from 'react-intl';
import AppLocale from '../AppLocale';
import {ESupportedLanguages} from '../intl.config';

const IntlProviderWrapper: React.FC<PropsWithChildren> = ({children}) => {
  const currentLang = ESupportedLanguages.English;
  return (
    <IntlProvider
      locale={currentLang}
      messages={AppLocale[currentLang]}
      defaultLocale={ESupportedLanguages.English}>
      {children}
    </IntlProvider>
  );
};

export default IntlProviderWrapper;
