import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  errorMessage: {
    fontSize: FontSize.body,
    color: Colors.maroon,
    fontFamily: FontFamily.PoppinsRegular,
    marginTop: 10,
  },
});
