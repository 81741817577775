import * as yup from 'yup';
import {ForgotPasswordFormFields} from '../screens/ForgotPassword/ForgotPassword.types';
import {phoneRegex} from '../utils/validation/consts';

yup.setLocale({
  mixed: {
    required: ({path}) => `profile.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `profile.errors.${path}.matches`,
  },
});

export const forgotPasswordFormSchema = yup
  .object<Record<keyof ForgotPasswordFormFields, yup.AnySchema>>({
    mobileNumber: yup.string().required().matches(phoneRegex),
  })
  .required();
