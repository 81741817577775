import * as React from 'react';
import Svg, {G, Path, SvgProps} from 'react-native-svg';
import {Colors} from '../../../utils/theme';

const Clock = (props: SvgProps) => {
  return (
    <Svg width={15.75} height={15.75} {...props}>
      <G
        fill="none"
        stroke={Colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        {...props}>
        <Path
          data-name="Path 3160"
          d="M13.125 6.875a6.25 6.25 0 11-6.25-6.25 6.25 6.25 0 016.25 6.25z"
        />
        <Path data-name="Path 3161" d="M6.875 3.125v3.75l2.5 1.25" />
      </G>
    </Svg>
  );
};

export default Clock;
