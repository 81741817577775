// import React, {useState} from 'react';
// import {
//   Image,
//   Linking,
//   Platform,
//   TouchableOpacity,
//   View,
//   Text,
//   Pressable,
// } from 'react-native';
// import {style} from './Header.styles';
// import {Logo} from '../../assets/svg';
// import Button from '../Button/Button';
// import {FormattedMessage} from 'react-intl';
// import {useNavigate} from 'react-router-dom';
// import {NavigationNames} from '../../utils/routeNames';
// import useDimensionsHook from '../../hooks/useDimensions';
// import {Breakpoints} from '../../utils/theme';
// import {apple, googlePlay, languageIcon} from '../../assets/png';
// import {SupportURLs} from '../../services/api/path';
// import {useModal} from '../../setup/providers/modalContext/ModalContext';
// import i18next from '../../services/i18next';
// import {useTranslation} from 'react-i18next';

// const LandingPageHeader: React.FC = () => {
//   const {openModal} = useModal();
//   const navigate = useNavigate();
//   const {t} = useTranslation();
//   const {windowWidth} = useDimensionsHook();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
//   const isSmallScreen = windowWidth < 600;

//   const openPlaystore = async () => {
//     const supported = await Linking.canOpenURL(SupportURLs.PlayStoreApp);
//     supported
//       ? await Linking.openURL(SupportURLs.PlayStoreApp)
//       : openModal({message: t('login.urlDoesNotSupport')});
//   };

//   const openAppStore = async () => {
//     const supported = await Linking.canOpenURL(SupportURLs.AppleStore);
//     supported
//       ? await Linking.openURL(SupportURLs.AppleStore)
//       : openModal({message: t('login.urlDoesNotSupport')});
//   };

//   const changeLang = (lng: string) => i18next.changeLanguage(lng);

//   return (
//     <View
//       style={[style.landingContainer, {paddingRight: isWeb ? '10%' : '16%'}]}>
//       <View style={style.logoContainer}>
//         <Logo height={80} width={80} style={style.logoStyle} />
//         <Text  style={[style.logoText, {fontSize: getFontSize()}]}>श्री समस्त राजस्थान जैन समाज, वडोदरा</Text>
//       </View>

//       {isSmallScreen ? (
//         <Pressable onPress={() => setIsMenuOpen(!isMenuOpen)}>
//           <View style={style.hamburgerIcon}>
//             <View style={style.hamburgerLine} />
//             <View style={style.hamburgerLine} />
//             <View style={style.hamburgerLine} />
//           </View>
//         </Pressable>
//       ) : (
//         <View style={style.headerContent}>
//           <TouchableOpacity onPress={openAppStore}>
//             <Image source={apple} style={style.storeIcon} />
//           </TouchableOpacity>
//           <TouchableOpacity onPress={openPlaystore}>
//             <Image source={googlePlay} style={style.storeIcon} />
//           </TouchableOpacity>
//           <Button
//             textStyle={style.loginText}
//             style={style.loginButton}
//             name={<FormattedMessage id={t('misc.login')} />}
//             onPress={() => navigate(NavigationNames.Login)}
//           />
//           <Button
//             textStyle={style.signUpButtonText}
//             style={style.signUpButton}
//             name={<FormattedMessage id={t('login.signUp')} />}
//             onPress={() => navigate(NavigationNames.SignUp)}
//           />
//           <View style={style.langSelectionWrapper}>
//             <Image source={languageIcon} style={style.languageIcon} />
//             <Text style={style.languageName} onPress={() => changeLang('en')}>
//               English |
//             </Text>
//             <Text style={style.languageName} onPress={() => changeLang('hi')}>
//               हिंदी
//             </Text>
//           </View>
//         </View>
//       )}

//       {isSmallScreen && isMenuOpen && (
//         <View style={style.mobileMenu}>
//           <Button
//             textStyle={style.loginText}
//             style={style.loginButton}
//             name={<FormattedMessage id={t('misc.login')} />}
//             onPress={() => navigate(NavigationNames.Login)}
//           />
//           <Button
//             textStyle={style.signUpButtonText}
//             style={style.signUpButton}
//             name={<FormattedMessage id={t('login.signUp')} />}
//             onPress={() => navigate(NavigationNames.SignUp)}
//           />
//           <TouchableOpacity onPress={openAppStore}>
//             <Image source={apple} style={style.storeIcon} />
//           </TouchableOpacity>
//           <TouchableOpacity onPress={openPlaystore}>
//             <Image source={googlePlay} style={style.storeIcon} />
//           </TouchableOpacity>
//         </View>
//       )}
//     </View>
//   );
// };

// export default LandingPageHeader;

import React, {useState, useEffect} from 'react';
import {
  Image,
  Linking,
  Platform,
  TouchableOpacity,
  View,
  Text,
  Pressable,
} from 'react-native';
import {style} from './Header.styles';
import {Logo} from '../../assets/svg';
import Button from '../Button/Button';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../utils/routeNames';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';
import {apple, googlePlay, languageIcon} from '../../assets/png';
import {SupportURLs} from '../../services/api/path';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import i18next from '../../services/i18next';
import {useTranslation} from 'react-i18next';

const LandingPageHeader: React.FC = () => {
  const {openModal} = useModal();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {windowWidth} = useDimensionsHook();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fontSize, setFontSize] = useState(20); // Default font size

  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const isSmallScreen = windowWidth < 600;

  useEffect(() => {
    const getFontSize = () => {
      if (windowWidth > 300 && windowWidth < 580) return 12;
      if (windowWidth > 600 && windowWidth < 720) return 14;
      return 20;
    };
    setFontSize(getFontSize());
  }, [windowWidth]);

  const openPlaystore = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.PlayStoreApp);
    supported
      ? await Linking.openURL(SupportURLs.PlayStoreApp)
      : openModal({message: t('login.urlDoesNotSupport')});
  };

  const openAppStore = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.AppleStore);
    supported
      ? await Linking.openURL(SupportURLs.AppleStore)
      : openModal({message: t('login.urlDoesNotSupport')});
  };

  const changeLang = (lng: string) => i18next.changeLanguage(lng);

  return (
    <View
      style={[style.landingContainer, {paddingRight: isWeb ? '10%' : '16%'}]}>
      <View style={style.logoContainer}>
        <Logo height={80} width={80} style={style.logoStyle} />
        <Text style={[style.logoText, {fontSize}]}>
          श्री समस्त राजस्थान जैन समाज, वडोदरा
        </Text>
      </View>

      {isSmallScreen ? (
        <Pressable onPress={() => setIsMenuOpen(!isMenuOpen)}>
          <View style={style.hamburgerIcon}>
            <View style={style.hamburgerLine} />
            <View style={style.hamburgerLine} />
            <View style={style.hamburgerLine} />
          </View>
        </Pressable>
      ) : (
        <View style={style.headerContent}>
          <TouchableOpacity onPress={openAppStore}>
            <Image source={apple} style={style.storeIcon} />
          </TouchableOpacity>
          <TouchableOpacity onPress={openPlaystore}>
            <Image source={googlePlay} style={style.storeIcon} />
          </TouchableOpacity>
          <Button
            textStyle={style.loginText}
            style={style.loginButton}
            name={<FormattedMessage id={t('misc.login')} />}
            onPress={() => navigate(NavigationNames.Login)}
          />
          <Button
            textStyle={style.signUpButtonText}
            style={style.signUpButton}
            name={<FormattedMessage id={t('login.signUp')} />}
            onPress={() => navigate(NavigationNames.SignUp)}
          />
          <View style={style.langSelectionWrapper}>
            <Image source={languageIcon} style={style.languageIcon} />
            <Text style={style.languageName} onPress={() => changeLang('en')}>
              English |
            </Text>
            <Text style={style.languageName} onPress={() => changeLang('hi')}>
              हिंदी
            </Text>
          </View>
        </View>
      )}

      {isSmallScreen && isMenuOpen && (
        <View style={style.mobileMenu}>
          <Button
            textStyle={style.loginText}
            style={style.loginButton}
            name={<FormattedMessage id={t('misc.login')} />}
            onPress={() => navigate(NavigationNames.Login)}
          />
          <Button
            textStyle={style.signUpButtonText}
            style={style.signUpButton}
            name={<FormattedMessage id={t('login.signUp')} />}
            onPress={() => navigate(NavigationNames.SignUp)}
          />
          <TouchableOpacity onPress={openAppStore}>
            <Image source={apple} style={style.storeIcon} />
          </TouchableOpacity>
          <TouchableOpacity onPress={openPlaystore}>
            <Image source={googlePlay} style={style.storeIcon} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default LandingPageHeader;
