import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: '10%',
    paddingVertical: '10%',
  },
  meesageText: {
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
    marginTop: 9,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
    textAlign: 'center',
  },
});
