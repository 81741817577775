import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../../utils/theme';

export const style = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    width: '80%',
    height: '35%',
    borderRadius: 20,
    backgroundColor: Colors.white,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  chooseLangTitle: {
    fontSize: 20,
    color: Colors.maroon,
    fontWeight: '800',
  },
  langListContainer: {
    width: 200,
    height: 40,
    borderWidth: 2,
    display: 'flex',
    justifyContent: 'center',
    alignite: 'center',
    borderStyle: 'solid',
    borderColor: Colors.maroon,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  langList: {
    fontSize: 20,
    color: 'black',
    fontWeight: '400',
    fontFamily: FontFamily.PoppinsItalic,
    alignSelf: 'center',
  },
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
  contentContainerStyle: {
    flexGrow: 1,
    paddingVertical: 30,
    paddingHorizontal: 20,
    gap: 16,
  },
  titleText: {
    fontSize: FontSize.h3,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.blackA100,
    marginTop: 10,
    marginBottom: 2,
    textTransform: 'capitalize',
  },
  occupationText: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
  },
  profileView: {
    marginBottom: 30,
  },
  boxView: {
    gap: 10,
  },
  appVersionText: {
    textAlign: 'center',
    fontSize: FontSize.bodyBig,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    marginTop: 20,
  },
  logoutButton: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
    width: 120,
    display: 'flex',
    alignSelf: 'center',
    marginTop: 10,
  },
  logoutText: {
    color: Colors.maroon,
    width: 0,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    // flex: 0,
    fontFamily: FontFamily.PoppinsMedium,
    top: 1,
  },
  langSelectionWrapperSetting: {
    width: 200,
    height: 60,
    // borderWidth: 2,
    position: 'absolute',
    top: 100,
    right: 30,
    // borderColor: `black`,
    // borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
  },
  languageName: {
    fontSize: 20,
    color: Colors.maroon,
    // textDecorationLine: 'underline',
  },
  inputPasswordField: {
    fontSize: 18,
  },
});
