import * as yup from 'yup';
import {
  changePasswordFormFields,
  Password,
} from '../components/modal/changePassword.types';

yup.setLocale({
  mixed: {
    required: ({path}) => `profile.errors.${path}.required`,
  },
  string: {
    matches: ({path}) => `profile.errors.${path}.matches`,
  },
});

// export const changePasswordSchema = yup
//   .object<Record<keyof changePasswordFormFields, yup.AnySchema>>({
//     oldPassword: yup.string().required(),
//     newPassword: yup
//       .string()
//       .length(6, 'password length should be atleast 6 digit.')
//       .required(),
//     confirmPassword: yup
//       .string()
//       .length(6, 'password length should be atleast 6 digit.')
//       .required(),
//   })
//   .required();

export const changePasswordSchema = yup
  .object<Record<keyof changePasswordFormFields, yup.AnySchema>>({
    oldPassword: yup.string().required(Password.oldPassword),
    newPassword: yup
      .string()
      .min(6, Password.minLength)
      .required(Password.newpasswordrequired),
    confirmPassword: yup
      .string()
      .min(6, Password.minLength)
      .required(Password.confirmPasswordrequired)
      .oneOf([yup.ref(Password.newPassword)], Password.mismatch),
  })
  .required();
