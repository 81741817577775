import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    minHeight: 200,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 10,
    borderColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelText: {
    fontSize: FontSize.bodyBig,
    color: Colors.lightBlack,
    marginBottom: 9,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
  },
  nameText: {
    fontSize: FontSize.bodyBig,
    color: Colors.blackA100,
    marginTop: 10,
    lineHeight: 24,
    fontFamily: FontFamily.PoppinsRegular,
  },
});
