import {FieldError, FieldErrorsImpl, Merge} from 'react-hook-form';
import {IntlShape} from 'react-intl';

export const getErrorProps = (
  intl: IntlShape,
  error:
    | Merge<FieldError, FieldErrorsImpl<{value: number; name: string}>>
    | undefined,
) => {
  const hasError = !!error;
  const helperText = error?.message
    ? intl.formatMessage({id: error.message})
    : undefined;

  return {
    error: hasError,
    helperText,
  };
};
