import {StyleSheet} from 'react-native';
import {Colors, FontFamily, FontSize} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  profileImage: {
    width: '100%',
    height: 200,
    borderRadius: 15,
    resizeMode: 'cover',
    backgroundColor: Colors.background,
  },
  profileEmptyImage: {
    width: '100%',
    height: 200,
    borderRadius: 15,
    backgroundColor: Colors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    fontSize: FontSize.h6,
    fontFamily: FontFamily.PoppinsMedium,
    color: Colors.black,
    marginTop: 12,
  },
  descriprion: {
    fontSize: FontSize.body,
    fontFamily: FontFamily.PoppinsRegular,
    color: Colors.lightBlack,
    marginTop: 3,
  },
});
