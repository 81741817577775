import {
  View,
  ImageBackground,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import React from 'react';
import {style} from './FamilyMemberDetails.styles';
import {ApiRoutes} from '../../../services/api/path';
import useDimensionsHook from '../../../hooks/useDimensions';
import {BackArrow, User} from '../../../assets/svg';
import {ProfileInfoTile} from '../../../components';
import {FormattedMessage} from 'react-intl';
import {gender, maritalStatus} from '../../../utils/json';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Colors} from '../../../utils/theme';

const FamilyMemberDetails: React.FC = () => {
  const {windowHeight} = useDimensionsHook();
  const navigate = useNavigate();
  const {state} = useLocation();
  const member = state?.member;

  const onBackPress = (): void => {
    navigate(-1);
  };

  return (
    <View style={style.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={style.scrollView}>
        <ImageBackground
          source={{
            uri: `${ApiRoutes.BaseURL}${member?.image?.substring(1)}`,
          }}
          style={[
            style.imagebackGround,
            {maxHeight: windowHeight / 2.5, height: windowHeight / 2.5},
          ]}
          imageStyle={{resizeMode: 'contain', maxHeight: windowHeight / 2.5}}>
          <TouchableOpacity
            style={style.backButton}
            onPress={onBackPress}
            activeOpacity={0.8}>
            <BackArrow width={30} height={30} />
          </TouchableOpacity>
          {(!member?.image || member?.image === '') && (
            <User
              fill={Colors.lightGray}
              width="40%"
              height="40%"
              style={style.userLogo}
            />
          )}
        </ImageBackground>
        <View style={style.containerView}>
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.name" />}
            value={member.name}
          />
          {member?.relationship && member.relationship !== '' && (
            <ProfileInfoTile
              title={<FormattedMessage id="familyMemberDetail.relation" />}
              value={member.relationship}
            />
          )}
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.dateOfBirth" />}
            value={moment(member.dob).format('DD-MM-YYYY')}
          />
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.occupation" />}
            value={member.practice}
          />
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.bloodGroup" />}
            value={member.bloodGroup}
          />
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.maritalStatus" />}
            value={maritalStatus.find(x => x.id === member.maritalStatus)?.name}
          />
          <ProfileInfoTile
            title={<FormattedMessage id="familyMemberDetail.gender" />}
            value={gender.find(x => x.id === member.gender)?.name}
          />
          {/* {member?.bioData && (
            <ProfileInfoTile
              title={<FormattedMessage id="familyMemberDetail.bioData" />}
              value={<PdfViewer pdfUrl={member.bioData} />}
            />
          )} */}
        </View>
      </ScrollView>
    </View>
  );
};

export default FamilyMemberDetails;
