import {useEffect} from 'react';
import axios from '../../../services/api/api';
import {ApiRoutes} from '../../../services/api/path';
import {useAppDispatch, useAppSelector} from '../../../services/redux/hook';
import {AllFamilyMembers} from '../../../services/api/response/user';
import {familyMembersAction} from '../../../services/redux/user/user.action';
import {AddProfileController, AddProfileFormFields} from './AddProfile.types';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {addMatrimonialFormSchema} from '../../../validationSchemas/matrimonial';
import {AddMatrimonialProfile} from '../../../services/api/response/matrimonial';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';

export const useAddProfile = (): AddProfileController => {
  const {openModal} = useModal();
  const {t} = useTranslation();
  const {user, familyMembers} = useAppSelector(state => state.userReducer);
  const dispatch = useAppDispatch();
  const {
    control,
    formState: {errors},
    setValue,
    handleSubmit,
    watch,
  } = useForm<AddProfileFormFields>({
    resolver: yupResolver(addMatrimonialFormSchema),
    mode: 'onSubmit',
  });

  const {isLoading} = watch();

  const fetchFamilyMembers = async () => {
    try {
      const {data} = await axios.post<AllFamilyMembers>(
        ApiRoutes.FamilyMembers,
        {
          UId: user?.uId,
          ApiSecret: user?.apiSecret,
          SkipSize: 0,
          PageSize: 100,
        },
      );
      if (data.isAuthorisedUser) {
        dispatch(familyMembersAction(data.familyMembers));
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchFamilyMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (
    data: AddProfileFormFields,
    onSuccess: () => void,
  ) => {
    try {
      setValue('isLoading', true);
      const {data: response} = await axios.post<AddMatrimonialProfile>(
        ApiRoutes.AddMatrimonialProfile,
        {
          UId: user?.uId,
          ApiSecret: user?.apiSecret,
          FamilyMemberId: data.familyMember.id,
          IsForMatch: 1,
          BioData: data.biodata || null,
        },
      );
      setValue('isLoading', false);
      if (response.isAuthorisedUser && response.isSaved) {
        openModal({
          title: t('misc.success'),
          message: t('matrimonial.successMessage'),
        });
        onSuccess();
        return;
      } else {
        openModal({
          title: t('matrimonial.addProfileFailed'),
          message: response?.message,
        });
      }
    } catch (e) {
      setValue('isLoading', false);
      console.error(e);
    }
  };

  return {
    familyMembers,
    control,
    errors,
    setValue,
    handleSubmit,
    onSubmit,
    isLoading,
  };
};
