import {
  DimensionValue,
  Linking,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, {useRef} from 'react';
import {style} from './Home.styles';
import useDimensionsHook from '../../hooks/useDimensions';
import Swiper from 'react-native-web-swiper';
import {Banner} from '../../components';
import {Breakpoints, Colors} from '../../utils/theme';
import {SupportURLs} from '../../services/api/path';
import {useHomeController} from './Home.controller';
import {useTranslation, Trans} from 'react-i18next';
import {useModal} from '../../setup/providers/modalContext/ModalContext';

const Home: React.FC = () => {
  const {openModal} = useModal();
  const {t} = useTranslation();
  const {banners, fetchBanners, refreshing} = useHomeController();
  const {windowHeight, windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const sliderHeight: DimensionValue = isWeb
    ? windowHeight / 2
    : windowHeight / 3 < 300
    ? 250
    : windowHeight / 3;

  const swiperRef = useRef<Swiper>(null);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.goToNext();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.goToPrev();
    }
  };
  const openReadMore = async () => {
    const supported = await Linking.canOpenURL(SupportURLs.AboutUs);

    if (supported) {
      await Linking.openURL(SupportURLs.AboutUs);
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  const H4 = (props: any) => (
    <Text style={style.semiTitle} onPress={openReadMore}>
      {props.children}
    </Text>
  );

  const H5 = (props: any) => <Text style={style.title}>{props.children}</Text>;
  return (
    <View style={style.container}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps="handled"
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => fetchBanners(true)}
          />
        }
        contentContainerStyle={style.scrollView}>
        <View style={[style.bannerView, {height: sliderHeight - 30}]}>
          <Swiper
            ref={swiperRef}
            key={banners?.length}
            loop
            timeout={5}
            springConfig={{speed: 11}}
            controlsProps={{
              dotsTouchable: false,
              prevPos: false,
              nextPos: false,
              dotActiveStyle: style.dotActiveStyle,
              dotProps: {
                badgeStyle: style.dotInActiveStyle,
              },
            }}
            minDistanceForAction={0}>
            {banners?.map(image => (
              <Banner key={image.toString()} image={image.path} />
            ))}
          </Swiper>
          <TouchableOpacity style={style.arrowLeft} onPress={handlePrevSlide}>
            <Text style={style.swipeArrow}>{'<'}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={style.arrowRight} onPress={handleNextSlide}>
            <Text style={style.swipeArrow}>{'>'}</Text>
          </TouchableOpacity>
        </View>
        <Text style={style.aboutUs}>
          <Trans i18nKey="home.aboutUs" />
        </Text>
        <Text style={style.description}>
          <Trans
            i18nKey="home.description"
            components={{h4: <H4 />, h5: <H5 />}}
          />
        </Text>
        <Text style={{color: Colors.primary}} onPress={openReadMore}>
          Read more
        </Text>
      </ScrollView>
    </View>
  );
};

export default Home;
