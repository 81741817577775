import axios from '../../api/api';
import {ApiRoutes} from '../../api/path';
import {FamilyMembers, User} from '../../api/response/user';
import {AppDispatch} from '../store';
import {UserActionType} from './user.types';

export const userInfoAction = (payload: User) => ({
  type: UserActionType.UserInfo,
  payload,
});

export const updateUserInfoAction = (payload: User) => ({
  type: UserActionType.UpdateUserInfo,
  payload,
});

export const userLogoutAction = () => ({
  type: UserActionType.Logout,
});

export const fetchUserDetails =
  (uId: number, apiSecret: number) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const {data} = await axios.post(ApiRoutes.UserDetails, {
        UId: uId,
        ApiSecret: apiSecret,
        LoginId: uId,
      });
      if (data.isAuthorisedUser) {
        const response: User = data.userDetails[0];
        dispatch(updateUserInfoAction(response));
      }
    } catch (e) {
      console.error(e);
    }
  };

export const familyMembersAction = (payload: FamilyMembers[]) => ({
  type: UserActionType.FamilyMembers,
  payload,
});
