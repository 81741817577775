import * as Axios from 'axios';
import {ApiRoutes} from './path';
import {cameliseDeep} from '../../utils/functions/camelise';

const axios = Axios.default.create({
  baseURL: ApiRoutes.BaseURL,
});

axios.interceptors.response.use(
  response => {
    response.data = cameliseDeep(response.data);
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axios;
