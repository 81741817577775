import {TouchableOpacity} from 'react-native';
import React from 'react';
import {style} from './CheckBox.styles';
import {CheckBoxProps} from './CheckBox.types';
import {Check, UnCheck} from '../../assets/svg';
import {Colors} from '../../utils/theme';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  style: TouchableOpacityStyle,
  children,
  error,
  helperText,
  ...restProps
}) => {
  return (
    <>
      <TouchableOpacity
        style={[style.container, TouchableOpacityStyle]}
        {...restProps}>
        {checked ? (
          <Check fill={Colors.primary} />
        ) : (
          <UnCheck fill={Colors.primary} />
        )}
        {children}
      </TouchableOpacity>
      <ErrorMessage
        error={error}
        helperText={helperText}
        textStyle={{marginTop: 15}}
      />
    </>
  );
};

export default CheckBox;
