import {FamilyMembers, User} from '../../api/response/user';
import {UserActionType, UserState} from './user.types';

const initialState: UserState = {
  user: undefined,
  familyMembers: [],
};

const userReducer = (
  state: UserState = initialState,
  action: any,
): UserState => {
  switch (action.type) {
    case UserActionType.UserInfo:
      return {
        ...state,
        user: action.payload as User,
      };
    case UserActionType.UpdateUserInfo:
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload as User),
          hasProfileCompleted: true,
        },
      };
    case UserActionType.FamilyMembers:
      return {
        ...state,
        familyMembers: action.payload as FamilyMembers[],
      };
    case UserActionType.Logout:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
