import {useCallback, useState} from 'react';
import axios from '../../../services/api/api';
import {BoardMember} from '../../../services/api/response/community';
import {useAppSelector} from '../../../services/redux/hook';
import {getWhatsAppURLs, ProfileDetailController} from './ProfileDetail.types';
import {User, UserDetailsResponse} from '../../../services/api/response/user';
import {useFocusEffect} from '@react-navigation/native';
import {ApiRoutes} from '../../../services/api/path';
import {Linking, Platform} from 'react-native';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../setup/providers/modalContext/ModalContext';

export const useProfileDetail = (
  props: BoardMember,
): ProfileDetailController => {
  const {user} = useAppSelector(state => state.userReducer);
  const [profile, setProfile] = useState<User>();
  const {openModal} = useModal();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      getProfileDetail();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  );

  const getProfileDetail = async () => {
    try {
      const {data} = await axios.post<UserDetailsResponse>(
        ApiRoutes.UserDetails,
        {
          UId: user?.UId,
          ApiSecret: user?.ApiSecret,
          LoginId: props.loginId,
        },
      );
      setProfile(data.userDetails[0]);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const openWhatsApp = async (phoneNumber: string) => {
    if (!phoneNumber) return;

    const {UserWhatsApp, UserWhatsAppWeb} = getWhatsAppURLs(phoneNumber);

    console.log(UserWhatsApp, 'check its unique no ');

    const supported = await Linking.canOpenURL(
      Platform.OS === 'web' ? UserWhatsAppWeb : UserWhatsApp,
    );

    if (supported) {
      await Linking.openURL(
        Platform.OS === 'web' ? UserWhatsAppWeb : UserWhatsApp,
      );
    } else {
      openModal({
        message: t('login.urlDoesNotSupport'),
      });
    }
  };

  return {
    profile,
    isLoading,
    openWhatsApp,
  };
};
