import * as React from 'react';
import Svg, {G, Path, SvgProps} from 'react-native-svg';
import {Colors} from '../../../utils/theme';

const CalenderOutLine = (props: SvgProps) => {
  return (
    <Svg width={14.876} height={14.14} {...props}>
      <G
        fill="none"
        stroke={Colors.primary}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}>
        <Path
          data-name="Path 90"
          d="M2.014 2.014h8.848a1.264 1.264 0 011.264 1.264v8.848a1.264 1.264 0 01-1.264 1.264H2.014A1.264 1.264 0 01.75 12.126V3.278a1.264 1.264 0 011.264-1.264z"
        />
        <Path data-name="Path 91" d="M8.966.75v2.528" />
        <Path data-name="Path 92" d="M3.91.75v2.528" />
        <Path data-name="Path 93" d="M.75 5.806h11.376" />
      </G>
    </Svg>
  );
};

export default CalenderOutLine;
