import React from 'react';
import {SafeAreaView} from 'react-native';
import IntlProviderWrapper from '../intl/IntlProvider/IntlProvider';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from '../../services/redux/store';
import {ModalProvider} from './modalContext/ModalContext';
import {Alert} from '../../components';

interface Props {
  children?: React.ReactNode;
}

const Providers: React.FC<Props> = ({children}) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProviderWrapper>
          <ModalProvider>
            <SafeAreaView style={{flex: 1}}>{children}</SafeAreaView>
            <Alert />
          </ModalProvider>
        </IntlProviderWrapper>
      </PersistGate>
    </Provider>
  );
};

export default Providers;
