import React, {useEffect} from 'react';
import {LogBox} from 'react-native';
import Routes from './setup/routes/route';
import Providers from './setup/providers/Providers';
import {InternetInfo} from './components';

const App: React.FC = (): JSX.Element => {
  useEffect(() => {
    LogBox.ignoreLogs([
      'VirtualizedLists should never be nested',
      'Non-serializable values were found in the navigation state',
    ]);
  }, []);
  return (
    <React.StrictMode>
      <Providers>
        <InternetInfo />
        <Routes />
      </Providers>
    </React.StrictMode>
  );
};

export default App;
