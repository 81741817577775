import {BoardMember} from '../../api/response/community';
import {UserActionType} from '../user/user.types';
import {CommunityActionType, CommutnityState} from './community.types';

const initialState: CommutnityState = {
  boardMembers: [],
  allMembers: [],
};

const communityReducer = (
  state: CommutnityState = initialState,
  action: any,
): CommutnityState => {
  switch (action.type) {
    case CommunityActionType.BoardMembers:
      return {...state, boardMembers: action.payload as BoardMember[]};
    case CommunityActionType.AllMembers:
      return {...state, allMembers: action.payload as BoardMember[]};
    case UserActionType.Logout:
      return initialState;
    default:
      return state;
  }
};

export default communityReducer;
