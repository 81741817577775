import {View, FlatList, RefreshControl} from 'react-native';
import React from 'react';
import {
  EmptyComponent,
  FloatingButton,
  Header,
  MemberInfo,
  Spinner,
} from '../../../components';
import {style} from './FamilyMembers.styles';
import {useFamilyMembersController} from './FamilyMembers.controller';
import {useDimensions} from '../../../hooks/useDimensions';
import {Breakpoints} from '../../../utils/theme';
import {ApiRoutes} from '../../../services/api/path';
import {
  BackArrow,
  Pulse,
  FamilyMembers as FamilyMembersIcon,
} from '../../../assets/svg';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {NavigationNames} from '../../../utils/routeNames';
import {FamilyMembers as FamilyMembersProps} from '../../../services/api/response/user';
import {useTranslation} from 'react-i18next';

const FamilyMembers: React.FC = () => {
  const navigate = useNavigate();
  const {windowWidth} = useDimensions();
  const {t} = useTranslation();
  const isMobile =
    windowWidth <= Breakpoints.md && windowWidth >= Breakpoints.xs;
  const columnCount = isMobile ? 2 : Math.floor(windowWidth / 300);

  const {familyMembers, fetchFamilyMembers, loading} =
    useFamilyMembersController();

  const onBackButtonPress = (): void => {
    navigate(NavigationNames.AccountAndSettings, {replace: true});
  };

  const onEditButtonClick = (props: FamilyMembersProps): void => {
    navigate(NavigationNames.AddFamilyMember, {
      state: {member: props},
    });
  };

  const onProfilePress = (item: FamilyMembersProps): void => {
    navigate(NavigationNames.FamilyMemberDetails, {
      state: {
        member: item,
      },
    });
  };

  return (
    <View style={style.container}>
      <Spinner visible={loading.fetching} />
      <Header
        leftComponent={<BackArrow width={20} height={20} />}
        leftComponentPress={onBackButtonPress}
        title={<FormattedMessage id={t('misc.familyMembers')} />}
        rightComponent={null}
      />
      <FlatList
        key={columnCount}
        data={familyMembers}
        numColumns={columnCount}
        keyExtractor={item => item?.id.toString()}
        renderItem={({item}) => (
          <MemberInfo
            {...item}
            position={item.practice}
            image={
              item?.image
                ? {
                    uri: `${ApiRoutes.BaseURL}${item?.image?.substring(1)}`,
                  }
                : 0
            }
            buttonProps={{
              name: <FormattedMessage id={t('misc.edit')} />,
              onPress: () => onEditButtonClick(item),
            }}
            onPress={() => onProfilePress(item)}
          />
        )}
        columnWrapperStyle={style.columnWrapperStyle}
        contentContainerStyle={style.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={loading.refreshing}
            onRefresh={() => fetchFamilyMembers(true)}
          />
        }
        ListEmptyComponent={() => (
          <EmptyComponent
            icon={<FamilyMembersIcon width={300} height={300} />}
            isFetching={loading.fetching}
            message={
              <FormattedMessage id={t('familyMember.noFamilyMembers')} />
            }
          />
        )}
      />
      <FloatingButton onPress={() => navigate(NavigationNames.AddFamilyMember)}>
        <Pulse />
      </FloatingButton>
    </View>
  );
};

export default FamilyMembers;
