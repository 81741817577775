export enum NavigationNames {
  Language = '/language',
  Login = '/login',
  Verification = '/verification',
  Main = '/main',
  Home = '/home',
  AboutUs = '/aboutUs',
  Community = '/community',
  AllMembers = '/all-members',
  BoardMembers = '/board-members',
  AccountAndSettings = '/account-settings',
  EditProfile = '/edit-profile',
  Matrimonial = '/matrimonial',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  FamilyMembers = '/family-members',
  AddFamilyMember = '/add-family-member',
  Profile = '/account-settings',
  ProfileDetail = '/profile-detail',
  FamilyMemberDetails = '/family-member-details',
  AddMatrimonialProfile = '/add-matrimonial-profile',
  Event = '/event',
  OtherFamilyMembers = '/otherfamily-members',
  EventDetails = '/event-details',
  ContactUs = '/contact-us',
}
