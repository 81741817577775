import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Image, Platform, Text, View} from 'react-native';
import {useNavigate} from 'react-router-dom';
import {IosLogo} from '../../assets/svg';
import {styles} from './PageNotFound.styles';
import {Page404} from '../../assets/png';
import {Button} from '../../components';
import useDimensionsHook from '../../hooks/useDimensions';
import {Breakpoints} from '../../utils/theme';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const {windowWidth} = useDimensionsHook();
  const isWeb = Platform.OS === 'web' && windowWidth > Breakpoints.md;
  const handleBackClick = () => navigate(-1);
  return (
    <View style={styles.container}>
      <IosLogo
        style={styles.logoStyle}
        width={isWeb ? 200 : 150}
        height={isWeb ? 300 : 40}
      />
      <Image style={styles.image} source={Page404} />
      <Text style={styles.title}>
        <FormattedMessage id="pageNotFound.pageNotFound" />
      </Text>
      <Text style={styles.message}>
        <FormattedMessage id="pageNotFound.message" />
      </Text>
      <Button
        style={styles.button}
        onPress={handleBackClick}
        name={<FormattedMessage id="misc.goBack" />}
      />
    </View>
  );
};

export default PageNotFound;
