import {Platform, StyleSheet} from 'react-native';
import {Colors} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    //@ts-ignore
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 20,
    bottom: 20,
    backgroundColor: Colors.primary,
    color: Colors.white,
    borderRadius: 100,
    minWidth: 50,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: Colors.primary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
