import * as React from 'react';
import Svg, {SvgProps, Ellipse, Path, G, Circle} from 'react-native-svg';
const CommunityMembers = (props: SvgProps) => (
  <Svg viewBox="0 0 500 500" {...props}>
    <Ellipse cx={249.53} cy={338.25} rx={238} ry={137.41} fill="#f5f5f5" />
    <Ellipse cx={388.39} cy={263.47} rx={30.75} ry={17.75} fill="#e0e0e0" />
    <Ellipse cx={318.17} cy={256.27} rx={39.37} ry={22.73} fill="#e0e0e0" />
    <Ellipse cx={246.82} cy={251.93} rx={39.37} ry={22.73} fill="#e0e0e0" />
    <Ellipse cx={140.9} cy={259.82} rx={32.49} ry={18.76} fill="#e0e0e0" />
    <Ellipse cx={99.36} cy={393.76} rx={34.34} ry={19.82} fill="#e0e0e0" />
    <Ellipse cx={156.12} cy={390.55} rx={34.34} ry={19.82} fill="#e0e0e0" />
    <Ellipse cx={215.21} cy={388.2} rx={38.07} ry={21.98} fill="#e0e0e0" />
    <Ellipse cx={347.05} cy={389.34} rx={72.87} ry={42.07} fill="#e0e0e0" />
    <Path
      d="M156.06 127.85c-5.38-28.77-6.21-34.73-6.9-40.65 8.09-.85 12.3 3.9 13.78 12.42.73 4.16 1.4 21 3.33 27.75 1.7 5.88 4 12.23 7.76 17.23a4.28 4.28 0 0 0 2.54 1.65c2.31.81 3 2.08 4.89 3.33 1.47 1 2.56 1 2.49 1.48a2 2 0 0 1-2.31 1.47 10.71 10.71 0 0 1-2.76-.77 20.67 20.67 0 0 0 4.05 4.09c3.63 2.82 3.44 5.66-2.27 7-3.58.85-6.95-.71-11.45-8-1.3-2.1-4-5.85-5.44-8.63-4.48-8.6-6.77-13.53-7.71-18.37Z"
      fill="#f28f8f"
    />
    <Path
      d="M150.42 86.49s5.61-.27 8.07 2.7 4.34 4.22 5.19 13 1.84 17.83 2.15 21.28 7.3 19.9 7.3 19.9a12.3 12.3 0 0 0-7.61 5.88s-7-11.2-9.35-18.26a77.21 77.21 0 0 1-3.68-17c-.73-5.99-2.07-27.5-2.07-27.5Z"
      fill="#455a64"
    />
    <Path
      d="M141.08 47.41a16.85 16.85 0 0 1 16.85 16.85v15.2a11.11 11.11 0 0 1-11.11 11.11h-22.58V64.26a16.85 16.85 0 0 1 16.85-16.85Z"
      fill="#E21F23"
    />
    <Path
      d="M141.08 47.41a16.85 16.85 0 0 1 16.85 16.85v15.2a11.11 11.11 0 0 1-11.11 11.11h-22.58V64.26a16.85 16.85 0 0 1 16.85-16.85Z"
      opacity={0.15}
    />
    <Path
      d="M138.14 265c-.76-.67-1.52-1.33-2.2-2-2.14-1.95-3.77-5.27-4.7-7.53a10.47 10.47 0 0 1-.81-4.06V247h-7.71V254.35c.37 1.61 3.91 5.88 5.92 8.66 1.34 1.87 3.9 4.24 7 4.65 2.9.42 4.36-.5 2.5-2.66Z"
      fill="#f28f8f"
    />
    <Path
      d="M120.76 257.43c-.39 1.79.18 6.67.18 6.67a6.47 6.47 0 0 0 3.59 2.72l1.06-6.83Z"
      fill="#263238"
    />
    <Path d="m124.53 266.82 6-2.14 1.32-5.42-6.71 3.62-.61 3.94z" />
    <Path
      d="M130.43 251v-1.27c.83.38 2.8 7 5.36 11.45 2.34 4.1 6.82 6.31 8.3 8.08 2 2.37-1.67 5.58-7.7 4.45-3-.57-7.55-2.36-8.82-4.35s-1.78-5.66-2.81-7.12-3.45-3.1-4-4.77c-.3-.92.11-2.92.58-4.6s.86-3.41 1.38-3.26v1.17c.42.81 1.22 1.68 4.25 1.76 1.44.01 2.83-.25 3.46-1.54Z"
      fill="#455a64"
    />
    <Path
      d="M163.22 261.65c-.76-.68-1.52-1.34-2.21-2-2.14-2-3.77-5.27-4.7-7.54a13.9 13.9 0 0 1-.84-4.7v-3.67l-7.69-.11V251c.37 1.61 3.91 5.89 5.92 8.67 1.35 1.87 3.92 4.25 7 4.66 2.92.44 4.4-.48 2.52-2.68Z"
      fill="#f28f8f"
    />
    <Path
      d="M145.81 253.9c-.4 1.8.18 6.68.18 6.68a6.52 6.52 0 0 0 3.58 2.72l1.07-6.83Z"
      fill="#263238"
    />
    <Path d="m149.57 263.3 6-2.15 1.33-5.41-6.71 3.62-.62 3.94z" />
    <Path
      d="M155.47 247.44v-1.28c.84.38 2.81 7 5.37 11.46 2.33 4.1 6.82 6.31 8.3 8.08 2 2.37-1.68 5.58-7.71 4.44-3-.56-7.54-2.36-8.81-4.34s-1.78-5.67-2.82-7.12-3.45-3.1-4-4.78c-.3-.91.1-2.91.57-4.59s.87-3.41 1.4-3.25v1.18c.42.81 1.2 1.65 4.24 1.74 1.45.02 2.84-.21 3.46-1.54Z"
      fill="#455a64"
    />
    <Path
      d="M122.35 133.5c-6.18 13.54.22 61.21.22 61.21-.18 2.2-3.11 7.11-2.91 15.88.26 11.47 2.6 35.74 3.06 39s3.32 3.36 7.71.38c0 0 5.91-41.83 7.34-53.18C139 186.86 141 169.2 141 169.2l3 28.33s-2.13 7.37-2.19 11.62c-.1 8.76 5.42 32.33 5.95 36.91 1.08 2.16 2.68 1.83 7.69-.79 0 0 2.47-41.21 3.07-49.25 1-13.52-1-60-3.44-68Z"
      fill="#f28f8f"
    />
    <Path
      d="m141 169.2 2.46-16.7s8.5-1.76 12.35-5.39a22 22 0 0 1-10 6.68l-2.26 9.68v29.15Z"
      opacity={0.15}
    />
    <Path
      d="m133.88 87.5-5.5.27-5.38 6.16c-.43 3.51-1.71 8-.55 11.37l3.6 15.87a24.52 24.52 0 0 1-1.05 4.53 82.44 82.44 0 0 0-6 18.44c-5.69 32.53-4.63 67.21-4.63 98.58 13.86 7.75 42.63 2.17 46.79-3.49.06-.41-.74-75.79-5-114.14a6.41 6.41 0 0 1-.41-4.3l1.54-7.52c3.45-5.37 3.75-10.6 1.93-14.37-3-6.14-7.25-10.45-9.41-11.13a22.61 22.61 0 0 0-5.65-.68Z"
      fill="#E21F23"
    />
    <Path
      d="m133.88 87.5-5.5.27-5.38 6.16c-.43 3.51-1.71 8-.55 11.37l3.6 15.87a24.52 24.52 0 0 1-1.05 4.53 82.44 82.44 0 0 0-6 18.44c-5.69 32.53-4.63 67.21-4.63 98.58 13.86 7.75 42.63 2.17 46.79-3.49.06-.41-.74-75.79-5-114.14a6.41 6.41 0 0 1-.41-4.3l1.54-7.52c3.45-5.37 3.75-10.6 1.93-14.37-3-6.14-7.25-10.45-9.41-11.13a22.61 22.61 0 0 0-5.65-.68Z"
      fill="#fff"
      opacity={0.8}
    />
    <Path
      d="M124.88 87.08c-6.71.25-8.1 4.83-12.38 10.86-3 4.28-8.12 11-8.12 11s-8.8-12.84-9.29-14.76c-.59-2.31.18-3.34 0-5.88s-.68-4.19-.31-5.35-1.75-1-2.29.52-.23 3.3-.87 3.1-1.93-4-2.08-6.88-1.87-3.28-2.25-2.26a8.45 8.45 0 0 0-.45 1.69 3.7 3.7 0 0 0-3.46 1.66c-1.08 1.93-1.84 4.6-.9 8.74s3.74 6.79 4.72 8.46c1.45 2.5 6.68 17 12.35 23.92 2.6 3.14 5.72 2.12 8.07-.35l15-15.83c4.61-4.38 6.38-15.57 2.26-18.64Z"
      fill="#f28f8f"
    />
    <Path
      d="M126.87 86.49s-5.46-.67-8.56 2.7-13.93 19.71-13.93 19.71l-9.29-14.76s-1.18 3.86-7.47 4.61c0 0 3 7.62 5.34 13.28s6 12.45 9.78 12.45 5-1.86 12-9.19 11-11.76 11-11.76 4.65-8.69 1.13-17.04Z"
      fill="#455a64"
    />
    <Path d="M130.29 85h10v11.8a10 10 0 0 1-10 10V85Z" fill="#E21F23" />
    <Path
      d="M130.29 85h10v11.8a10 10 0 0 1-10 10V85Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M152.41 56c2.27 1.63 3 3.49 2.8 13.84-.21 8.78-3.05 11-4.47 11.58s-4.18.24-6.85-.2v6.22a5.27 5.27 0 0 1 1.77 3.08c.18.65-2 1.66-7.29.94a7 7 0 0 1-5.48-3.58V68.24s0-8 5.71-11.08A16 16 0 0 1 152.41 56Z"
      fill="#f28f8f"
    />
    <Path
      d="M132.89 68.24s5.2-9.29 12.77-10.42 9.25 2.67 9.25 2.67-1-8.59-9.25-8.37c-11.42.31-12.77 9.06-12.77 16.12Z"
      fill="#E21F23"
    />
    <Path
      d="M132.89 68.24s5.2-9.29 12.77-10.42 9.25 2.67 9.25 2.67-1-8.59-9.25-8.37c-11.42.31-12.77 9.06-12.77 16.12Z"
      opacity={0.4}
    />
    <Path
      d="M144 67.64a1.1 1.1 0 1 1-2.2.1 1.11 1.11 0 0 1 1-1.19 1.13 1.13 0 0 1 1.2 1.09Z"
      fill="#263238"
    />
    <Path
      d="m144.4 75.1 2.56.81a1.31 1.31 0 0 1-1.66.92 1.4 1.4 0 0 1-.9-1.73Z"
      fill="#b16668"
    />
    <Path
      d="m153.5 64.39-2.23-1.55a1.29 1.29 0 0 1 1.85-.38 1.44 1.44 0 0 1 .38 1.93ZM140.75 64.25 143 62.7a1.29 1.29 0 0 0-1.85-.38 1.43 1.43 0 0 0-.4 1.93ZM152.62 67.41a1.1 1.1 0 1 1-2.2.1 1.11 1.11 0 0 1 1-1.19 1.13 1.13 0 0 1 1.2 1.09Z"
      fill="#263238"
    />
    <Path
      d="m147.33 66.68.17 6.65 3.49-1.09-3.66-5.56zM143.89 81.22c-2.82-.37-8.61-2-9.51-4.34a6 6 0 0 0 2 2.93c1.73 1.49 7.48 3.16 7.48 3.16Z"
      fill="#b16668"
    />
    <Path
      d="m147.46 82.38-3.56-1.16a1.16 1.16 0 0 1-1.16 1.16h-5.58a4.28 4.28 0 0 1-4.27-4.29l-3.55 4.29h-5.1v6.75a4.17 4.17 0 0 0 4.16 4.18h15.39a10.16 10.16 0 0 0 10.15-10.18v-.75Z"
      fill="#E21F23"
    />
    <Path
      d="m147.46 82.38-3.56-1.16a1.16 1.16 0 0 1-1.16 1.16h-5.58a4.28 4.28 0 0 1-4.27-4.29l-3.55 4.29h-5.1v6.75a4.17 4.17 0 0 0 4.16 4.18h15.39a10.16 10.16 0 0 0 10.15-10.18v-.75Z"
      opacity={0.15}
    />
    <Path
      d="M155.76 120.79s-2.12 1.86-12.52 2.34a49 49 0 0 1-17.22-2L125 125.7s7 2.66 18.13 2.27 13.08-2.88 13.08-2.88Z"
      fill="#E21F23"
    />
    <Path
      d="M296.29 115.84c-1.78 4.85-7.89 19.53-8.9 21.65a50.74 50.74 0 0 0-2.49 6.51c-.7 2.58-5.41 9.58-3.13 10.94 4.09 2.42 7.53-2.18 8.83-4a89.5 89.5 0 0 0 5.2-8.48c2.92-5.23 5.59-9.79 7.85-14.7 1.74-3.77 6.55-21.17 7.94-25.5 1.89-5.87 3.52-22.45 3.52-22.45-5.9 0-9.17 1.2-10.73 4.12-3 5.58-4.3 13.06-5.6 19.76-1.17 6.1-2.49 12.15-2.49 12.15Z"
      fill="#f28f8f"
    />
    <Path
      d="M261.17 100.09a105.52 105.52 0 0 0 6.76 22.29c2.94 6.42 7.24 14.24 8.62 17.8a5 5 0 0 1-.15 2.88c-.51 2.08-.12 3.08-.71 5.22-.47 1.69-.76 2.21-.43 2.49a2 2 0 0 0 2.67-.41 14.36 14.36 0 0 0 1.82-2.76 18.3 18.3 0 0 1-.22 5.9c-.85 4.47 2.25 4.91 5.19 3.74 3.37-1.35 4.39-4.68 3-13a55 55 0 0 0-3-9.52c-3.51-8.55-6.17-13.95-8.74-20.18-2.12-9-4.27-19-6.78-24.85-1.34-3.1-5.66-5.34-10.67-4.93.65 5.53 2.64 15.33 2.64 15.33Z"
      fill="#ffa8a7"
    />
    <Path
      d="M278.8 147.89c1.22-.94 4.14-2.88 5.13-1.57s1.17 5.35.26 7.06-4 1.65-4.25 3.46c-2.73-.77-3.72-6.97-1.14-8.95Z"
      fill="#f28f8f"
    />
    <Path
      d="M315.11 78.82s-6.83-.31-10 3.18c-4.35 4.73-5.73 16.7-7.59 25.87 3.89 4.4 10.46 4.42 13.16 1.55Z"
      fill="#E21F23"
    />
    <Path
      d="M315.11 78.82s-6.83-.31-10 3.18c-4.35 4.73-5.73 16.7-7.59 25.87 3.89 4.4 10.46 4.42 13.16 1.55Z"
      opacity={0.1}
    />
    <Path
      d="M335.46 240.77h8.26v14.13h-8.26zM312.61 240.72l-8.26.28-.41-14.96 8.67-.28v14.96z"
      fill="#ffa8a7"
    />
    <Path
      d="M341.66 135.27c.6 18.24.64 55.54.64 55.54.19 2.27 2.38 6.31 2.55 14.48.24 11.85-.78 42.54-.78 42.54a10.86 10.86 0 0 1-9.19.18s-7.29-42.26-8.77-54c-1.29-10.24-3.48-38.05-3.48-38.05l-4.47 33.42a115.8 115.8 0 0 1 .3 15.73c-.38 5.43-5.33 31-5.33 31a11.26 11.26 0 0 1-9.53.73s-.88-42.44-1-47.42c-.11-5.67.51-63.89.51-63.89Z"
      fill="#455a64"
    />
    <Path
      d="M335.46 250.82v-1.18c-.79.35-2.62 7.35-5.9 10.34-2.6 2.37-6.16 5.12-7.06 7.77s4.95 4.27 7.38 3.84c2.83-.5 7.73-2.49 9-4.34s1.8-4.56 2.8-5.92 3.31-2.86 3.85-4.42c.29-.86-.06-2.75-.48-4.34s-.79-3.06-1.29-2.93v.9a6.49 6.49 0 0 1-4.13 1.4c-1.25.06-4.18-.19-4.17-1.12ZM304.27 238.45c-.43 0-.78.62-1.73 1.65a18.1 18.1 0 0 1-4.85 3.49c-3.09 1.55-9.11 3.93-11.83 5.16-1.69.76-1.62 2.81.14 4s6 2 11.09.87c2.78-.62 6.39-3.18 8.67-3s6.57.1 8.25-1.06 1-3.65.54-5.78c-.52-2.33-1.17-6-1.94-5.8v.8c-1 1.5-6.12 2.11-8.3 1Z"
      fill="#263238"
    />
    <Path
      d="M322.63 156a30.39 30.39 0 0 1-11.49-6.59s2.21 4.16 8.81 8l-1.41 29.13Z"
      fill="#37474f"
    />
    <Path
      d="M327.47 78.6a76.86 76.86 0 0 1 11.7 1.8c2.54 1 3.8 2.86 3.88 5.93.26 10.54-2.52 32.53-2.52 32.53l1.53 18.21c-5.06 5.07-27.06 7.05-39.35-1.07 0 0 1.3-35.91 2.49-41.83 2.14-10.66 6.38-15.21 10.3-15.38Z"
      fill="#E21F23"
    />
    <Path
      d="M327.61 53.52a10.7 10.7 0 1 1-10.79-10.6 10.69 10.69 0 0 1 10.79 10.6ZM335.47 61.06s-1.66 8.15-2.34 9.5a3.7 3.7 0 0 1-2.13 1.73l.07-5.72Z"
      fill="#263238"
    />
    <Path
      d="M333.19 49.41a4 4 0 0 1 3.33 1.14c1.44 1.45.69 6-1 10.51l-3.26.4Z"
      fill="#263238"
    />
    <Path
      d="M331.23 61.47c.66.39 1.58-.88 2.38-1.73s3.43-2 4.76.73-1.1 6.56-3.17 7.23c-3.56 1.15-4.12-1.13-4.12-1.13l-.15 13.29c-4.39 5.74-16.73 5.36-11.65-.63V75.3a22.55 22.55 0 0 1-4.82.29c-2.63-.38-4.3-2.44-5.12-5.28-1.33-4.56-1.84-8.25-.84-17.25 1.11-9.86 12.57-10.07 18.79-6.23s3.94 14.64 3.94 14.64Z"
      fill="#f28f8f"
    />
    <Path
      d="M331.24 62.57c.33 0 1.56-2.08 2.37-2.83 1.12-1-.42-10.33-.42-10.33a4.49 4.49 0 0 0-.82-5c-1.56-1.69-3.74-3.75-10.75-2.86-5 .63-10.43 2.24-14.84.12a7.48 7.48 0 0 0 2.67 7.93s1.61 1 5.8 1.49a62.2 62.2 0 0 0 11.6-.32c1.21-.18 1.48 1 2.16 4.2.6 2.81 1.12 7.59 2.23 7.6Z"
      fill="#263238"
    />
    <Path
      d="m333.19 50.14 3.94-2.14a2.18 2.18 0 0 0-3-1 2.37 2.37 0 0 0-.94 3.14Z"
      fill="#263238"
    />
    <Path
      d="M319.26 75.3s5.86-1.23 7.9-2.34a6.72 6.72 0 0 0 2.84-2.83 9.28 9.28 0 0 1-1.58 3.32c-1.48 1.93-9.12 3.41-9.12 3.41Z"
      fill="#b16668"
    />
    <Path
      d="M320.54 60.1a1.18 1.18 0 1 0 1.16-1.24 1.2 1.2 0 0 0-1.16 1.24ZM321.75 55.65l2.53 1.35a1.52 1.52 0 0 0-.61-2 1.4 1.4 0 0 0-1.92.65Z"
      fill="#263238"
    />
    <Path
      d="m319.73 68.23-2.68 1a1.42 1.42 0 0 0 1.84.88 1.52 1.52 0 0 0 .84-1.88Z"
      fill="#b16668"
    />
    <Path
      d="m310 56.73 2.28-1.79a1.4 1.4 0 0 0-2-.29 1.53 1.53 0 0 0-.28 2.08ZM310.75 59.85a1.18 1.18 0 1 0 1.17-1.24 1.21 1.21 0 0 0-1.17 1.24Z"
      fill="#263238"
    />
    <Path d="m316.74 58.68-.33 7.08-3.7-1.23 4.03-5.85z" fill="#b16668" />
    <Path
      d="M372.17 155.16 362.11 166l-14.84-10.36-3.37 7.14a116.42 116.42 0 0 0 12.38 9.73c2 1.27 6 4.58 9.1 2.23 3.46-2.59 10.47-9 10.47-9l6.69-15.41c-4.54-.51-6.73 1.27-10.37 4.83Z"
      fill="#ffa8a7"
    />
    <Path
      d="m347.27 155.68-1.12 2.38-2.25 4.76.65.58.12.1.36.33.52.45.07.06 1.32 1.13.15.12.7.59.15.12a7 7 0 0 0 6.11-5.89Z"
      fill="#f28f8f"
      opacity={0.5}
    />
    <Path
      d="M356.46 143.52a84.1 84.1 0 0 1-1.36 10.17c-2.25 8.68-5.14 11.28-9 11.54-6.14.41-6.18-1.89-3.42-5.84a19.75 19.75 0 0 0 2.74-5.65 14.67 14.67 0 0 1-2.95 1.9 2.09 2.09 0 0 1-2.76-.75c-.21-.41.86-.83 2-2.26 1.5-1.83 2.09-3.15 4.15-4.67a3.62 3.62 0 0 0 1-2.79c0-1.68-1.32-20.18-1.32-24.74l-5.53-18.58C337.72 90 339.3 82 340.81 81c8.65 2.28 8.92 8.44 15.6 33.18 1.18 4.36.73 22.61.05 29.34Z"
      fill="#f28f8f"
    />
    <Path
      d="M345.44 153.74c3.26-.05 5.05 2.92 3 5.32s-6.76 1.93-6.76 1.93l-.41.85c-.69-.33-1.6-1.43-.93-3.31.78-2.16 2.17-4.74 5.1-4.79Z"
      fill="#ffa8a7"
    />
    <Path
      d="M339.17 80.4s-3.05 9.39-.32 18l4.61 17.46c5.72-.33 10.55-1.07 12.81-5.45 0 0-3.66-12.76-5.48-18.44C349.13 86.8 348 82 339.17 80.4Z"
      fill="#E21F23"
    />
    <Path
      d="M339.17 80.4s-3.05 9.39-.32 18l4.61 17.46c5.72-.33 10.55-1.07 12.81-5.45 0 0-3.66-12.76-5.48-18.44C349.13 86.8 348 82 339.17 80.4Z"
      opacity={0.1}
    />
    <Path
      d="M227.79 247.37a40.52 40.52 0 0 0-.62 6.5c-.07 3.95-.8 5.35-.89 8.59-.11 3.7.53 5.08 2 7.16s5.44 4 7.11.45 1.45-5.82.92-9.45-.62-5.82-.94-8.69c-.28-2.52-.62-4.44-.62-4.44Z"
      fill="#263238"
    />
    <Path
      d="m240.88 212.56-13-.24c.2 8.68-.31 26.9-.09 35.05.05 1.53 0 11.91.54 15.06.87 4.74 6.5 4.1 6.8 1.27s-.37-15-.35-16.21c-.05-5.22 4.29-22.63 5.57-32 .06-.49.47-2.49.53-2.93Z"
      fill="#ffa8a7"
    />
    <Path
      d="M257.51 240.92s-.66 0-.76.57-.15 1.63-.41 2.67a15 15 0 0 0-.45 5c.22 1.18 2.91 2.93 4.75 3.24s4.43 2.13 5.87 3.48a14.07 14.07 0 0 0 8.17 3.22c3.07 0 5.21-1 6.22-2.67s-.92-1.84-4.43-4.12l-2.72-1.77c-5.56-3.63-7.59-5.58-9.17-9.21Z"
      fill="#263238"
    />
    <Path
      d="M264.54 238.86c-.1-2.61 1.6-25.79 1.6-25.79l-10.33 1.35c1.42 18.24 2 24.46 1.67 26.69a2.51 2.51 0 0 0-.06.65c0 3 3.37 4.58 6 6.57 1 .79 3.7 3.2 6.82 4.27 2 .68 5.75 1 6.23-.28-3.19-2.44-7.54-5.63-10.08-8.41-1.21-1.33-1.76-2.44-1.85-5.05Z"
      fill="#ffa8a7"
    />
    <Path
      d="M231.32 130.9c-3.7 16.23 0 54.73 0 54.73-.17 2-2.48 6-3.33 14.84-1 10.47-.49 42.1-.49 42.1a9.41 9.41 0 0 0 7.89.15s8.57-44.79 9.89-55.2c1.14-9.08 5.87-31 5.87-31l5.25 31a54.52 54.52 0 0 0-2 9c-.59 6.12 2.83 40.33 2.83 40.33a10.65 10.65 0 0 0 7.54-.1s4.39-43.21 5-50.59c.74-9.87-.84-40.63-1.83-55Z"
      fill="#E21F23"
    />
    <Path
      d="M231.32 130.9c-3.7 16.23 0 54.73 0 54.73-.17 2-2.48 6-3.33 14.84-1 10.47-.49 42.1-.49 42.1a9.41 9.41 0 0 0 7.89.15s8.57-44.79 9.89-55.2c1.14-9.08 5.87-31 5.87-31l5.25 31a54.52 54.52 0 0 0-2 9c-.59 6.12 2.83 40.33 2.83 40.33a10.65 10.65 0 0 0 7.54-.1s4.39-43.21 5-50.59c.74-9.87-.84-40.63-1.83-55Z"
      opacity={0.5}
    />
    <Path
      d="m251.14 156.49 1.35-9.25s7.81-1 11.35-4.37a12.84 12.84 0 0 1-9.21 5.82l-1.38 8.36 2.61 27.32Z"
      opacity={0.1}
    />
    <Path
      d="m266.62 109.14-.29 6.89a124.77 124.77 0 0 1 2 16.54c-14.51 7.74-34.73 2.91-37.48-.28.54-5.7 5.84-13.26 7.35-15.83l-3-11.64s8-7.19 1.77-18.93l8.28-.89 10.86-.35 2.49.09c2.11 1.63 6.31 4.43 9 9.47 1.47 2.79 3.26 7.41-.98 14.93Z"
      fill="#f0f0f0"
    />
    <Path
      d="M238.26 85.76c-6.71.25-8.1 4.83-12.38 10.86-3 4.28-8.12 11-8.12 11s-8.8-12.84-9.29-14.76c-.59-2.31.18-3.34 0-5.88s-.67-4.19-.31-5.35-1.74-1-2.28.52-.23 3.3-.88 3.1-1.93-4-2.07-6.88-1.88-3.27-2.25-2.26a8.45 8.45 0 0 0-.45 1.69 3.69 3.69 0 0 0-3.46 1.66c-1.08 1.93-1.84 4.6-.9 8.74s3.74 6.79 4.71 8.46c1.45 2.5 6.69 17 12.36 23.92 2.59 3.14 5.72 2.12 8.06-.35l15-15.83c4.61-4.4 6.36-15.57 2.26-18.64Z"
      fill="#ffa8a7"
    />
    <Path
      d="m253.05 79.28-3.58.14A14.89 14.89 0 0 1 234 65.1a16.69 16.69 0 0 1 16-17.32 16.7 16.7 0 0 1 17.33 16 14.9 14.9 0 0 1-14.28 15.5Z"
      fill="#37474f"
    />
    <Path
      d="M225.49 87.47a11.51 11.51 0 0 0 11-11.93L236 62.3a11.49 11.49 0 0 0-11 11.93Z"
      fill="#37474f"
    />
    <Path
      d="M260.28 54.38c1.86 1 4.28 5.49 4.43 15.09.12 8.13-2.43 10.24-3.73 10.87s-3.87.38-6.37.06l.19 4.88s4.61 7 2.48 8.59c-3.28 2.48-8.45-.51-9.94-2.19a14.73 14.73 0 0 1-3.34-5.63l-.37-11.22s-1.41 1.47-4-.59c-2.11-1.71-2.91-4.7-1.38-6.38a3.56 3.56 0 0 1 5.7.91s1.51 1.41 2.87-1.35c9.5-1.71 14.18-8.42 13.46-13.04Z"
      fill="#ffa8a7"
    />
    <Path d="M254.38 67.08a1 1 0 1 1-1.11-1 1 1 0 0 1 1.11 1Z" fill="#263238" />
    <Path
      d="m256.09 74.66 2.41.66a1.22 1.22 0 0 1-1.51.91 1.3 1.3 0 0 1-.9-1.57Z"
      fill="#f28f8f"
    />
    <Path
      d="m263.23 63.78-2.12-1.35a1.19 1.19 0 0 1 1.7-.42 1.32 1.32 0 0 1 .42 1.77ZM253.41 63.25l-2.31 1a1.21 1.21 0 0 1 .61-1.65 1.32 1.32 0 0 1 1.7.65ZM262.78 66.76a1 1 0 1 1-1.11-1 1 1 0 0 1 1.11 1Z"
      fill="#263238"
    />
    <Path
      d="m257.56 66.37.4 6.15 3.2-1.13-3.6-5.02zM254.61 80.4c-2.62-.24-8.06-1.58-9-3.68a5.52 5.52 0 0 0 2 2.65c1.66 1.31 7.06 2.66 7.06 2.66Z"
      fill="#f28f8f"
    />
    <Path
      d="m234.18 63.18-4.54.17a2.22 2.22 0 0 1 2.11-2.35 2.38 2.38 0 0 1 2.43 2.22Z"
      fill="#37474f"
    />
    <Path
      d="m234.07 62.88-3.16-3.26a2.19 2.19 0 0 1 3.18 0 2.38 2.38 0 0 1 0 3.29ZM243.94 68.77l.17 4.28a2.92 2.92 0 0 0 2.8-3l-.16-4Z"
      fill="#37474f"
    />
    <Path
      d="M242 107.08a8.84 8.84 0 0 0 7.81 4.46 9.86 9.86 0 0 0 8.79-4.21s-.51 5.67-8.8 5.67c-7.2 0-7.8-5.92-7.8-5.92Z"
      fill="#e0e0e0"
    />
    <Path
      d="m390.45 149.48-17.57-1.08 1.12-16.76c.53-7.84 8.2-13.81 16-13.28a14.22 14.22 0 0 1 13.23 15.15l-.34 5.06a11.73 11.73 0 0 1-12.44 10.91Z"
      fill="#37474f"
    />
    <Path
      d="m372.93 187.54 29.45.68c.72 13.62 1.31 33.74 1.31 33.74.16 1.82 1.79 4.85 2 11.68.27 10.52-.05 20 0 22.41l.17 4.33v.16c-.16 2-6.71 4.13-6.71 2.82 0 0 .85-1.46-.47-8-.92-4.63-5.69-21-7.61-30.23-1.55-7.42-3.91-19.87-3.91-19.87l-2.71 16.12a55 55 0 0 1 .1 9.74c-.86 7.21-2.32 17.43-2.47 21.41.41 5.23-3.72 4.55-6.7 4 0 0 0-.44-.06-1.6l-.09-2.34c-.5-5.53-2.4-26.68-2.83-32.46-.86-11.22.48-23.97.53-32.59Z"
      fill="#ffa8a7"
    />
    <Path
      d="M406.75 264.81c.34.11.15 1.17 0 1.52a3.66 3.66 0 0 1-1.86 1.4 5.32 5.32 0 0 0-3 2.24 6 6 0 0 1-3.41 3.64 10 10 0 0 1-6.85.69c-1.92-.57-2.53-1.53-2.64-2a11.1 11.1 0 0 1 0-2Z"
      fill="#37474f"
    />
    <Path
      d="M404.05 266.91a5.4 5.4 0 0 0 2.67-1.73c.19-2.27-.5-2.88-.42-4.13.15-2.46-.2-2.29-.49-2.17v1A3.21 3.21 0 0 1 405 261a1.78 1.78 0 0 1-1.2.2 1 1 0 0 1-.48-.16 1.43 1.43 0 0 1-.32-.44 3.9 3.9 0 0 0-1-1.28 4 4 0 0 0-3.23-.7 1.13 1.13 0 0 0-.38.12c-.27.18-.23.57-.3.89a9.46 9.46 0 0 1-2.71 4 14.23 14.23 0 0 1-2.57 2.21 12.58 12.58 0 0 0-2.72 2 3.81 3.81 0 0 0-.84 3.4 2.93 2.93 0 0 0 1.92 1.81 8.79 8.79 0 0 0 5.76.05 6.79 6.79 0 0 0 3.91-3.09 6.55 6.55 0 0 1 3.21-3.1Z"
      fill="#E21F23"
    />
    <Path
      d="M404.05 266.91a5.4 5.4 0 0 0 2.67-1.73c.19-2.27-.5-2.88-.42-4.13.15-2.46-.2-2.29-.49-2.17v1A3.21 3.21 0 0 1 405 261a1.78 1.78 0 0 1-1.2.2 1 1 0 0 1-.48-.16 1.43 1.43 0 0 1-.32-.44 3.9 3.9 0 0 0-1-1.28 4 4 0 0 0-3.23-.7 1.13 1.13 0 0 0-.38.12c-.27.18-.23.57-.3.89a9.46 9.46 0 0 1-2.71 4 14.23 14.23 0 0 1-2.57 2.21 12.58 12.58 0 0 0-2.72 2 3.81 3.81 0 0 0-.84 3.4 2.93 2.93 0 0 0 1.92 1.81 8.79 8.79 0 0 0 5.76.05 6.79 6.79 0 0 0 3.91-3.09 6.55 6.55 0 0 1 3.21-3.1Z"
      opacity={0.5}
    />
    <Path
      d="M400.9 262.61a5 5 0 0 0-3.64-1.21.76.76 0 0 0-.58.78 5.46 5.46 0 0 1 3.88 1.26c.24.2.45.22.65 0s-.11-.66-.31-.83ZM399.83 264.27A5.56 5.56 0 0 0 396 263s-.8.22-.81.83a5.16 5.16 0 0 1 4.09 1.24.49.49 0 0 0 .79-.16.58.58 0 0 0-.24-.64ZM398.44 266.65a.54.54 0 0 0-.11-.71 5.59 5.59 0 0 0-3.82-1.42s-.95.26-1.08.86a6 6 0 0 1 4.19 1.38.56.56 0 0 0 .81-.09Z"
      fill="#f5f5f5"
    />
    <Path
      d="M397.3 268.91a4.29 4.29 0 0 1 1.08 3.51 6.89 6.89 0 0 1-1.45.64 8.79 8.79 0 0 1-5.76-.05c-.82-.31-2-.92-2.26-1.86a4.24 4.24 0 0 1 1-3.6 3.09 3.09 0 0 1 1.28-.83c1.69-.55 4.62.49 6.11 2.19Z"
      fill="#f0f0f0"
    />
    <Path
      d="M383 257.58c.34.11.16 1.18 0 1.52a3.66 3.66 0 0 1-1.86 1.4 5.4 5.4 0 0 0-3 2.24 6 6 0 0 1-3.41 3.64 10 10 0 0 1-6.84.69c-1.93-.57-2.54-1.52-2.65-2a12.16 12.16 0 0 1 0-2Z"
      fill="#37474f"
    />
    <Path
      d="M380.32 259.68A5.35 5.35 0 0 0 383 258c.19-2.26-.5-2.88-.42-4.13.15-2.45-.2-2.29-.49-2.17v1a3.19 3.19 0 0 1-.82 1.16 1.79 1.79 0 0 1-1.2.2 1 1 0 0 1-.48-.16 1.27 1.27 0 0 1-.33-.43 3.59 3.59 0 0 0-4.22-2 1 1 0 0 0-.38.11c-.27.19-.23.57-.3.89a9.46 9.46 0 0 1-2.71 4 14.23 14.23 0 0 1-2.57 2.21 12.22 12.22 0 0 0-2.71 2 3.82 3.82 0 0 0-.85 3.4 2.93 2.93 0 0 0 1.93 1.81 8.59 8.59 0 0 0 5.75.05 6.76 6.76 0 0 0 3.91-3.09 6.55 6.55 0 0 1 3.21-3.17Z"
      fill="#E21F23"
    />
    <Path
      d="M380.32 259.68A5.35 5.35 0 0 0 383 258c.19-2.26-.5-2.88-.42-4.13.15-2.45-.2-2.29-.49-2.17v1a3.19 3.19 0 0 1-.82 1.16 1.79 1.79 0 0 1-1.2.2 1 1 0 0 1-.48-.16 1.27 1.27 0 0 1-.33-.43 3.59 3.59 0 0 0-4.22-2 1 1 0 0 0-.38.11c-.27.19-.23.57-.3.89a9.46 9.46 0 0 1-2.71 4 14.23 14.23 0 0 1-2.57 2.21 12.22 12.22 0 0 0-2.71 2 3.82 3.82 0 0 0-.85 3.4 2.93 2.93 0 0 0 1.93 1.81 8.59 8.59 0 0 0 5.75.05 6.76 6.76 0 0 0 3.91-3.09 6.55 6.55 0 0 1 3.21-3.17Z"
      opacity={0.5}
    />
    <Path
      d="M377.17 255.38a4.93 4.93 0 0 0-3.64-1.2.73.73 0 0 0-.57.77 5.44 5.44 0 0 1 3.87 1.26c.24.2.46.22.66 0s-.11-.66-.32-.83ZM376.1 257a5.52 5.52 0 0 0-3.85-1.28s-.79.21-.81.82a5.15 5.15 0 0 1 4.09 1.24.49.49 0 0 0 .79-.16.58.58 0 0 0-.22-.62ZM374.72 259.42a.56.56 0 0 0-.12-.71 5.54 5.54 0 0 0-3.82-1.41s-.95.25-1.08.85a6 6 0 0 1 4.19 1.38.57.57 0 0 0 .82-.09Z"
      fill="#f5f5f5"
    />
    <Path
      d="M373.57 261.69a4.26 4.26 0 0 1 1.08 3.5 6.67 6.67 0 0 1-1.45.64 8.59 8.59 0 0 1-5.75-.05c-.83-.31-2-.92-2.27-1.86a4.24 4.24 0 0 1 1-3.6 3.07 3.07 0 0 1 1.29-.83c1.68-.55 4.61.51 6.1 2.2ZM396.7 152.38a6.78 6.78 0 0 1 5.45 5.2 21.55 21.55 0 0 1 .51 4.71l-1.09 15.23 1.09 13.29c-4.35 4.74-20.29 6.1-29.78-.84 0 0 .47-24.26 1.18-29 1.24-8.3 5.69-10.84 9.69-10.53l9.57 1.37Z"
      fill="#f0f0f0"
    />
    <Path
      d="m373.07 181.87-9 26.84s2.4 9 23.5 8.64 23-10.32 23-10.32L402 183.29Z"
      fill="#E21F23"
    />
    <Path
      d="M401.57 177.52a34.88 34.88 0 0 1-28.34-1.15c-.14 2.06-.21 3.93-.16 5.5 0 0 2.8 4.25 13.85 4.35 10 .09 15.12-2.93 15.12-2.93a36.13 36.13 0 0 0-.47-5.77Z"
      fill="#455a64"
    />
    <Path
      d="M412.09 192.17c-.21 2.79-.38 5.22-.45 7.35-.22 6.41-2.19 8.14-3.59 8.47-3.9.93-5.5-.81-4.17-3.67a13.36 13.36 0 0 0 .8-2.22c.06-.21.51-1.59 0-1.33a1 1 0 0 0-.29.28 2.65 2.65 0 0 1-2.23.95.64.64 0 0 1-.45-.19c-.18-.24 0-.57.17-.8a27 27 0 0 0 2.06-3.36 9.37 9.37 0 0 0 1.33-3.23c.07-.83.1-13.36-.62-16.38l-6.39-11.7c-2.51-5.54-3.81-11.53-.9-13.82 6.63.44 7.48 5.19 14.77 20.34 1.57 3.33.3 14.9-.04 19.31ZM395.8 137.34c.52.37.64.54 1.07-.32.94-1.87 4-2.37 4.9 0s-1.37 5.34-3.13 5.75c-3 .7-3.32-1.23-3.32-1.23l-1.05 10.64a9.52 9.52 0 0 1-7.59 4.9c-5.29.31-4.9-4.08-3-6.06l.23-3a12.72 12.72 0 0 1-3.85-.68c-1.63-.58-2.82-1.68-3.72-4.3-1.22-3.53-1.13-7.27.5-14.19 1.87-8 11.87-7.48 16.74-3.87s2.22 12.36 2.22 12.36Z"
      fill="#ffa8a7"
    />
    <Path
      d="m396.87 137-.23 2.39a2.3 2.3 0 0 1-2.12-2.4l.25-5 3.24.84Z"
      fill="#37474f"
    />
    <Path
      d="M386.77 118.53a13.17 13.17 0 0 0-14.43 11.78l-.17 1.7 6.41.65a6 6 0 0 0 6.42-4.56l-.29 5.18a4.78 4.78 0 0 0 5.17-3.85l.16-.83a5.65 5.65 0 0 0 5.08 5.74l1.54.06.62-9.2Z"
      fill="#37474f"
    />
    <Path
      d="M386.41 135.91a1.1 1.1 0 1 0 1.19-1.06 1.13 1.13 0 0 0-1.19 1.06ZM388.09 132.71l2.23 1.47a1.4 1.4 0 0 0-.4-1.9 1.31 1.31 0 0 0-1.83.43Z"
      fill="#263238"
    />
    <Path
      d="m385.92 142.31-3.07.89a1.56 1.56 0 0 0 1.95 1.14 1.66 1.66 0 0 0 1.12-2.03Z"
      fill="#f28f8f"
    />
    <Path
      d="m376.89 131.73 2.59-.73a1.28 1.28 0 0 0-1.61-.95 1.42 1.42 0 0 0-.98 1.68ZM377.65 135.36a1.12 1.12 0 0 0 1 1.22 1.14 1.14 0 0 0 .18-2.28 1.12 1.12 0 0 0-1.18 1.06Z"
      fill="#263238"
    />
    <Path
      d="m383.51 134.46-.77 5.82-2.97-1.27 3.74-4.55zM383.89 148s5.26-.14 7.74-1.47a6.65 6.65 0 0 0 2.52-2.15 6.56 6.56 0 0 1-1.54 2.64c-2.55 2.58-8.82 2.26-8.82 2.26Z"
      fill="#f28f8f"
    />
    <Path
      d="m208 203.78-9 20.52s-13.8 11.6-23.89 20.56c-.74-2.61-1.58-12.17-1.58-12.17 4.26-5.22 14.53-15.88 16.2-17.73 5.82-6.48 11.2-11.71 18.27-11.18Z"
      fill="#ffbda7"
    />
    <Path
      d="M159 165.46h1.48a17.25 17.25 0 0 1 17.25 17.25v31.61a22.88 22.88 0 0 1-22.88 22.88h-13.1v-54.48A17.25 17.25 0 0 1 159 165.46Z"
      fill="#263238"
      transform="rotate(.47 160.526 201.708)"
    />
    <Path
      d="M169 205.5c4.22.36 7.24 1.23 13.32 9.46 2.51 3.39 11 15.84 13.91 20.1 2.85.44 8.92 2.15 8.92 2.15 2.44 2.37.87 8 .45 10.07l-11.8-1.09a6.19 6.19 0 0 1-4.06-2.07c-8.81-10-14.7-17.22-14.7-17.22ZM163 365.9c4.56-14.78 15.42-45.2 17-49.78 2-5.85.2-50.5-2.88-63.65l-34.72-.58c-7.69 10.59-7.69 20.36-5.41 44.49 1.1 11.51 2.59 24.78 2.59 24.78s-3.92 7.89-2.77 21.16c.5 5.85 2.11 23.73 3.37 31.37.28 2.14.45 4.24.53 6.22 0 .52.08 1 .08 1.52v1.29s8.8 7 8.8-2.53v-1.34l.39-5.68c.52-6.67 1.66-18.9 2.54-26.85 1.49-13.54 2.17-20.76 3-27.78 1.1-9.59 3.11-40.72 3.11-40.72l5.21 37a35.18 35.18 0 0 0-5.56 14.27c-.56 4.28-2 13.55-2.94 24.08-.39 4.19-.64 8.4-1.13 12.59-.1.8-.19 1.64-.28 2.58 0 0 8.38 1.46 8.61.37.26-1.36.46-2.81.46-2.81Z"
      fill="#b16668"
    />
    <Path
      d="M141.78 252.79a31.39 31.39 0 0 0-6.11 16.77c-.66 9-1.24 36.55-1.24 36.55s1.74 6.53 24.18 5.17 23.39-6.92 23.39-6.92-.78-17.55-1.46-26.42-1.37-22.71-4.1-28Z"
      fill="#455a64"
    />
    <Path
      d="M139.36 387c-.42 1.9.19 7.05.19 7.05a6.9 6.9 0 0 0 3.8 2.88l1.12-7.22Z"
      fill="#263238"
    />
    <Path d="m143.35 396.96 6.36-2.27 1.4-5.73-7.11 3.83-.65 4.17z" />
    <Path
      d="M149.6 380.19v-1.34c.88.4 3 7.36 5.68 12.11 2.48 4.33 7.22 6.66 8.79 8.53 2.1 2.51-1.77 5.9-8.16 4.7-3.17-.59-8-2.49-9.34-4.59s-1.88-6-3-7.53-3.65-3.27-4.23-5a9.66 9.66 0 0 1-.11-4.92c.46-1.63.84-3.6 1.4-3.44l.07 1.24c.44.85 2 1.82 5.22 1.92 1.54 0 3.02-.27 3.68-1.68Z"
      fill="#37474f"
    />
    <Path
      d="M151.33 372.84c-.62 1.84-.57 7-.57 7a6.89 6.89 0 0 0 3.47 3.27l1.89-7.06Z"
      fill="#263238"
    />
    <Path d="m154.23 383.14 6.56-1.57 2.01-5.55-7.48 3.04-1.09 4.08z" />
    <Path
      d="m162.55 367.44.49-1.54c.83.49 1.51 7.54 3.7 12.55 2 4.57 6.47 7.4 7.83 9.43 1.82 2.71-2.4 5.67-8.62 3.8-3.09-.94-7.68-3.34-8.8-5.57s-1.23-6.15-2.15-7.8-3.29-3.65-3.67-5.47c-.22-1 .44-3 1.12-4.76s1.3-3.48 1.84-3.26l-.13 1.24c.35.9 1.08 1.88 4.26 2.31 1.52.21 3.32.39 4.13-.93Z"
      fill="#37474f"
    />
    <Path
      d="M176.11 244.05c.5-2.32 1.08-6.7 1.43-9.9 5.85-5.46 4.21-11.43 2.08-15.82-4.25-8.78-8.79-12.83-10.7-12.83h-12c-5.75.68-9.67 1.33-11.06 1.63-3.54.77-3.44 3.34-3.64 7.09a39.8 39.8 0 0 0 .38 11.61l4.84 10.46.43 6.43s-9.22 11.34-11.35 20.15a27.06 27.06 0 0 0 8.62-6.17s3.52 8.91 18.42 7.85c11.31-.8 15-7.44 15-7.44Z"
      fill="#E21F23"
    />
    <Path
      d="M143.55 248.62c0 1.28 1.67 3.69 3 3.78a2.56 2.56 0 0 0 2.93-1.89c.72-1.68 0-5.91 0-5.91l-5.8 3.82Z"
      opacity={0.1}
    />
    <Path
      d="M147.56 238.39a14.29 14.29 0 0 0 2.13 5.69c.7 1.27-.05 8.13-3.13 7.13-2.44-.8-3.68-3.32-3.34-6.8a24.47 24.47 0 0 1 1.45-6.48 6.89 6.89 0 0 1 2.38-2.45l.37.8Z"
      fill="#ffbda7"
    />
    <Path
      d="M152.45 228.85a9.38 9.38 0 0 0 8.2 6.06 9.89 9.89 0 0 0 9-4.14s-1.14 6.82-9.24 5.78-7.96-7.7-7.96-7.7Z"
      opacity={0.1}
    />
    <Path
      d="M170.53 172.71c2 1.12 4.54 6 4.55 16.38 0 8.8-2.79 11-4.21 11.7s-4.18.34-6.87 0l.12 5.28s7.61 7.92 1.47 8.85c-3.07.47-7-1.38-9.66-3.39a10.7 10.7 0 0 1-3.55-5.45l-.22-11.48s-1.54 1.57-4.27-.7c-2.26-1.88-3.08-5.12-1.4-6.91a3.86 3.86 0 0 1 6.16 1.07s1.57.09 3.08-2.87a19.26 19.26 0 0 0 8.31-1.54c7.04-3.12 7.25-8.65 6.49-10.94Z"
      fill="#b16668"
    />
    <Path
      d="M163.66 186.6a1.11 1.11 0 1 1-1.19-1.07 1.14 1.14 0 0 1 1.19 1.07Z"
      fill="#263238"
    />
    <G
      style={{
        opacity: 0.5,
        mixBlendMode: 'multiply',
      }}>
      <Path
        d="m165.19 194.34 2.87.84a1.46 1.46 0 0 1-1.83 1.06 1.56 1.56 0 0 1-1.04-1.9Z"
        fill="#9a4a4d"
      />
    </G>
    <Path
      d="m173.57 182.92-2.28-1.49a1.31 1.31 0 0 1 1.85-.43 1.43 1.43 0 0 1 .43 1.92ZM162.41 182.08l-2.52 1a1.32 1.32 0 0 1 .69-1.77 1.43 1.43 0 0 1 1.83.77ZM173 186.13a1.12 1.12 0 0 1-1 1.23 1.14 1.14 0 0 1-1.19-1.07 1.1 1.1 0 1 1 2.2-.16Z"
      fill="#263238"
    />
    <G
      style={{
        opacity: 0.5,
        mixBlendMode: 'multiply',
      }}>
      <Path d="m167.72 185.63.33 6.65 3.48-1.17-3.81-5.48z" fill="#9a4a4d" />
    </G>
    <G
      style={{
        opacity: 0.5,
        mixBlendMode: 'multiply',
      }}>
      <Path
        d="M164 200.75c-2.84-.3-8.69-1.83-9.65-4.11a5.91 5.91 0 0 0 2.11 2.89c1.77 1.44 7.58 3 7.58 3Z"
        fill="#9a4a4d"
      />
    </G>
    <Path
      d="m152.65 188 .11 4.63a3.16 3.16 0 0 0 3.07-3.23l-.1-4.27ZM198.88 202.5H218a15.73 15.73 0 0 0 15.73-15.73v-3.57c.14-10-7.59-18.24-17.21-18.38-9.61-.14-17.52 7.86-17.67 17.87v1c.15 9.2.03 18.81.03 18.81Z"
      fill="#263238"
    />
    <Path
      d="M222.6 187.45s-4.46-2.54-9.35-5.86a30.22 30.22 0 0 1-7.87-7.83s-.18 7.23 4.6 11.16 11.79 4 12 4.05.62-1.52.62-1.52Z"
      fill="#E21F23"
    />
    <Path
      d="m230.46 173.19 3.93-2.31a2.16 2.16 0 0 0-3-1 2.53 2.53 0 0 0-.93 3.31Z"
      fill="#263238"
    />
    <Path
      d="m230.37 172 1.17-4.54a2.18 2.18 0 0 0-2.79 1.57 2.49 2.49 0 0 0 1.62 2.97ZM199.62 370.41l-1.21-1.55c-.59 3.59-4 7.59-7.27 10.57-2.59 2.37-6.46 5.27-6.36 7.69.12 2.9 4.92 3.67 7.35 3.24 2.81-.5 6.46-2.1 8.44-4.54 1.39-1.71 3.37-4 4.36-5.3s3.29-2.85 3.83-4.41c.29-.86-.06-2.74-.47-4.32s-.8-3.05-1.29-2.92v.9c-.6.66-3.34 1.53-5.2 1.61-1.2.04-2.19-.05-2.18-.97ZM236.44 376.07a40.22 40.22 0 0 1 .64 6.65c.07 4 .82 5.47.91 8.78.12 3.79-.68 6.14-2.21 8.27s-6.45 3-8.15-.59-1.48-6-.94-9.67.64-6 1-8.89c.29-2.58.63-4.54.63-4.54Z"
      fill="#263238"
    />
    <Path
      d="M238.14 330c-.94-9.74-2.34-13.82-2.53-16 0 0 2.22-22.94 2.81-42.28.28-9.31 0-19.73-8.85-30.61l-28.52-.45c-3.48 12.91-7 60.13-5.57 74.06.81 8.08 3.26 49 3.26 49v3.25a8.91 8.91 0 0 1-1.53 4.45 39.33 39.33 0 0 1-5.19 7.07 38.49 38.49 0 0 0-2.56 3c-2 2.34-.43 3.32 2.67 2.9 3.26-.43 6-3 7.43-4.95 2.14-3 7-7.72 7.4-9.43v-.21s.19-2.58.46-5.66S212.79 336 212.9 327a71.28 71.28 0 0 0-1.07-10.64l3.54-32.87s1.86 23.64 3.11 33.62c1.1 8.67 5.79 34.22 8.41 45.74a56.8 56.8 0 0 1 1.39 13.19c0 1.28-.43 11.5 0 14.39.81 6 6.17 5.49 7.22.68.9-4.1.94-13.51 1-15.08 0-1.13.09-2.4.16-3.74.02-.78 2.58-30.91 1.48-42.29Z"
      fill="#ffbda7"
    />
    <Path
      d="M201.05 240.67s-1.93 8.12-3.17 18.12-3.43 38-3.14 47 2 30.18 2 30.18 1.83 2.13 7.87 2.37 7.75-2 7.75-2 1-7.32 1-11a46.34 46.34 0 0 0-1.13-9.3l3.34-31.24s1.21 20.46 2.52 31.49 3.75 22.79 3.75 22.79 1.83 2.36 9.23 2 7.93-3.59 7.93-3.59.24-13.65-2.86-23.2c0 0 2-28 2.43-35s1.71-23.15-5.06-33.14-9.22-10.15-9.22-10.15Z"
      fill="#E21F23"
    />
    <Path
      d="m215.48 284.89-1-17.87s-6.75-2.89-9.31-5.88c0 0 1.91 4.14 7.79 7.13l1.2 17.21-1.16 22.6Z"
      opacity={0.15}
    />
    <Path
      d="m224.35 204.92 3.68.67c-2.05 10 4.67 18.71 4.67 18.71l-2.42 16.37a23.75 23.75 0 0 1 3.14 5.48c-5.79 7.22-25.42 8.74-34 1.93a69.26 69.26 0 0 1 1.37-7.55l-1.41-9.53c-5-5.9-2.86-10.82-1.33-13.75a44.91 44.91 0 0 1 9.95-13.47l4.55-.15Z"
      fill="#455a64"
    />
    <Path
      d="M227.27 205.45c5.68.69 8 5.41 10.84 10.08 2.51 4.09 5 9.14 7.83 13.12 2.57-4.37 8.41-11.52 10.51-15.06s1.52-5.42 2.32-7.77a25.37 25.37 0 0 0 1.44-6.82c.23-2.39 2.24-.83 2.49 1 .18 1.43-1 4.07.45 3.63s3.33-5.74 3.69-7.25 1.32-3.28 2.65-2.49c.53.32.43 1 .35 2.08 1.17 0 2.17.6 2.83 3.41a14 14 0 0 1-1.18 10.12c-2 3.7-2.81 3.53-6.51 8.69s-10.2 18.89-15.14 24.85c-3.11 3.75-5.54 3.47-8.72-.23-3.89-4.51-7.5-11-11.44-16.42s-6.16-14.9-2.41-20.94ZM205.38 173.76c-1.74 1.59-3.9 5-3.73 15.14.15 8.56 2.8 10.69 4.13 11.3s3.92.26 6.43-.16v4.4s-4.38 4.4-4.17 6.75 5.86 3.46 9.11 1.17a27 27 0 0 0 5.9-6.29l.12-12.27s1.46 1.5 4-.76c2.07-1.87 2.79-5 1.18-6.75a3.53 3.53 0 0 0-5.73 1.16 25.8 25.8 0 0 1-10.39-3.12c-5.07-2.83-6.3-7.33-6.85-10.57Z"
      fill="#ffbda7"
    />
    <Path
      d="M205.51 186.14a1.06 1.06 0 0 1-1 1.13 1.07 1.07 0 0 1-1.09-1 1 1 0 1 1 2.07-.1ZM214.28 186.38a1 1 0 1 1-1.09-1 1 1 0 0 1 1.09 1Z"
      fill="#263238"
    />
    <Path
      d="m211.54 194.32-2.4.78a1.24 1.24 0 0 0 1.56.91 1.38 1.38 0 0 0 .84-1.69Z"
      fill="#f0997a"
    />
    <Path
      d="m202.72 183.19 2.1-1.5a1.2 1.2 0 0 0-1.74-.38 1.44 1.44 0 0 0-.36 1.88ZM222.59 186.43v3.62a1.7 1.7 0 0 1-1.76-1.73 1.87 1.87 0 0 1 1.76-1.89Z"
      fill="#263238"
    />
    <Path d="m208.75 185.36.03 7.01-3.27-1.07 3.24-5.94z" fill="#f0997a" />
    <Path
      d="M224.11 185.75h-6.93a3.3 3.3 0 0 0-3.22-2.62h-.51a3.3 3.3 0 0 0-3 2 2.85 2.85 0 0 0-1.93-.83 2.43 2.43 0 0 0-1.7.5 3.29 3.29 0 0 0-2.87-1.71h-.51a3.3 3.3 0 0 0 0 6.6h.51a3.25 3.25 0 0 0 3.11-4.35 1.84 1.84 0 0 1 1.43-.49 2.2 2.2 0 0 1 1.75.89 3 3 0 0 0-.07.67 3.3 3.3 0 0 0 3.3 3.3h.53a3.3 3.3 0 0 0 3.3-3.3s0-.08 0-.12h6.18a6 6 0 0 1 .63-.54Zm-20.21 3.42h-.51a2.74 2.74 0 1 1 0-5.48h.51a2.74 2.74 0 0 1 0 5.48Zm10.06 0h-.51a2.74 2.74 0 1 1 0-5.48h.55a2.74 2.74 0 0 1 0 5.48Z"
      fill="#455a64"
    />
    <Path
      d="M212.21 200c2.65-.34 8.09-1.94 8.95-4.17a5.83 5.83 0 0 1-1.92 2.84c-1.63 1.44-7 3.06-7 3.06Z"
      fill="#f0997a"
    />
    <Path
      d="m116.35 310.17-33 .76c-.8 15.25-1.47 37.76-1.47 37.76-.17 2-2 5.44-2.2 13.08-.3 11.78.06 22.41 0 25.1l-.18 4.85v.18c.18 2.26 7.51 4.63 7.51 3.17 0 0-1-1.64.53-9 1-5.18 6.36-23.55 8.52-33.84 1.74-8.31 4.38-22.25 4.38-22.25l3 18a61.9 61.9 0 0 0-.12 10.9c1 8.07 2.6 19.51 2.77 24-.45 5.85 4.17 5.08 7.5 4.46 0 0 0-.5.07-1.79l.1-2.63c.56-6.18 2.69-29.86 3.17-36.33.97-12.5-.53-26.77-.58-36.42Z"
      fill="#b16668"
    />
    <Path
      d="M83.32 310.17s-.65 8.69-.87 13.79-.73 23.24-1.31 25.35a54 54 0 0 0-2 12.17s5.61 5 14.86 2.77c-.07-.37 2.41-8.38 3.43-13.84s2.76-15.67 2.76-15.67 2 11.44 2.12 13.63-.8 8.3 0 15.37a17.77 17.77 0 0 0 8.08 1.09c4.3-.51 6-2.77 6-2.77s1-14.2 1.24-19.67-.48-25.11-1.22-32.22Z"
      fill="#455a64"
    />
    <Path
      d="m100.15 334.74.88-5.45s7.26-.59 10.45-4.22c0 0-1.06 4.76-8.67 5.51l-1 5.27.29 11.32c-.53-3.96-1.2-8.17-1.95-12.43ZM78.48 396.67c-.38.13-.17 1.32 0 1.7a4.12 4.12 0 0 0 2.08 1.57 6 6 0 0 1 3.31 2.51 6.81 6.81 0 0 0 3.83 4.08 11.2 11.2 0 0 0 7.66.76c2.15-.63 2.83-1.7 3-2.25a12.83 12.83 0 0 0 0-2.23Z"
      fill="#37474f"
    />
    <Path
      d="M81.51 399c-1.42-.6-2.2-.87-3-1.93-.21-2.54.56-3.23.48-4.63-.17-2.75.22-2.56.54-2.43v1.07a3.58 3.58 0 0 0 .92 1.31 2.1 2.1 0 0 0 1.35.22 1.05 1.05 0 0 0 .54-.18 1.48 1.48 0 0 0 .36-.49 4.35 4.35 0 0 1 1.11-1.44 4.46 4.46 0 0 1 3.62-.77 1 1 0 0 1 .42.13c.3.2.26.64.34 1 .32 1.5 1.73 3.16 3 4.45a16.14 16.14 0 0 0 2.88 2.47 13.87 13.87 0 0 1 3 2.23 4.26 4.26 0 0 1 .94 3.81 3.25 3.25 0 0 1-2.15 2 9.08 9.08 0 0 1-2.82.57 9.3 9.3 0 0 1-3.62-.51 7.59 7.59 0 0 1-4.38-3.46 7.39 7.39 0 0 0-3.53-3.42Z"
      fill="#E21F23"
    />
    <Path
      d="M81.51 399c-1.42-.6-2.2-.87-3-1.93-.21-2.54.56-3.23.48-4.63-.17-2.75.22-2.56.54-2.43v1.07a3.58 3.58 0 0 0 .92 1.31 2.1 2.1 0 0 0 1.35.22 1.05 1.05 0 0 0 .54-.18 1.48 1.48 0 0 0 .36-.49 4.35 4.35 0 0 1 1.11-1.44 4.46 4.46 0 0 1 3.62-.77 1 1 0 0 1 .42.13c.3.2.26.64.34 1 .32 1.5 1.73 3.16 3 4.45a16.14 16.14 0 0 0 2.88 2.47 13.87 13.87 0 0 1 3 2.23 4.26 4.26 0 0 1 .94 3.81 3.25 3.25 0 0 1-2.15 2 9.08 9.08 0 0 1-2.82.57 9.3 9.3 0 0 1-3.62-.51 7.59 7.59 0 0 1-4.38-3.46 7.39 7.39 0 0 0-3.53-3.42Z"
      opacity={0.5}
    />
    <Path
      d="M85 394.21a5.58 5.58 0 0 1 4.08-1.35.82.82 0 0 1 .64.86 6.1 6.1 0 0 0-4.34 1.42c-.27.22-.51.25-.73 0s.15-.74.35-.93ZM86.23 396.07a6.14 6.14 0 0 1 4.31-1.44s.89.24.9.92c0 0-2-.53-4.58 1.39a.54.54 0 0 1-.88-.18.66.66 0 0 1 .25-.69ZM87.78 398.74a.62.62 0 0 1 .13-.8 6.24 6.24 0 0 1 4.27-1.59s1.07.29 1.21 1a6.75 6.75 0 0 0-4.69 1.54.62.62 0 0 1-.91-.1Z"
      fill="#f5f5f5"
    />
    <Path
      d="M89.06 401.27a4.73 4.73 0 0 0-1.2 3.92 7.09 7.09 0 0 0 1.62.72 9.3 9.3 0 0 0 3.62.51 9.08 9.08 0 0 0 2.82-.57c.92-.34 2.23-1 2.54-2.08a4.78 4.78 0 0 0-1.08-4 3.56 3.56 0 0 0-1.44-.93c-1.94-.64-5.21.52-6.88 2.43Z"
      fill="#f0f0f0"
    />
    <Path
      d="M105 388.58c-.38.13-.17 1.32 0 1.7a4.16 4.16 0 0 0 2.09 1.57c1 .34 2.76 1.27 3.31 2.51a6.81 6.81 0 0 0 3.82 4.08 11.21 11.21 0 0 0 7.66.76c2.16-.64 2.84-1.7 3-2.26a13.94 13.94 0 0 0 0-2.23Z"
      fill="#37474f"
    />
    <Path
      d="M108.07 390.93c-1.41-.6-2.19-.87-3-1.94-.21-2.53.56-3.22.47-4.62-.17-2.75.22-2.56.55-2.43v1.07a3.66 3.66 0 0 0 .92 1.31 2.07 2.07 0 0 0 1.34.22 1.12 1.12 0 0 0 .54-.18 1.36 1.36 0 0 0 .36-.49 4.28 4.28 0 0 1 1.12-1.44 4.48 4.48 0 0 1 3.61-.78 1.09 1.09 0 0 1 .43.14c.3.2.26.63.33 1 .32 1.5 1.73 3.16 3 4.45a16.09 16.09 0 0 0 2.88 2.46 14 14 0 0 1 3 2.23 4.28 4.28 0 0 1 1 3.81 3.29 3.29 0 0 1-2.16 2 9 9 0 0 1-2.82.57 9.34 9.34 0 0 1-3.62-.52 7.57 7.57 0 0 1-4.38-3.45 7.31 7.31 0 0 0-3.57-3.41Z"
      fill="#E21F23"
    />
    <Path
      d="M108.07 390.93c-1.41-.6-2.19-.87-3-1.94-.21-2.53.56-3.22.47-4.62-.17-2.75.22-2.56.55-2.43v1.07a3.66 3.66 0 0 0 .92 1.31 2.07 2.07 0 0 0 1.34.22 1.12 1.12 0 0 0 .54-.18 1.36 1.36 0 0 0 .36-.49 4.28 4.28 0 0 1 1.12-1.44 4.48 4.48 0 0 1 3.61-.78 1.09 1.09 0 0 1 .43.14c.3.2.26.63.33 1 .32 1.5 1.73 3.16 3 4.45a16.09 16.09 0 0 0 2.88 2.46 14 14 0 0 1 3 2.23 4.28 4.28 0 0 1 1 3.81 3.29 3.29 0 0 1-2.16 2 9 9 0 0 1-2.82.57 9.34 9.34 0 0 1-3.62-.52 7.57 7.57 0 0 1-4.38-3.45 7.31 7.31 0 0 0-3.57-3.41Z"
      opacity={0.5}
    />
    <Path
      d="M111.6 386.11a5.54 5.54 0 0 1 4.07-1.34.82.82 0 0 1 .64.86 6.1 6.1 0 0 0-4.34 1.42c-.27.22-.5.24-.73-.05s.12-.69.36-.89ZM112.79 388a6.18 6.18 0 0 1 4.31-1.44s.89.23.91.92c0 0-2-.53-4.58 1.39a.55.55 0 0 1-.89-.19.65.65 0 0 1 .25-.68ZM114.34 390.64a.62.62 0 0 1 .14-.8 6.24 6.24 0 0 1 4.27-1.58s1.06.28 1.21.95a6.78 6.78 0 0 0-4.69 1.55.62.62 0 0 1-.91-.1Z"
      fill="#f5f5f5"
    />
    <Path
      d="M115.63 393.17a4.74 4.74 0 0 0-1.21 3.93 7.25 7.25 0 0 0 1.62.71 9.34 9.34 0 0 0 3.62.52 9 9 0 0 0 2.82-.57c.93-.35 2.23-1 2.54-2.09a4.76 4.76 0 0 0-1.07-4 3.43 3.43 0 0 0-1.44-.93c-1.94-.63-5.22.52-6.88 2.43Z"
      fill="#f0f0f0"
    />
    <Path
      d="m117.86 280 8.47 10.28 13.09-8.59 3.37 7.13a117.13 117.13 0 0 1-12.36 9.71c-2 1.28-6.27 4.77-9 2a69.41 69.41 0 0 1-5.84-7.21Z"
      fill="#b16668"
    />
    <Path
      d="m139.42 281.69-6.81 4.47a7.07 7.07 0 0 0 6.14 6.14c2.37-2 4-3.48 4-3.48Z"
      fill="#9a4a4d"
    />
    <Path
      d="M105.44 271.84c5.45-.35 9.93 3.66 12.92 7.5 3.29 4.21 7.81 10.63 7.81 10.63a13.09 13.09 0 0 1-6.78 9.1l-11.78-13.69Z"
      fill="#E21F23"
    />
    <Path
      d="M86.23 272.87c-1.71 2.92-1.8 3.52-2.2 6.7-.18 1.47-2.24 4.94-2.24 6.89l1.61 13-.62 16.38c4.74 5.15 23.82 6.8 34.15-.75 0 0-1.09-26.6-1.87-31.8-1.34-9-6.19-11.79-10.53-11.46l-11.45.55Z"
      fill="#fafafa"
    />
    <Path
      d="M85.08 274.41c-1.86-.67-4.21.93-7.47 5-2.9 3.58-6.1 7.73-6.1 7.73S66 275.33 66 273.59s.35-1.86.5-4.09-.08-3.72.38-4.69-1.41-1-2.06.18-.59 2.84-1.13 2.59-1.2-3.69-1-6.23-1.23-3.08-1.68-2.24a7.23 7.23 0 0 0-.6 1.41 3.25 3.25 0 0 0-3.21 1c-1.17 1.55-2.15 3.78-1.83 7.5s2.43 6.28 2.83 7.94 4 15.6 8.13 22.28c1.87 3.05 4.94 2.79 7.06.67 2.85-2.86 9.78-9.95 9.78-9.95 4.07-5.96 5.11-12.39 1.91-15.55Z"
      fill="#b16668"
    />
    <Path
      d="M86.64 272.85c-.61 0-4 .07-6 2.18a87.82 87.82 0 0 0-9 11.54s4.72 5.27 4.09 11.93l9-8.6a20.61 20.61 0 0 0 1.91-17.05Z"
      fill="#E21F23"
    />
    <Circle
      cx={106.33}
      cy={251.86}
      r={9.73}
      fill="#263238"
      transform="rotate(-76.72 106.323 251.854)"
    />
    <Path
      d="M89.08 258s1.52 7.13 2.13 8.3a3.26 3.26 0 0 0 1.87 1.51l-.11-5ZM91 247.76a3.52 3.52 0 0 0-2.91 1c-1.25 1.28-.55 5.28 1 9.19l2.86.33Z"
      fill="#263238"
    />
    <Path
      d="M92.8 258.3c-.57.35-1.39-.75-2.1-1.49a2.48 2.48 0 0 0-4.16.67c-1.17 2.47 1 5.74 2.83 6.31 3.13 1 3.6-1 3.6-1l.24 11.29a10.07 10.07 0 0 0 7.6 5.77c5.55.75 5.49-3.9 3.67-6.14v-3.17a13.46 13.46 0 0 0 4.1-.41c1.77-.48 3.11-1.54 4.28-4.23 1.56-3.61 1.76-7.57.61-15-1.33-8.59-11.9-8.84-17.32-5.43S92.8 258.3 92.8 258.3Z"
      fill="#b16668"
    />
    <Path
      d="M92.8 259.27c-.29 0-1.38-1.81-2.1-2.46-1-.9.29-9.05.29-9.05a3.86 3.86 0 0 1 1-4.12c1.36-1.49 3.62-1.65 7.48-2.26 2-.32 5.1-.89 7.13-1.26 2.41-.45 4.42-.93 6.12.56a4.29 4.29 0 0 1 1.19 4.8 3.9 3.9 0 0 1-.69 1 4 4 0 0 1-1.74 1.2s-1.05.94-4.72 1.38a53.66 53.66 0 0 1-10.16-.19c-1.06-.15-1.29.88-1.86 3.7-.55 2.49-.97 6.68-1.94 6.7Z"
      fill="#263238"
    />
    <Path
      d="m91 248.4-3.46-1.86a1.9 1.9 0 0 1 2.62-.87 2.05 2.05 0 0 1 .84 2.73Z"
      fill="#263238"
    />
    <Path
      d="M104.48 270.51s-5.53-.57-8-2.18a7 7 0 0 1-2.48-2.46 6.85 6.85 0 0 0 1.41 2.9c2.47 2.92 9.1 3.09 9.1 3.09Z"
      fill="#9a4a4d"
    />
    <Path
      d="M102.53 257a1.16 1.16 0 1 1-1.16-1.21 1.18 1.18 0 0 1 1.16 1.21ZM101 253.54l-2.47 1.36a1.47 1.47 0 0 1 .58-2 1.37 1.37 0 0 1 1.89.64Z"
      fill="#263238"
    />
    <Path
      d="m102.54 263.82 3.15 1.18a1.65 1.65 0 0 1-2.14 1.05 1.76 1.76 0 0 1-1.01-2.23Z"
      fill="#9a4a4d"
    />
    <Path
      d="m112.45 254.18-2.25-1.73a1.35 1.35 0 0 1 2-.3 1.48 1.48 0 0 1 .25 2.03ZM111.86 256.5a1.16 1.16 0 1 1-1.16-1.21 1.18 1.18 0 0 1 1.16 1.21Z"
      fill="#263238"
    />
    <Path d="m105.7 255.75.34 6.19 3.24-1.1-3.58-5.09z" fill="#9a4a4d" />
    <Path
      d="M129.17 268.86a62.07 62.07 0 0 0 2.14 10.9c2.94 8.46 5.42 11.21 9.26 11.47 6.12.41 6.16-1.89 3.41-5.83a19.56 19.56 0 0 1-2.73-5.64 14.25 14.25 0 0 0 2.94 1.89 2.11 2.11 0 0 0 2.76-.74c.2-.42-.86-.83-2-2.26-1.5-1.83-2.27-3.29-4.33-4.8a4.26 4.26 0 0 1-1.24-2.53c-.31-1.23-.53-20.9.58-25l5.27-18.91c4.29-2.94 7.19-17.23.67-20.33-8.26.9-10 4.6-17 34-1.04 4.42-1.04 16.62.27 27.78Z"
      fill="#b16668"
    />
    <Path
      d="M141.25 279.76c-3.26-.05-5.05 2.91-3 5.3S145 287 145 287l.42.85c.68-.34 1.6-1.44.93-3.32-.79-2.15-2.18-4.73-5.1-4.77Z"
      fill="#9a4a4d"
    />
    <Path
      d="M314.87 352.36V356a18.8 18.8 0 0 0 2.79 9.88l.42.65.05.07c.12.19.25.37.38.54a18.89 18.89 0 0 0 13.19 7.65h2.08a23.08 23.08 0 0 0 11-3.43l1.6-.93c13.92-8 25.2-27.57 25.2-43.65v-3.66a18.89 18.89 0 0 0-2.28-9l-.18-.35c-.06-.08-.1-.17-.15-.25-.13-.24-.28-.47-.42-.7l-.24-.35-.2-.27a4 4 0 0 0-.31-.41l-.18-.22a18.86 18.86 0 0 0-12.9-7.2 13.94 13.94 0 0 0-1.72-.1h-.29a22.83 22.83 0 0 0-11 3.43l-1.61.93c-13.95 8.12-25.23 27.66-25.23 43.73Zm9.45.93c0-13.57 9.53-30.06 21.28-36.85s21.28-1.29 21.28 12.29-9.53 30.06-21.28 36.84-21.28 1.29-21.28-12.28Z"
      fill="#455a64"
    />
    <Path
      d="M319.6 355.09V357c0 7.95 2.76 13.55 7.23 16.22l.22.13a.46.46 0 0 0 .14.07l.09.06.35.18a7.84 7.84 0 0 0 .72.32l.33.14.41.15.36.11a8.31 8.31 0 0 0 .89.25l.47.1.49.08.55.08H333.55a22.9 22.9 0 0 0 11.25-3.44l1.6-.93c13.92-8 25.2-27.57 25.2-43.65V322.77c0-.26 0-.51-.07-.77v-.14c0-.31-.07-.6-.11-.89s-.07-.52-.12-.78-.05-.3-.07-.45c-.06-.31-.12-.63-.19-.93l-.2-.8c-.13-.48-.27-.95-.43-1.4-.08-.25-.17-.5-.27-.74-.18-.46-.37-.9-.58-1.33l-.18-.35-.18-.35c-.06-.08-.1-.17-.15-.25-.13-.24-.28-.47-.42-.7l-.24-.35-.2-.27a4 4 0 0 0-.31-.41c-.1-.14-.21-.26-.32-.39a3.9 3.9 0 0 0-.33-.38 9.25 9.25 0 0 0-.7-.7l-.36-.33a8.82 8.82 0 0 0-.76-.61c-.13-.09-.26-.19-.4-.27s-.27-.18-.41-.27l-.08-.05-.35-.21c-4.55-2.54-10.79-2.13-17.68 1.85l-1.6.93c-14.01 8.05-25.29 27.57-25.29 43.66Zm4.72-1.8c0-13.57 9.53-30.06 21.28-36.85s21.28-1.29 21.28 12.29-9.53 30.06-21.28 36.84-21.28 1.29-21.28-12.28Z"
      fill="#37474f"
    />
    <Path
      d="M329.78 362.09c3.36 4 9.19 4.58 15.82.76 10.45-6 18.91-20.7 18.91-32.76 0-5.39-1.69-9.35-4.49-11.54 2 2.31 3.09 5.76 3.09 10.18 0 12-8.47 26.72-18.92 32.75-5.77 3.34-10.94 3.33-14.41.61Z"
      fill="#E21F23"
    />
    <Path
      d="M329.78 362.09c3.36 4 9.19 4.58 15.82.76 10.45-6 18.91-20.7 18.91-32.76 0-5.39-1.69-9.35-4.49-11.54 2 2.31 3.09 5.76 3.09 10.18 0 12-8.47 26.72-18.92 32.75-5.77 3.34-10.94 3.33-14.41.61Z"
      opacity={0.2}
    />
    <Path
      d="M324.33 353.29c0 13.57 9.52 19.07 21.27 12.28s21.28-23.28 21.28-36.85-9.53-19.06-21.28-12.28-21.27 23.28-21.27 36.85Zm2.36-1.36c0-12.06 8.47-26.73 18.91-32.76s18.91-1.14 18.91 10.92-8.46 26.72-18.91 32.75-18.91 1.16-18.91-10.91Z"
      fill="#E21F23"
    />
    <Path
      d="M327.33 345.93c0-.22.09-.43.14-.64l16.47-4.42.37-.55-12.1-7c.09-.17.19-.33.29-.5l12.11 7 .29-.6-4.38-16.32.49-.43 4.3 16 .58-.67 4.23-20.66.63-.17-4.17 20.39.66-.1 11.35-19.66c.17.09.35.18.51.28l-11.36 19.72.25.62 15.73-13.94c.06.2.12.4.17.62L347.94 339l-.28.83L364 335.5c0 .21-.08.42-.13.64l-16.6 4.44-.37.55 12.34 7.13c-.09.17-.18.34-.28.51l-12.35-7.13-.29.6L350.8 359c-.16.15-.32.3-.49.44l-4.42-16.5-.58.66-4.31 21.26-.63.16 4.29-21-.66.1-11.6 20.09-.49-.3 11.59-20.08-.25-.62-15.85 14.05c-.06-.2-.11-.42-.16-.63l16.06-14.24.28-.84Z"
      fill="#ebebeb"
    />
    <Path
      d="M337.41 344.48c0 2.22.77 3.79 2 4.54.39.24.69.39 1 .59a3.77 3.77 0 0 0 2.31.54l.51-.08h.16l.4-.11.29-.08.28-.12c.14-.05.28-.1.43-.17s.48-.23.72-.37a15.68 15.68 0 0 0 7.09-12.29v-.78a2.51 2.51 0 0 0 0-.27 3.62 3.62 0 0 0-.06-.46c0-.1-.05-.2-.07-.31l-.09-.34c0-.12-.08-.22-.12-.33a2 2 0 0 0-.1-.26c-.05-.12-.11-.23-.16-.33l-.11-.19a3.78 3.78 0 0 0-.22-.33l-.09-.12a2.8 2.8 0 0 0-.28-.31l-.08-.08a3 3 0 0 0-.34-.28h-.05l-.27-.18c-.35-.2-.67-.35-.94-.51-1.29-.81-3.1-.74-5.11.42a15.67 15.67 0 0 0-7.1 12.21Zm8.9 1.48c-.58-1.14-.7-3.38 0-4.23s.71.39 2.55-.56a12 12 0 0 0 1.24-.76 17.37 17.37 0 0 1-3.59 5.42.71.71 0 0 1-.2.17Zm-4.26-8.73a.74.74 0 0 1 .07-.21 12 12 0 0 1 4.64-3.6 9.88 9.88 0 0 1-1.89 5.3 1.39 1.39 0 0 0-.54-.54l-1.09-.62a2.08 2.08 0 0 0-.3-.13 4.5 4.5 0 0 0-.89-.2Zm7.31-4.09a5.34 5.34 0 0 1 1.22 3.59c-.15 1.35-2.42 2.7-3.81 2.93.06-.7 1-1 1.55-3.17.49-1.76.45-3.1 1.04-3.35Zm-8.49 15-.07-.09c-.56-.89 1.62-3.91 3.07-5a3.69 3.69 0 0 0 0 2c.38 1.4 1.2 2.06.46 2.73a4.67 4.67 0 0 1-3.46.33Zm-1.44-2.93a13.58 13.58 0 0 1 1.21-5.29 1.83 1.83 0 0 1 2.23-.75 1.42 1.42 0 0 1 .5 1.48c-.37 1.1-1 .39-2.52 2.45-.59.75-1.05 1.53-1.42 2.08Z"
      fill="#e0e0e0"
    />
    <Path
      d="M337.41 344.48c0 2.22.77 3.79 2 4.54.39.24.69.39 1 .59a3.77 3.77 0 0 0 2.31.54l.51-.08h.16l.4-.11.29-.08.28-.12c.14-.05.28-.1.43-.17s.48-.23.72-.37a15.68 15.68 0 0 0 7.09-12.29v-.78a2.51 2.51 0 0 0 0-.27 3.62 3.62 0 0 0-.06-.46c0-.1-.05-.2-.07-.31l-.09-.34c0-.12-.08-.22-.12-.33a2 2 0 0 0-.1-.26c-.05-.12-.11-.23-.16-.33l-.11-.19a3.78 3.78 0 0 0-.22-.33l-.09-.12a2.8 2.8 0 0 0-.28-.31l-.08-.08a3 3 0 0 0-.34-.28h-.05l-.27-.18c-.35-.2-.67-.35-.94-.51-1.29-.81-3.1-.74-5.11.42a15.67 15.67 0 0 0-7.1 12.21Zm8.9 1.48c-.58-1.14-.7-3.38 0-4.23s.71.39 2.55-.56a12 12 0 0 0 1.24-.76 17.37 17.37 0 0 1-3.59 5.42.71.71 0 0 1-.2.17Zm-4.26-8.73a.74.74 0 0 1 .07-.21 12 12 0 0 1 4.64-3.6 9.88 9.88 0 0 1-1.89 5.3 1.39 1.39 0 0 0-.54-.54l-1.09-.62a2.08 2.08 0 0 0-.3-.13 4.5 4.5 0 0 0-.89-.2Zm7.31-4.09a5.34 5.34 0 0 1 1.22 3.59c-.15 1.35-2.42 2.7-3.81 2.93.06-.7 1-1 1.55-3.17.49-1.76.45-3.1 1.04-3.35Zm-8.49 15-.07-.09c-.56-.89 1.62-3.91 3.07-5a3.69 3.69 0 0 0 0 2c.38 1.4 1.2 2.06.46 2.73a4.67 4.67 0 0 1-3.46.33Zm-1.44-2.93a13.58 13.58 0 0 1 1.21-5.29 1.83 1.83 0 0 1 2.23-.75 1.42 1.42 0 0 1 .5 1.48c-.37 1.1-1 .39-2.52 2.45-.59.75-1.05 1.53-1.42 2.08Z"
      opacity={0.2}
    />
    <Path
      d="M338.51 345.1c0 4.52 3.17 6.36 7.09 4.1a15.68 15.68 0 0 0 7.09-12.29c0-4.52-3.17-6.35-7.09-4.09a15.66 15.66 0 0 0-7.09 12.28Zm7.11-5.51c-.85.35-.16-1-1.6-1.55-1.13-.4-2.36 0-1.9-1s3.84-3.7 5.24-3.72-.51 5.76-1.74 6.27Zm6.05-2.25c-.21 1.91-4.68 3.85-4.89 2.55-.15-.88.88-1 1.54-3.4.52-1.87.43-3.28 1.15-3.41s2.42 2.36 2.2 4.26Zm-5.34 4.39c.75-.9.71.39 2.55-.56 1.45-.75 2.63-2 2.62-1.09s-2.35 5.17-3.88 6.36-2.39-3.44-1.29-4.71Zm-1.43.82c.61.33-.45 1.29 0 3.06.38 1.41 1.2 2.06.47 2.74s-3.87.83-4.6-.33 3.23-5.95 4.13-5.47Zm-5.3 3.91c-.44-.47 0-4.65 1-6.57s4.37-.26 3.81 1.34c-.37 1.1-1 .4-2.52 2.45-1.18 1.62-1.89 3.26-2.29 2.78Z"
      fill="#e0e0e0"
    />
    <Path
      d="M345.6 343.51c-1.2.69-2.17.13-2.17-1.25a4.8 4.8 0 0 1 2.17-3.76c1.2-.69 2.17-.13 2.17 1.26a4.78 4.78 0 0 1-2.17 3.75Z"
      fill="#455a64"
    />
    <Path
      d="M309.82 355.86a1 1 0 0 0-1.41-.37l-.88.51a21.47 21.47 0 0 0-8.74 9.47l-2.66 5.62-8.36 17.64a1 1 0 0 0 .5 1.38 1.13 1.13 0 0 0 .44.1 1 1 0 0 0 .93-.59l8.21-17.33 10.33-5.86a1 1 0 1 0-1-1.8l-7.8 4.37 1.28-2.69a19.49 19.49 0 0 1 7.9-8.57l.88-.5a1.05 1.05 0 0 0 .38-1.38Z"
      fill="#fafafa"
    />
    <Path
      d="M308 365.81c2.93 5.53 2 12.57 1.87 15s1.1 1.93 1.78 0 2.48-9.83-.57-16.72Z"
      fill="#ebebeb"
    />
    <Path
      d="M302.43 383a15.39 15.39 0 0 1 7-12.07c2-1.17 3.83-1.21 5.11-.36l3 1.67-10 17-2.93-1.68c-1.36-.73-2.18-2.29-2.18-4.56Z"
      fill="#455a64"
    />
    <Path
      d="M312.55 372.73a15.4 15.4 0 0 0-7 12.07c0 4.45 3.12 6.25 7 4a15.42 15.42 0 0 0 7-12.07c-.03-4.42-3.15-6.22-7-4Z"
      fill="#37474f"
    />
    <Path
      d="M312.55 378a5.42 5.42 0 0 0-2.45 4.25c0 1.56 1.1 2.19 2.45 1.41a5.41 5.41 0 0 0 2.46-4.25c-.01-1.61-1.1-2.25-2.46-1.41Z"
      fill="#263238"
    />
    <Path
      d="M310.1 365.58c2.92 5.53 2 12.57 1.87 15s1.1 1.93 1.78 0 2.48-9.83-.58-16.72Z"
      fill="#fafafa"
    />
    <Path
      d="m307.01 368.8 24.87-14.71v-3.08l-22.39 13.29-2.48 4.5z"
      fill="#37474f"
    />
    <Path
      d="m322.55 419.61 13.99-8.07-44.75-25.85-13.99 8.08 44.75 25.84z"
      fill="#E21F23"
    />
    <Path
      d="M336.54 411.54v3.23l-13.99 8.07v-3.23l13.99-8.07z"
      fill="#E21F23"
    />
    <Path
      d="M336.54 411.54v3.23l-13.99 8.07v-3.23l13.99-8.07z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M277.8 393.77V397l44.75 25.84v-3.23l-44.75-25.84z"
      fill="#E21F23"
    />
    <Path
      d="M277.8 393.77V397l44.75 25.84v-3.23l-44.75-25.84z"
      opacity={0.15}
    />
    <Path
      d="M355.07 381.46a1 1 0 0 0-1.41-.38l-.88.51a21.52 21.52 0 0 0-8.74 9.47l-2.66 5.63-8.38 17.63a1 1 0 0 0 .49 1.38 1 1 0 0 0 .44.1 1 1 0 0 0 .93-.59l8.22-17.33 10.34-5.88a1 1 0 1 0-1-1.79l-7.76 4.4 1.27-2.69a19.52 19.52 0 0 1 7.9-8.56l.88-.51a1 1 0 0 0 .36-1.39Z"
      fill="#fafafa"
    />
    <Path
      d="M355.59 393.12c2.92 5.53 2 12.57 1.87 15s1.09 1.93 1.77 0 2.48-9.83-.57-16.72Z"
      fill="#ebebeb"
    />
    <Path
      d="M350 410.3a15.42 15.42 0 0 1 7-12.07c2-1.16 3.84-1.21 5.11-.36l3 1.68-10 17-2.94-1.67c-1.37-.74-2.17-2.31-2.17-4.58Z"
      fill="#455a64"
    />
    <Path
      d="M360.11 400a15.39 15.39 0 0 0-7 12.07c0 4.44 3.12 6.24 7 4a15.42 15.42 0 0 0 7-12.07c-.04-4.39-3.11-6.19-7-4Z"
      fill="#37474f"
    />
    <Path
      d="M360.11 405.25a5.41 5.41 0 0 0-2.46 4.25c0 1.56 1.1 2.2 2.46 1.42a5.45 5.45 0 0 0 2.45-4.25c0-1.57-1.1-2.2-2.45-1.42Z"
      fill="#263238"
    />
    <Path
      d="M357.65 392.89c2.92 5.53 2 12.57 1.87 15s1.1 1.94 1.78 0 2.48-9.83-.58-16.72Z"
      fill="#fafafa"
    />
    <Path
      d="m405.47 296.16-3.61-6.24 15.26-8.81a1.76 1.76 0 0 1 1.83.15 5.67 5.67 0 0 1 2.55 4.42 1.81 1.81 0 0 1-.72 1.62Z"
      fill="#455a64"
    />
    <Path
      d="M406.21 294.51c0 1.63-1.14 2.29-2.55 1.47a5.61 5.61 0 0 1-2.55-4.41c0-1.62 1.14-2.28 2.55-1.47a5.62 5.62 0 0 1 2.55 4.41Z"
      fill="#37474f"
    />
    <Path
      d="M403.53 291.54a.92.92 0 0 0-.76-.13l-.2.13-10.65 6.14.23.17.71.51c.53.37 1.05.75 1.54 1.17a5.18 5.18 0 0 1 .41.4l9.37-5.41.38-.22a.94.94 0 0 0 .19-.64 2.72 2.72 0 0 0-1.22-2.12Z"
      fill="#e0e0e0"
    />
    <Path
      d="m370.23 275.54-3.61-6.23 15.27-8.82a1.75 1.75 0 0 1 1.82.16 5.62 5.62 0 0 1 2.55 4.41 1.8 1.8 0 0 1-.71 1.62Z"
      fill="#455a64"
    />
    <Path
      d="M371 273.9c0 1.62-1.14 2.28-2.54 1.47a5.64 5.64 0 0 1-2.55-4.42c0-1.62 1.14-2.28 2.55-1.47a5.66 5.66 0 0 1 2.54 4.42Z"
      fill="#37474f"
    />
    <Path
      d="M368.29 270.92a.89.89 0 0 0-.75-.12l-.21.12-10.64 6.14.22.17.71.51a17.59 17.59 0 0 1 1.55 1.18c.14.12.27.26.41.39l9.36-5.41.39-.21a1 1 0 0 0 .19-.65 2.74 2.74 0 0 0-1.23-2.12Z"
      fill="#e0e0e0"
    />
    <Path
      d="m354.75 284.8 11.11 6.42-50.45 23.86a12.63 12.63 0 0 0-5.7 8.58l-6.9 44.7a.77.77 0 0 1-1.15.54c-.26-.15-.41-.44-.32-1.52l4-47.5a12.26 12.26 0 0 1 4.66-8.05Z"
      fill="#37474f"
    />
    <Path
      d="m356.62 370.27 41.91-24.21a5.86 5.86 0 0 0 2.64-4.59V339a5.86 5.86 0 0 0-2.64-4.59l-41.91-24.19a5.83 5.83 0 0 0-5.3 0l-41.9 24.19a5.87 5.87 0 0 0-2.65 4.59v2.5a5.87 5.87 0 0 0 2.65 4.59l41.9 24.18a5.89 5.89 0 0 0 5.3 0Z"
      fill="#E21F23"
    />
    <Path
      d="M355.85 370.6a3.8 3.8 0 0 0 .77-.33l41.91-24.22a5.85 5.85 0 0 0 2.64-4.58V339a6 6 0 0 0-2.15-4.24c.94.84.78 2-.49 2.71l-41.71 24.08Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M306.77 339v2.5a5.85 5.85 0 0 0 2.65 4.59l41.91 24.18a5.87 5.87 0 0 0 4.52.33l1-9.05-.2.11a5.81 5.81 0 0 1-5.29 0l-41.91-24.19c-1.28-.74-1.44-1.87-.5-2.71a6 6 0 0 0-2.18 4.24Z"
      opacity={0.15}
    />
    <Path
      d="M341.71 318.82 383.62 343a5.59 5.59 0 0 0 3.1.61l.47-.05h.1l.27-.05a3.2 3.2 0 0 0 .42-.1 4.87 4.87 0 0 0 .9-.38l2.16-1.25a7.41 7.41 0 0 0 3.18-4.54l6.37-35.57a3.6 3.6 0 0 0 .06-.66v-.26a4.53 4.53 0 0 0-.29-1.3 4.66 4.66 0 0 0-1.4-2l-.18-.14a2.2 2.2 0 0 0-.28-.18l-41.91-24.2a5.87 5.87 0 0 0-5.29 0l-2.16 1.25a5.69 5.69 0 0 0-1.27 1 2.25 2.25 0 0 0-.21.24 7.84 7.84 0 0 0-.78 1l-.15.24-.12.21c-.06.11-.11.21-.16.32a2.92 2.92 0 0 0-.14.31c0 .09-.07.18-.11.26v.13l-.08.23a2.81 2.81 0 0 0-.08.31 1.42 1.42 0 0 0 0 .22l-6.37 35.58a4.64 4.64 0 0 0 2.04 4.59Z"
      fill="#E21F23"
    />
    <Path
      d="m393.18 306-6.37 35.58a2 2 0 0 1-3.19 1.48l-41.91-24.2a4.65 4.65 0 0 1-2.1-4.54l6.39-35.61a2 2 0 0 1 3.19-1.48l41.9 24.19a4.66 4.66 0 0 1 2.09 4.58Z"
      fill="#E21F23"
    />
    <Path
      d="m393.18 306-6.37 35.58a2 2 0 0 1-3.19 1.48l-41.91-24.2a4.65 4.65 0 0 1-2.1-4.54l6.39-35.61a2 2 0 0 1 3.19-1.48l41.9 24.19a4.66 4.66 0 0 1 2.09 4.58Z"
      opacity={0.15}
    />
    <Path
      d="M384.7 343.44a5.94 5.94 0 0 0 2 .19l.47-.05.37-.07a3.2 3.2 0 0 0 .42-.1 4.87 4.87 0 0 0 .9-.38l2.16-1.25a7.41 7.41 0 0 0 3.18-4.54l6.37-35.57a3.6 3.6 0 0 0 .06-.66v-.26a4.53 4.53 0 0 0-.29-1.3l-7.48 4.25a4.17 4.17 0 0 1 .27 2.27l-6.37 35.58a2 2 0 0 1-2.1 1.84.13.13 0 0 1 .04.05Z"
      opacity={0.30000000000000004}
    />
    <Path
      d="M352.6 271.09c-6-2.06-10.25 3.51-16.17 9-4.06 3.77-9.11 9.12-9.11 9.12s-6.69-13-6.93-15c-.28-2.5.68-3.47.86-6.15s-.1-4.48.45-5.65-1.69-1.24-2.47.22-.71 3.42-1.36 3.12-1.45-4.45-1.18-7.51-1.49-3.7-2-2.68a8.25 8.25 0 0 0-.71 1.69 3.93 3.93 0 0 0-3.87 1.25c-1.4 1.85-2.59 4.54-2.2 9s2.93 7.65 3.71 9.54c1.16 2.83 4.53 18.79 9.48 26.83 2.25 3.67 5.68 3.05 8.49.8 4.36-3.5 17.07-14.93 17.07-14.93 4.89-7.27 9.79-14.85 5.94-18.65Z"
      fill="#ffa8a7"
    />
    <Path
      d="M327.32 289.2a19.88 19.88 0 0 1 2.06 9 14.45 14.45 0 0 0-1-10Z"
      fill="#f28f8f"
    />
    <Path
      d="M354.37 270.76c-2.8-.95-7.52-.95-12.69 3.84-2.83 2.62-8.09 7.94-12 12.17a35.42 35.42 0 0 1 5.83 13.5l13-10.81a17.06 17.06 0 0 0 5.86-18.7Z"
      fill="#37474f"
    />
    <Path
      d="M296.69 375.28s-2.54 9.94-4.24 13.06a49 49 0 0 1-6.73 9.85c-.64.71-2.09 2-2.61 2.61a1.14 1.14 0 0 0 .08 1.75c1.26 1.25 6.69 1.63 9.77-.59s5.68-5.93 7.53-7.73l2.43-2.41a2.45 2.45 0 0 0 .65-2.33c-.06-.24-.12-.52-.2-.84-.65-2.95 2.87-10.38 2.87-10.38-2.55-3.67-6.24-3.52-9.55-2.99Z"
      fill="#ffa8a7"
    />
    <Path
      d="M303.74 392.49a3 3 0 0 1-.17 1.48c-.31.67-2.23 1.85-3.22 2.31a5.27 5.27 0 0 0-2.65 2.64 11.62 11.62 0 0 1-5.29 5.1 16.06 16.06 0 0 1-9.51.8c-4-.79-4.24-3.14-4.24-3.14s-.16-2.21.83-2.54 24.25-6.65 24.25-6.65Z"
      fill="#263238"
    />
    <Path
      d="M303.89 385.7a4.21 4.21 0 0 0 0 1.05c.1 1.1.19 1.79.24 3a6.31 6.31 0 0 1-.43 2.7c-1.2 1.6-4 2.08-5.62 4.26s-3.6 5.56-8.82 6.49-9.13-.8-10.07-2.29 0-2.88 3.34-4.62c3.58-1.84 7.88-6.86 7.88-6.86.43-.5.82-1.07 1.2-1.61a7.09 7.09 0 0 0 1.16-2 8.48 8.48 0 0 0 .43-2.33.28.28 0 0 1 .05-.2.35.35 0 0 1 .18-.06 9.05 9.05 0 0 1 5.56 1.3 1.27 1.27 0 0 1 .45.38 1.46 1.46 0 0 1 .18.45c.11.42.23.84.34 1.26a1.17 1.17 0 0 0 .53.84c.38.17.8-.1 1.13-.36s.55-.85.91-1.12a1.78 1.78 0 0 1 1.36-.28Z"
      fill="#455a64"
    />
    <Path
      d="M292.69 402.07a6.59 6.59 0 0 0-3.74-5.72c-3.88-2.09-6.29-.84-7.5 0-1.45 1-3.54 3.11-2.33 4.69s3.44 2.55 7.44 2.68a9.5 9.5 0 0 0 6.13-1.65Z"
      fill="#fafafa"
    />
    <Path
      d="M353.3 323.78c-1.87 5.37-2.53 11.46-9.57 16.41-6.4 4.51-20.7 7.92-22.5 8.8s-3 1.21-3.78 4.13c-1.05 4-1.18 7-4.92 14-2.63 4.94-4.65 8.46-6.73 12.1-1.9.21-8.38-1.56-9.35-3.08.48-3.06 5.91-32.15 9.2-38.51 2.77-5.36 37.29-22.91 37.29-22.91s12.23 3.7 10.36 9.06Z"
      fill="#E21F23"
    />
    <Path
      d="M353.3 323.78c-1.87 5.37-2.53 11.46-9.57 16.41-6.4 4.51-20.7 7.92-22.5 8.8s-3 1.21-3.78 4.13c-1.05 4-1.18 7-4.92 14-2.63 4.94-4.65 8.46-6.73 12.1-1.9.21-8.38-1.56-9.35-3.08.48-3.06 5.91-32.15 9.2-38.51 2.77-5.36 37.29-22.91 37.29-22.91s12.23 3.7 10.36 9.06Z"
      opacity={0.35000000000000003}
    />
    <Path
      d="M305.8 379.25a13.78 13.78 0 0 1-4-.61 7.61 7.61 0 0 1 .63-1.17 95.07 95.07 0 0 0 5.93-11c4.38-8.85 4.59-16.28 7-20.11 2.06-3.27 15.81-6.42 20.86-7.65 4.09-1 5.93-2.32 7.22-4.21s-2.42-6.09-1.72-10a20.75 20.75 0 0 0 7.33 9c-.54 1.2-9.92 9.22-9.92 9.22-5.63 2.57-12.82 4.58-16.18 5.64l-.83.27h-.09l-.23.08h-.09l-.19.07h-.06l-.19.08-1 .47a4.45 4.45 0 0 0-2 1.59 7.23 7.23 0 0 0-.84 2.07c-.06.25-.13.5-.19.74-.17.73-.33 1.44-.49 2.16-.06.23-.11.47-.17.72-.17.72-.36 1.47-.6 2.27-.08.26-.16.54-.25.81-.18.56-.39 1.15-.63 1.78a49.55 49.55 0 0 1-2.59 5.55l-.49.91c-1 1.79-1.84 3.39-2.67 4.89-.14.25-.28.49-.41.74l-1.6 2.83-1.17 2.05-.39.68Z"
      opacity={0.1}
    />
    <Path
      d="M324.39 388.25s-2.53 9.94-4.23 13.06a49 49 0 0 1-6.73 9.85c-.64.71-2.09 2-2.62 2.61a1.14 1.14 0 0 0 .09 1.75c1.26 1.25 6.68 1.63 9.77-.59s5.68-5.93 7.52-7.73c.93-.91 1.8-1.77 2.43-2.41a2.48 2.48 0 0 0 .66-2.33c-.06-.24-.13-.52-.2-.84-.65-2.95 2.87-10.38 2.87-10.38-2.55-3.67-6.26-3.52-9.56-2.99Z"
      fill="#ffa8a7"
    />
    <Path
      d="M331.45 405.46a2.91 2.91 0 0 1-.18 1.48c-.3.67-2.23 1.84-3.21 2.31a6.1 6.1 0 0 0-2.65 2.64 12.84 12.84 0 0 1-5.3 5.1 16 16 0 0 1-9.51.8c-4-.79-4.23-3.14-4.23-3.14s-.17-2.21.83-2.54 24.25-6.65 24.25-6.65Z"
      fill="#263238"
    />
    <Path
      d="M331.59 398.67a4.78 4.78 0 0 0 .05 1.05c.1 1.1.19 1.79.23 3a6.32 6.32 0 0 1-.42 2.7c-1.21 1.6-4 2.08-5.62 4.26s-3.6 5.56-8.82 6.49-9.14-.8-10.07-2.29 0-2.88 3.34-4.62c3.58-1.84 7.88-6.86 7.88-6.86.43-.5.81-1.07 1.2-1.61a7.62 7.62 0 0 0 1.16-2 8.85 8.85 0 0 0 .42-2.33.28.28 0 0 1 .06-.2.32.32 0 0 1 .18-.06 9.05 9.05 0 0 1 5.56 1.3 1.36 1.36 0 0 1 .45.38 1.44 1.44 0 0 1 .17.45l.35 1.26a1.19 1.19 0 0 0 .52.84c.38.17.8-.1 1.13-.36s.56-.85.92-1.13a1.81 1.81 0 0 1 1.31-.27Z"
      fill="#455a64"
    />
    <Path
      d="M320.4 415a6.61 6.61 0 0 0-3.74-5.72c-3.88-2.09-6.29-.84-7.51 0-1.45 1-3.53 3.11-2.32 4.69s3.44 2.55 7.44 2.68a9.5 9.5 0 0 0 6.13-1.65Z"
      fill="#fafafa"
    />
    <Path
      d="M378.29 320.09c.95 5.32 2.2 13.62-1 18.3-3 4.33-6.95 7.26-14.21 11.89-8 5.11-12 7.6-13.88 8.94a9.77 9.77 0 0 0-4.29 6.19 49.53 49.53 0 0 1-4.67 14c-2.66 5.64-5.61 10.81-7.11 13.67-2.25.38-7.86-.7-9.33-2.6.81-4.27 5.47-34.55 7.46-39S349 333.54 349 333.54s1.24-8.14 0-13.45Z"
      fill="#E21F23"
    />
    <Path
      d="M378.29 320.09c.95 5.32 2.2 13.62-1 18.3-3 4.33-6.95 7.26-14.21 11.89-8 5.11-12 7.6-13.88 8.94a9.77 9.77 0 0 0-4.29 6.19 49.53 49.53 0 0 1-4.67 14c-2.66 5.64-5.61 10.81-7.11 13.67-2.25.38-7.86-.7-9.33-2.6.81-4.27 5.47-34.55 7.46-39S349 333.54 349 333.54s1.24-8.14 0-13.45Z"
      opacity={0.35000000000000003}
    />
    <Path
      d="M338.36 371c1.07-3.62.87-7.35 1.82-11 1-4 4.49-6.78 7.74-9.3l10-7.76a14.42 14.42 0 0 0 2.9-2.72 7.16 7.16 0 0 0 1.26-5.34 8.17 8.17 0 0 0-3.84-5.33c-2.76-1.89-5.7-3.48-8.47-5.35a25.23 25.23 0 0 1-7.2-6.87c-.06-.1-1-2-.75-2l36.44 4.76c.21 1.16.43 2.47.62 3.85l.15 1.2c.12 1 .21 2.05.25 3.09v1.25c0 .62 0 1.24-.05 1.85 0 .41-.05.82-.1 1.22a17.36 17.36 0 0 1-.55 2.87 10.48 10.48 0 0 1-1.37 3l-.57.8a25 25 0 0 1-1.89 2.23 29.86 29.86 0 0 1-2.21 2.11c-.54.46-1.1.92-1.71 1.39s-1.24.94-1.92 1.42c-1.69 1.21-3.64 2.49-5.91 3.94l-2.81 1.8-6.42 4.08-.8.52c-1.79 1.15-3 1.95-3.85 2.54a12.55 12.55 0 0 0-2.34 2.12 8.71 8.71 0 0 0-2 4.07 49.53 49.53 0 0 1-4.67 14c-1.33 2.82-2.73 5.53-4 7.88-.47.88-.92 1.72-1.34 2.49-.7 1.29-1.31 2.41-1.78 3.3a12.84 12.84 0 0 1-4.12-.26 10 10 0 0 1 .85-1.93 120.28 120.28 0 0 0 8.64-19.92Z"
      opacity={0.1}
    />
    <Path
      d="M364.87 271.31c6.86 1.27 11.44 2.75 12.74 4 2.92 6-.88 26-.88 26l.39 9.37 1.64 9.36c-7.41 6.27-32.86 4-36.91-4.75 0 0 1.31-27.23 1.82-32.27.58-5.82 3.75-11.92 11.53-12.37Z"
      fill="#fafafa"
    />
    <Path
      d="M369.87 317.59c2-3.06 3.72-10.88 3.72-10.88s-3.28-20.44-2.48-25.26l7.16 9.06a140.06 140.06 0 0 1-1.54 10.84l.39 9.37 1.64 9.36c-5.93 5-22.54 5.71-32.83-.63 10.9 3.6 21.95 1.19 23.94-1.86Z"
      fill="#ebebeb"
    />
    <Path
      d="M368.9 244.4c0 7.59-6.3 17.45-13.89 17.45s-13.87-7-13.6-17.45c.19-7.59 5-7.28 12.57-7.28s14.92-.31 14.92 7.28ZM370.77 252.74s-1.11 8.14-1.69 9.5a3.65 3.65 0 0 1-2 1.85l-.31-5.64Z"
      fill="#263238"
    />
    <Path
      d="M367.77 241.42a4 4 0 0 1 3.35.91c1.51 1.33 1.07 5.88-.35 10.41l-3.19.61Z"
      fill="#263238"
    />
    <Path
      d="M366.62 253.42c.67.34 1.5-1 2.23-1.86s3.24-2.22 4.74.41-.66 6.53-2.65 7.33c-3.44 1.36-4.14-.85-4.14-.85l.75 14c-3.19 6.94-18.48 6.56-11.56-.2l-.26-4.4a36.64 36.64 0 0 1-5.3.39c-2.62-.2-4.16-1.57-5.16-4.32-1.6-4.4-2-8.34-1.59-17.26.45-9.79 11.72-10.73 18.09-7.35s4.85 14.11 4.85 14.11Z"
      fill="#ffa8a7"
    />
    <Path
      d="M366.84 236.16a31.28 31.28 0 0 0-6.8-1.5c-5-.7-10.08-1.31-14.74-3.25a3.12 3.12 0 0 0-1.8-.37c-1.17.24-1.58 1.71-1.54 2.91a10 10 0 0 0 .67 3.24 1.89 1.89 0 0 0-2.64.24 3.27 3.27 0 0 0-.56 2.82 6.92 6.92 0 0 0 4.34 4.72 15.76 15.76 0 0 0 6.59.8 45 45 0 0 0 9.21-1.39 6.52 6.52 0 0 1 2.23-.33 2 2 0 0 1 1.77 1.21c.42 1.19 1.38 9.5 3.1 9.4.31 0 1.37-2.2 2.1-3 .93-1 1.39-8.22 1.47-9.55a.62.62 0 0 1 .12-.29c1-1.65-.51-4.2-2-4.95a12.16 12.16 0 0 0-1.52-.71Z"
      fill="#263238"
    />
    <Path d="M369.75 242a2.35 2.35 0 0 0 3.34-3.31Z" fill="#263238" />
    <Path
      d="M355.73 267.81s5.74-1 7.68-2.21a6.7 6.7 0 0 0 2.58-3 9.12 9.12 0 0 1-1.34 3.38c-1.34 2-8.79 4-8.79 4Z"
      fill="#f28f8f"
    />
    <Path
      d="M355.16 252.76a1.16 1.16 0 1 0 1.07-1.29 1.19 1.19 0 0 0-1.07 1.29ZM356.06 248.3l2.58 1.18a1.5 1.5 0 0 0-.73-1.93 1.39 1.39 0 0 0-1.85.75Z"
      fill="#263238"
    />
    <Path
      d="m355.74 260.82-2.57 1.18a1.39 1.39 0 0 0 1.86.74 1.48 1.48 0 0 0 .71-1.92Z"
      fill="#f28f8f"
    />
    <Path
      d="m344.9 250.13 2.13-1.91a1.38 1.38 0 0 0-2-.15 1.5 1.5 0 0 0-.13 2.06ZM345.83 253.15a1.16 1.16 0 1 0 1.06-1.29 1.18 1.18 0 0 0-1.06 1.29Z"
      fill="#263238"
    />
    <Path d="m351.73 251.96.14 6.98-3.73-.96 3.59-6.02z" fill="#f28f8f" />
    <Path
      d="m399.76 306.56-.76 4.21-38.27 30.38A12.64 12.64 0 0 0 356 350l-4.53 47a.75.75 0 0 0 .38.73.76.76 0 0 0 .81 0l14.65-8.54v-3.08l-12.17 7.12 5.35-40.23a12.66 12.66 0 0 1 5.7-8.58l32.65-20.64a10.57 10.57 0 0 0 .92-17.22Z"
      fill="#37474f"
    />
    <Path
      d="M392.24 316.14c.89 1.61 1.88 6.61 1 7.51a12.56 12.56 0 0 1-3.35 2.3 60.19 60.19 0 0 1-5.89 2.53c-4 1.52-8.39 2.76-12.14 3.84Z"
      fill="#263238"
    />
    <Path
      d="M392.7 312.88c-1.48-5.85-6.12-22.44-7.61-27.75-2.42-8.66-5-9.69-8.91-10.53-3.11 2.46-6.15 11.67-2 20l6.08 21.63s-1.2.88-6.29 5.4c-4.58 4.06-6.38 5.43-8.43 7a72.23 72.23 0 0 0-5.77 4.61c-.75.71-7 8.65-6.89 10.18.11 1.37 1.38 2.38 3.87 2.74 2.28.33 3.89-.88 5.46-2.38.25-.24.5-.5.74-.76a12.11 12.11 0 0 1 3.17-2.52 17.26 17.26 0 0 0 2.88-1.91c1.22-1 1.6-2.15 3.37-3.28s12.2-7.72 17.83-11.3c4.59-2.94 4.2-4.4 2.5-11.13Z"
      fill="#ffa8a7"
    />
    <Path
      d="M380.29 316.2s3.06-1.76 8.29 0a10.7 10.7 0 0 0-8.64-1.24Z"
      fill="#f28f8f"
    />
    <Path
      d="M374.41 273.82c5.95.69 8.15 2.45 10.29 8.89s6.3 22.63 6.3 22.63-6.6 3.8-13.61 3.23l-4.65-16.15s-6.23-13.27 1.67-18.6Z"
      fill="#37474f"
    />
    <Path
      d="M364.87 381.38v3.67a18.8 18.8 0 0 0 2.79 9.88c.13.22.28.44.42.65v.07l.39.54a18.9 18.9 0 0 0 13.19 7.65h2.08a23.08 23.08 0 0 0 11-3.43l1.6-.93c13.92-8 25.2-27.58 25.2-43.65v-3.66a18.76 18.76 0 0 0-2.27-9 3.9 3.9 0 0 0-.19-.35l-.14-.26c-.13-.23-.28-.46-.43-.69l-.23-.35-.2-.28c-.1-.14-.2-.27-.31-.4l-.18-.22a18.84 18.84 0 0 0-12.9-7.2 13.94 13.94 0 0 0-1.72-.1h-.29a22.83 22.83 0 0 0-11 3.43l-1.61.93c-13.92 8.09-25.2 27.63-25.2 43.7Zm9.45.93c0-13.57 9.53-30.07 21.28-36.85s21.28-1.29 21.28 12.28-9.53 30.07-21.28 36.85-21.28 1.29-21.28-12.28Z"
      fill="#455a64"
    />
    <Path
      d="M369.6 384.11V386c0 7.95 2.76 13.55 7.23 16.22l.22.13.14.07.09.05a2.91 2.91 0 0 0 .35.18 6.51 6.51 0 0 0 .71.33l.34.14.4.14.37.12c.29.09.58.18.89.25a3.26 3.26 0 0 0 .47.09 3.56 3.56 0 0 0 .49.09l.55.08H383.55a22.93 22.93 0 0 0 11.25-3.44l1.6-.93c13.92-8 25.2-27.58 25.2-43.65V351.77c0-.26 0-.52-.07-.77a.77.77 0 0 0 0-.15c0-.3-.06-.59-.1-.88s-.07-.53-.12-.78-.05-.3-.07-.45c-.06-.31-.13-.63-.19-.93s-.13-.54-.2-.8c-.13-.48-.27-.95-.43-1.41a9.09 9.09 0 0 0-.27-.73c-.18-.46-.38-.91-.58-1.33l-.18-.35a3.9 3.9 0 0 0-.19-.35l-.14-.26c-.13-.23-.28-.46-.43-.69l-.23-.35-.2-.28c-.1-.14-.2-.27-.31-.4s-.21-.27-.32-.39l-.33-.38c-.22-.25-.46-.48-.7-.71l-.36-.32c-.25-.22-.5-.42-.76-.61l-.4-.28-.41-.26-.08-.05-.35-.21c-4.55-2.54-10.79-2.13-17.68 1.84l-1.6.94c-14.02 8.07-25.3 27.57-25.3 43.68Zm4.72-1.8c0-13.57 9.53-30.07 21.28-36.85s21.28-1.29 21.28 12.28-9.53 30.07-21.28 36.85-21.28 1.29-21.28-12.28Z"
      fill="#37474f"
    />
    <Path
      d="M379.78 391.11c3.36 4 9.19 4.58 15.82.75 10.45-6 18.91-20.69 18.91-32.75 0-5.39-1.69-9.35-4.5-11.54 2 2.31 3.09 5.76 3.09 10.17 0 12.06-8.46 26.73-18.91 32.76-5.77 3.34-10.95 3.33-14.41.61Z"
      fill="#E21F23"
    />
    <Path
      d="M379.78 391.11c3.36 4 9.19 4.58 15.82.75 10.45-6 18.91-20.69 18.91-32.75 0-5.39-1.69-9.35-4.5-11.54 2 2.31 3.09 5.76 3.09 10.17 0 12.06-8.46 26.73-18.91 32.76-5.77 3.34-10.95 3.33-14.41.61Z"
      opacity={0.2}
    />
    <Path
      d="M374.33 382.31c0 13.57 9.52 19.07 21.27 12.28s21.28-23.28 21.28-36.85-9.53-19.07-21.28-12.28-21.27 23.28-21.27 36.85Zm2.36-1.37c0-12.06 8.47-26.72 18.91-32.75s18.91-1.14 18.91 10.92-8.51 26.72-18.91 32.75-18.91 1.14-18.91-10.92Z"
      fill="#E21F23"
    />
    <Path
      d="M377.33 374.94c0-.21.09-.42.14-.64l16.47-4.41.37-.55-12.11-7 .3-.5 12.1 7 .29-.6-4.37-16.32.49-.43 4.3 16 .58-.66 4.23-20.66.63-.17-4.17 20.38.66-.09 11.35-19.67.51.29-11.36 19.66.25.63 15.73-13.95c.06.21.12.41.17.62l-15.95 14.15-.28.83 16.34-4.33c0 .21-.08.42-.13.63l-16.6 4.45-.37.55 12.34 7.13-.28.51-12.35-7.13-.29.6L400.8 388l-.49.44-4.42-16.44-.58.66-4.31 21.22-.63.16 4.29-21-.66.1-11.6 20.09-.5-.3 11.6-20.08-.25-.63-15.85 14.06c-.06-.21-.11-.42-.16-.63l16.06-14.25.28-.83Z"
      fill="#ebebeb"
    />
    <Path
      d="M387.41 373.5c0 2.22.77 3.79 2 4.54.38.23.69.38 1 .59a3.8 3.8 0 0 0 2.32.54l.51-.08h.15l.41-.11.28-.08.29-.12a3.69 3.69 0 0 0 .42-.17c.24-.11.48-.23.73-.37a15.69 15.69 0 0 0 7.09-12.29v-1.05c0-.09 0-.31-.06-.46a2.81 2.81 0 0 0-.08-.31c0-.12-.05-.23-.08-.35l-.12-.32c0-.09-.06-.18-.1-.27a3.24 3.24 0 0 0-.17-.32 1.86 1.86 0 0 0-.1-.2 3.62 3.62 0 0 0-.22-.32l-.09-.13c-.09-.11-.19-.21-.28-.31l-.08-.07c-.11-.1-.22-.2-.34-.29h-.05l-.27-.18c-.35-.2-.67-.35-.94-.52-1.29-.81-3.1-.74-5.11.43a15.67 15.67 0 0 0-7.11 12.22Zm8.9 1.48c-.59-1.14-.7-3.38 0-4.24s.71.4 2.55-.55a14 14 0 0 0 1.24-.76 17.37 17.37 0 0 1-3.59 5.42.85.85 0 0 1-.2.15Zm-4.31-8.73a1.11 1.11 0 0 1 .08-.21 12 12 0 0 1 4.64-3.6 9.88 9.88 0 0 1-1.89 5.3 1.39 1.39 0 0 0-.54-.54l-1.09-.62a1.8 1.8 0 0 0-.31-.14 4.17 4.17 0 0 0-.89-.19Zm7.32-4.1a5.44 5.44 0 0 1 1.22 3.6c-.15 1.35-2.42 2.7-3.81 2.93.05-.7 1-1 1.55-3.17.52-1.76.48-3.1 1.08-3.36Zm-8.49 15-.07-.08c-.56-.9 1.62-3.91 3.07-5.05a3.69 3.69 0 0 0 0 2c.38 1.4 1.2 2.06.46 2.73a4.67 4.67 0 0 1-3.42.37Zm-1.44-2.92a13.41 13.41 0 0 1 1.21-5.29 1.8 1.8 0 0 1 2.23-.75 1.42 1.42 0 0 1 .5 1.48c-.37 1.1-1 .39-2.52 2.45-.55.75-1.01 1.53-1.38 2.08Z"
      fill="#e0e0e0"
    />
    <Path
      d="M387.41 373.5c0 2.22.77 3.79 2 4.54.38.23.69.38 1 .59a3.8 3.8 0 0 0 2.32.54l.51-.08h.15l.41-.11.28-.08.29-.12a3.69 3.69 0 0 0 .42-.17c.24-.11.48-.23.73-.37a15.69 15.69 0 0 0 7.09-12.29v-1.05c0-.09 0-.31-.06-.46a2.81 2.81 0 0 0-.08-.31c0-.12-.05-.23-.08-.35l-.12-.32c0-.09-.06-.18-.1-.27a3.24 3.24 0 0 0-.17-.32 1.86 1.86 0 0 0-.1-.2 3.62 3.62 0 0 0-.22-.32l-.09-.13c-.09-.11-.19-.21-.28-.31l-.08-.07c-.11-.1-.22-.2-.34-.29h-.05l-.27-.18c-.35-.2-.67-.35-.94-.52-1.29-.81-3.1-.74-5.11.43a15.67 15.67 0 0 0-7.11 12.22Zm8.9 1.48c-.59-1.14-.7-3.38 0-4.24s.71.4 2.55-.55a14 14 0 0 0 1.24-.76 17.37 17.37 0 0 1-3.59 5.42.85.85 0 0 1-.2.15Zm-4.31-8.73a1.11 1.11 0 0 1 .08-.21 12 12 0 0 1 4.64-3.6 9.88 9.88 0 0 1-1.89 5.3 1.39 1.39 0 0 0-.54-.54l-1.09-.62a1.8 1.8 0 0 0-.31-.14 4.17 4.17 0 0 0-.89-.19Zm7.32-4.1a5.44 5.44 0 0 1 1.22 3.6c-.15 1.35-2.42 2.7-3.81 2.93.05-.7 1-1 1.55-3.17.52-1.76.48-3.1 1.08-3.36Zm-8.49 15-.07-.08c-.56-.9 1.62-3.91 3.07-5.05a3.69 3.69 0 0 0 0 2c.38 1.4 1.2 2.06.46 2.73a4.67 4.67 0 0 1-3.42.37Zm-1.44-2.92a13.41 13.41 0 0 1 1.21-5.29 1.8 1.8 0 0 1 2.23-.75 1.42 1.42 0 0 1 .5 1.48c-.37 1.1-1 .39-2.52 2.45-.55.75-1.01 1.53-1.38 2.08Z"
      opacity={0.2}
    />
    <Path
      d="M388.51 374.12c0 4.52 3.17 6.35 7.09 4.1a15.69 15.69 0 0 0 7.09-12.29c0-4.52-3.17-6.35-7.09-4.1a15.69 15.69 0 0 0-7.09 12.29Zm7.11-5.51c-.85.35-.17-1-1.6-1.55-1.13-.4-2.37 0-1.9-1s3.83-3.7 5.24-3.72-.51 5.76-1.74 6.27Zm6.05-2.25c-.22 1.91-4.68 3.85-4.89 2.55-.15-.88.88-1 1.54-3.4.52-1.87.43-3.29 1.15-3.41s2.41 2.36 2.2 4.26Zm-5.34 4.38c.75-.89.71.4 2.55-.55 1.45-.75 2.63-2 2.61-1.09s-2.34 5.17-3.87 6.36-2.39-3.41-1.29-4.72Zm-1.43.83c.61.33-.45 1.29 0 3.06.38 1.4 1.2 2.06.47 2.74s-3.87.83-4.6-.33 3.23-5.95 4.13-5.47Zm-5.3 3.91c-.44-.48 0-4.65 1-6.57s4.37-.27 3.81 1.34c-.37 1.1-1 .4-2.52 2.45-1.18 1.62-1.89 3.3-2.29 2.78Z"
      fill="#e0e0e0"
    />
    <Path
      d="M395.6 372.53c-1.2.69-2.17.13-2.17-1.25a4.78 4.78 0 0 1 2.17-3.76c1.2-.69 2.17-.13 2.17 1.25a4.78 4.78 0 0 1-2.17 3.76Z"
      fill="#455a64"
    />
  </Svg>
);
export default CommunityMembers;
