import {View, Text, TouchableOpacity} from 'react-native';
import React from 'react';
import {style} from './FeatureInfoButton.styles';
import {FeatureInfoButtonProps} from './FeatureInfoButton.types';
import {NextArrow} from '../../assets/svg';

const FeatureInfoButton: React.FC<FeatureInfoButtonProps> = ({
  icon,
  name,
  onPress,
  rightComponent = <NextArrow width={15} height={15} />,
  nameStyle,
  containerStyle,
  logoContainerStyle,
}) => {
  return (
    <TouchableOpacity
      style={[style.container, containerStyle]}
      onPress={onPress}
      activeOpacity={0.8}>
      {icon && <View style={[style.logoView, logoContainerStyle]}>{icon}</View>}
      <Text style={[style.name, nameStyle]}>{name}</Text>
      {rightComponent}
    </TouchableOpacity>
  );
};

export default FeatureInfoButton;
