import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const BoardMembers = (props: SvgProps) => (
  <Svg
    width={200}
    height={200}
    fill={'#840505'}
    viewBox="0 0 512 512"
    {...props}>
    <Path
      d="M256.494 96.433c26.632 0 48.209-21.592 48.209-48.201C304.703 21.584 283.126 0 256.494 0c-26.647 0-48.216 21.584-48.216 48.232 0 26.608 21.569 48.201 48.216 48.201zM321.225 126.746l-64.731 64.731-65.139-65.131c-14.756 9.176-26.412 25.91-26.412 41.718v162.059c0 11.687 9.466 21.153 21.153 21.153h14.757l8.045 138.214c0 12.433 10.078 22.511 22.519 22.511h49.174c12.432 0 22.503-10.078 22.503-22.511l8.052-138.214h14.757c11.679 0 21.145-9.466 21.145-21.153v-162.06c.001-15.588-11.333-32.063-25.823-41.317z"
      fill={'#800000'}
    />
    <Path
      d="m242.076 165.732 14.387 14.387 13.344-13.335V140.82h-27.731zM242.091 113.787h27.691v17.747h-27.691zM408.711 149.084c23.28 0 42.102-18.854 42.102-42.11s-18.822-42.11-42.102-42.11c-23.249 0-42.094 18.853-42.094 42.11 0 23.257 18.845 42.11 42.094 42.11zM458.065 171.784l-50.202 50.194-33.123-33.123v141.267c0 13.586-5.62 25.815-14.614 34.669v6.852l7.025 120.694c0 10.856 8.815 19.662 19.662 19.662h42.949c10.863 0 19.662-8.806 19.662-19.662l7.025-120.694h12.889c10.204 0 18.468-8.265 18.468-18.484V211.641c-.001-16.13-13.87-33.327-29.741-39.857z"
      fill={'#800000'}
    />
    <Path
      d="m407.886 210.581 13.187-13.178v-17.08h-25.321v18.124zM395.744 160.23h25.344v11.648h-25.344zM103.289 149.084c23.249 0 42.094-18.854 42.094-42.11s-18.845-42.11-42.094-42.11c-23.28 0-42.102 18.853-42.102 42.11 0 23.257 18.822 42.11 42.102 42.11zM137.26 188.855l-33.123 33.123-50.202-50.194c-15.87 6.53-29.74 23.727-29.74 39.858V353.16c0 10.22 8.265 18.484 18.468 18.484h12.888l7.026 120.694c0 10.856 8.798 19.662 19.661 19.662h42.95c10.848 0 19.662-8.806 19.662-19.662l7.025-120.694v-6.852c-8.994-8.854-14.614-21.083-14.614-34.669V188.855z"
      fill={'#800000'}
    />
    <Path
      d="m104.113 210.581 12.135-12.134v-18.124H90.927v17.08zM90.911 160.23h25.345v11.648H90.911z"
      fill={'#800000'}
    />
  </Svg>
);
export default BoardMembers;
