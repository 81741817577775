import {useEffect, useState} from 'react';
import {Dimensions, ScaledSize} from 'react-native';
// import EventEmitter from 'react-native/Libraries/vendor/emitter/EventEmitter';

type DimensionsValues = {
  windowWidth: number;
  windowHeight: number;
  screenWidth: number;
  screenHeight: number;
};

// const eventEmitter = new EventEmitter();

const useDimensionsHook = (): DimensionsValues => {
  const [dimensions, setDimensions] = useState<DimensionsValues>({
    windowWidth: Dimensions.get('window').width,
    windowHeight: Dimensions.get('window').height,
    screenWidth: Dimensions.get('screen').width,
    screenHeight: Dimensions.get('screen').height,
  });

  const handleDimensionsChange = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    setDimensions({
      windowWidth: window.width,
      windowHeight: window.height,
      screenWidth: screen.width,
      screenHeight: screen.height,
    });
  };

  useEffect(() => {
    Dimensions.addEventListener('change', handleDimensionsChange);

    // return () => {
    //   Dimensions.re('change');
    // };
  }, []);

  return dimensions;
};

export const useDimensions = (): DimensionsValues => {
  return useDimensionsHook();
};

export default useDimensionsHook;
