import {StyleSheet} from 'react-native';
import {Colors} from '../../utils/theme';

export const style = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
    display: 'flex',
  },
});
