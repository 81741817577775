import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  changePasswordController,
  changePasswordFormFields,
} from './changePassword.types';
import {changePasswordSchema} from '../../validationSchemas/changePassword';
import axios from '../../services/api/api';
import {useAppSelector} from '../../services/redux/hook';
import {ApiRoutes} from '../../services/api/path';
import {useModal} from '../../setup/providers/modalContext/ModalContext';
import {useTranslation} from 'react-i18next';

export const useChangePasswordController = (): changePasswordController => {
  const {openModal} = useModal();
  const {user} = useAppSelector(state => state.userReducer);
  const {t} = useTranslation();

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
    reset,
  } = useForm<changePasswordFormFields>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      isLoading: false,
    },
  });

  const {isLoading} = watch();

  const onSubmit = async (
    data: changePasswordFormFields,
    onSuccess: () => void,
    onClose: () => void,
  ) => {
    try {
      if (data.newPassword === data.confirmPassword) {
        setValue('isLoading', true);
        const {data: response} = await axios.post<any>(
          ApiRoutes.ChangePassword,
          {
            UId: user?.UId,
            ApiSecret: user?.ApiSecret,
            RequestById: 9,
            Password: data.oldPassword,
            NewPassword: data.confirmPassword,
          },
        );

        setValue('isLoading', true);

        if (response.isSuccess) {
          onSuccess();
          reset();
          openModal({
            title: t('user.status'),
            message: t('user.changeSuccess'),
          });
        } else {
          reset();
          onClose();
          openModal({
            title: t('user.status'),
            message: response.message,
          });
        }
      } else {
        onClose();
        openModal({
          title: t('misc.passwordMismatch'),
          message: t('misc.passwordMismatchMessage'),
        });
      }
    } catch (e) {
      setValue('isLoading', false);
      openModal({
        title: t('misc.somethingWentWrong'),
        message: t('misc.somethingWentWrongMessage'),
      });
    }
  };

  return {
    control,
    onSubmit,
    handleSubmit,
    errors,
    isLoading,
    setValue,
    watch,
    reset,
  };
};
