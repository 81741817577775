import React, {forwardRef} from 'react';
import {Platform, Text, TextInput, View} from 'react-native';
import {style} from './Input.styles';
import {InputProps} from './Input.types';
import {Colors} from '../../utils/theme';

const Input = forwardRef<TextInput, InputProps>(
  (
    {
      label,
      labelStyle,
      containerStyle,
      inputViewStyle,
      style: inputStyle,
      error,
      helperText,

      ...restProps
    }: InputProps,
    ref?: React.Ref<TextInput>,
  ) => {
    return (
      <View style={containerStyle}>
        {label !== '' && label && (
          <Text style={[style.labelText, labelStyle]}>{label}</Text>
        )}
        <View
          style={[
            style.rowView,
            {
              borderColor: error ? Colors.maroon : undefined,
              borderWidth: error ? 1 : undefined,
            },
            inputViewStyle,
          ]}>
          <TextInput
            ref={ref}
            selectionColor="rgba(0,0,0,0.5)"
            placeholderTextColor={Colors.lightBlack}
            {...restProps}
            style={[
              style.textInput,
              {width: Platform.OS === 'web' ? '100%' : 'auto'},
              inputStyle,
            ]}
          />
        </View>
        {error && <Text style={style.error}>{helperText}</Text>}
      </View>
    );
  },
);

export default Input;
